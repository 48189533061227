import BaseService from './BaseService';

export default class ClusterService extends BaseService {
  constructor(hostUrl, { idpService = null } = {}) {
    super();
    this.hostUrl = hostUrl;
    this.baseUrl = `${this.hostUrl}/cluster`;
    this.idpService = idpService;
  }

  async getClusters(clusterIds) {
    const headers = await this.idpService?.getIdpHeaders();
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/clusters`,
      {
        method: 'POST',
        body: JSON.stringify({ cluster_list: clusterIds }),
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async getUserClusterMapping({ userId }) {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      user_id: userId
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/mappings?${queryParams}`,
      {
        cache: 'no-cache',
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }
}
