import React from 'react';
import PropTypes from 'prop-types';
import { styled, Box, Skeleton } from '@trustyou/ty-brew/mui';

const BreadcrumbItem = styled(Box)(({ theme }) => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  position: 'relative',
  '&:not(:last-child)': {
    '&::before, &::after': {
      content: '""',
      width: '33px',
      height: '1px',
      backgroundColor: theme.palette.grey[200],
      display: 'block',
      position: 'absolute',
      transformOrigin: '0 0',
    },
    '&::before': {
      transform: 'rotate(70deg)',
      top: '-19px',
      right: '-22px',
    },
    '&::after': {
      transform: 'rotate(-70deg)',
      bottom: '-20px',
      right: '-21px',
    },
  },
}));

const TopbarSkeleton = ({ breadcrumbItemsCount, menuItemsCount }) => (
  <Box
    sx={{
      width: '100%',
      minWidth: '640px',
      height: '60px',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'background.paper',
      color: 'text.secondary',
      boxSizing: 'border-box',
      borderWidth: '0 0 1px 1px',
      borderColor: 'grey.300',
      borderStyle: 'solid',
    }}
  >
    <Box // Breadcrumbs
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {[...Array(breadcrumbItemsCount).keys()].map((itemIdx) => (
        <BreadcrumbItem key={`breadcrumb-${itemIdx}`}>
          <Skeleton width={100} height={24} />
        </BreadcrumbItem>
      ))}
    </Box>
    <Box // Right Menu
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {[...Array(menuItemsCount).keys()].map((itemIdx) => (
        <Box
          key={`menu-${itemIdx}`}
          sx={{
            marginX: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Skeleton variant='circular' width={24} height={24} />
          <Skeleton width={48} height={16} sx={{ marginTop: 0.5 }} />
        </Box>
      ))}
    </Box>
  </Box>
);

TopbarSkeleton.propTypes = {
  breadcrumbItemsCount: PropTypes.number,
  menuItemsCount: PropTypes.number,
};
TopbarSkeleton.defaultProps = {
  breadcrumbItemsCount: 2,
  menuItemsCount: 2,
};
TopbarSkeleton.displayName = 'TopbarSkeleton';

export default TopbarSkeleton;
