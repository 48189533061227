import React from 'react';
import { styled, Box, GlobalStyles } from '@trustyou/ty-brew/mui';

const DashboardView = styled(Box)({
  width: '1000px',
  margin: 0,
  padding: '20px 20px 0 20px',
  a: {
    textDecoration: 'none',
  },
  // reset blue tabs background for reviews tile
  '.ui-tabs': {
    padding: 0,
    '.ui-tabs-nav': {
      padding: '0 20px !important',
      borderBottom: '1px solid #e5e5e5 !important',
      margin: '0 -20px',
      display: 'block',
      li: {
        borderRadius: '4px 4px 0 0',
        border: '1px solid #e5e5e5 !important',
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        fontSize: '13px',
        fontWeight: 400,
        borderColor: '#ddd',
        color: '#333',
        background: '#fafafa !important',
        marginBottom: '-1px !important',
        a: {
          padding: '10px 20px',
          fontWeight: 400,
          fontSize: '13px',
          color: '#666',
        },
        '&.ui-tabs-selected': {
          borderBottom: '1px solid #fff !important',
          background: '#fff !important',

          a: {
            color: '#333',
          },
        },
      },
    },
    '.ui-tabs-panel': {
      position: 'relative',
      padding: '20px 0 0 0',
      background: 'white !important',
      border: 'none !important',
    },
  },
  '.ui-widget-content': {
    border: 'none !important',
  },
  '.ui-widget-header': {
    position: 'relative',
    zIndex: 1,
    background: 'none !important',
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    border: 'none !important',
  },
  '.ui-widget-header .ui-state-default': {
    background: 'none !important',
    border: 'none !important',
  },
  '.ui-widget-header .ui-state-active': {
    background: 'white !important',
    borderTop: '1px solid rgb(221, 221, 221) !important',
    borderRight: '1px solid rgb(221, 221, 221) !important',
    borderLeft: '1px solid rgb(221, 221, 221) !important',
  },
  // SUR-3222: tasks tile layout
  '.tasks-app-container': {
    '.tasks-box, .activity-stream': {
      border: 0,
      margin: '10px 10px 10px 0',
      padding: '10px 10px 10px 0',
      width: '920px',
    },
    '.task-box-kpi': {
      margin: 0,
      textAlign: 'center',
      width: '184px',
    },
    '.task-box-kpi-title, .activity-stream-title': {
      color: '#999',
      fontSize: '13px',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    '.task-box-number': {
      float: 'none',
      fontSize: '27px',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    '.open': {
      color: '#ef5350',
    },
    td: {
      padding: '3px',
    },
    '.activity': {
      border: 0,
      marginBottom: '5px',
      padding: 0,
    },
    '.activity-datetime, .activity-content': {
      padding: '4px',
    },
    '.activity-datetime': {
      backgroundColor: 'white',
      borderBottom: '1px solid #ccc',
      color: '#999',
    },
  },
});

// SUR-3220: reviews popup css (Impact Score tile)
export const DashboardGlobalStyles = () => (
  <GlobalStyles
    styles={{
      '.review-list-popup': {
        width: '940px',
        minHeight: '50px',
        padding: '20px',
        '.review-list-header': {
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          marginBottom: '20px',
          fontSize: '16px',
          fontWeight: '300',
        },
      },
    }}
  />
);

export default DashboardView;
