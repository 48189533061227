import { useState, useEffect } from 'react';

export default function useHttpStates(loader, params, initData, forceCounter = 0, hasToFetch = true) {
  // make the call and send reactively!
  const initState = {
    data: initData,
    isLoading: !!hasToFetch,
    isError: false
  };
  const [state, setState] = useState(initState);
  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setState(initState);
      try {
        const response = await loader(params);
        !didCancel && setState(s => ({ ...s, data: response, isLoading: false }));
      } catch (error) {
        !didCancel && setState(s => ({ ...s, isError: true, isLoading: false }));
      }
    };
    if (hasToFetch) {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, forceCounter, hasToFetch]);
  const { data, isLoading, isError } = state;
  return [data, isLoading, isError];
}
