import { useEffect, useState } from 'react';

// get location hash without search params (`??` used for reviews filter preset)
// and root (#/forms) hash (`#/forms/uuid`) to select correct tab and breadcrumb
const getLocationHash = () => {
  // will match hash that begins with `#/` until next first found slash `/` or `?`
  const hashMatch = window.location.hash?.match(/^#\/[^/?]*/);
  return hashMatch?.[0] || '';
};

function useHash() {
  const [hash, setHash] = useState(getLocationHash());
  const handleHashChange = () => {
    setHash(getLocationHash());
  };
  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange, false);
    return () => {
      window.removeEventListener('hashchange', handleHashChange, false);
    };
  }, []);
  return hash;
}

export default useHash;
