export default class TrustYouProductAbstract {
  constructor(permissionsWorker) {
    if (new.target === TrustYouProductAbstract) {
      throw new TypeError('Cannot construct TrustYouProduct Abstract Class instances directly');
    }

    this.permissionsWorker = permissionsWorker;
  }

  isProductEnabled() {
    throw new TypeError('Do not call abstract method.');
  }

  getProductPermissions() {
    throw new TypeError('Do not call abstract method.');
  }
}
