/* eslint-disable camelcase */

export const normalizeDate = (date) => {
  const dateArr = date.split('-');
  // Add leading zeros to month and day if needed
  if (dateArr[1].length === 1) {
    dateArr[1] = `0${dateArr[1]}`;
  }
  if (dateArr[2].length === 1) {
    dateArr[2] = `0${dateArr[2]}`;
  }
  return dateArr.join('-');
};

export const isTileDisabledByConfig = (config, tileType) => {
  if (config.feature_flags?.disabled_tiles) {
    const disabledTiles = config.feature_flags.disabled_tiles;
    return disabledTiles.includes(tileType.toLowerCase());
  }
  return false;
};

export const parseHashParams = (hashParams) => {
  const parsedHashParams = {};
  hashParams = hashParams.split('&');
  hashParams.forEach((item) => {
    const itemSplit = item.split('=');
    const key = itemSplit.shift();
    const value = itemSplit.shift();
    parsedHashParams[key] = value;
  });
  return parsedHashParams;
};
