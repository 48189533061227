const AVATAR_COLORS = [
  '#75C1F0',
  '#80CBC4',
  '#9FD06C',
  '#FADC75',
  '#FBC06A',
  '#F9AA9A',
  '#CE93D8',
  '#9FAAEB',
  '#CCCCCC'
];

export default AVATAR_COLORS;
