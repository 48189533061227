import React, { useMemo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Menu } from '@trustyou/ty-brew/mui';

import { useSelector } from 'react-redux';
import MinibarItem from '../MinibarItem';
import DropdownItem from './DropdownItem';
import { getZendeskUrl, isChatEnabled } from '../../utils';
import UserlaneWrapper from '../../../../../core/Userlane/UserlaneWrapper';
import { usePermissions } from '../../../../../contexts/permissions/permissionsContext';
import innerSidebarTabs from '../../InnerSidebar/innerSidebarTabs';
import useHash from '../../../../../hooks/useHash';
import { helpTabs, helpTabsList } from '../minibarTabs';
import config from '../../../../../config';

const { helpManual, supportChat, customerService, onlineTraining, featureTour } = helpTabs;

const HelpItem = ({ clickHandlers = {} }) => {
  const session = useSelector((state) => state.app.session);
  const { tabsPermissionsManager, permissionsWorker } = usePermissions();
  const intl = useIntl();
  const hash = useHash();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // chat
  const _isChatEnabled = useMemo(() => isChatEnabled(session), [session]);

  // feature tour
  const userlaneWrapper = useMemo(() => new UserlaneWrapper(session, tabsPermissionsManager, permissionsWorker), [
    session,
    tabsPermissionsManager,
    permissionsWorker,
  ]);
  useEffect(() => {
    userlaneWrapper.loadUserlane();
  }, [userlaneWrapper]);
  const isUserlaneEnabled = userlaneWrapper.hasUserlaneEnabled();

  const tabVisibleMap = useMemo(
    () =>
      helpTabsList.reduce((map, tab) => {
        map[tab.id] = tabsPermissionsManager.isVisible(tab.id);
        return map;
      }, {}),
    [tabsPermissionsManager]
  );

  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    const hashUrlMap = {
      [innerSidebarTabs.portfolio.hash]: config.zendesk.redirectUrls.portfolio,
      [innerSidebarTabs.dashboard.hash]: config.zendesk.redirectUrls.dashboard,
      [innerSidebarTabs.reviews.hash]: config.zendesk.redirectUrls.reviews,
      [innerSidebarTabs.comparison.hash]: config.zendesk.redirectUrls.comparison,
      [innerSidebarTabs.survey.hash]: config.zendesk.redirectUrls.survey,
      [innerSidebarTabs.templates.hash]: config.zendesk.redirectUrls.templates,
      [innerSidebarTabs.tasks.hash]: config.zendesk.redirectUrls.tasks,
      [innerSidebarTabs.goals.hash]: config.zendesk.redirectUrls.goals,
    };
    const zendeskLangMap = {
      de: 'de',
      es: 'es',
      fr: 'fr',
      ja: 'ja',
      pt: 'pt',
      zh: 'zh-cn',
      zht: 'zh-cn',
    };

    return {
      [helpManual.id]: () => {
        const returnToUrl = hashUrlMap[hash] || config.zendesk.redirectUrls.default;
        // const zendeskUrl = getZendeskUrl(session, returnToUrl);
        const customLang = zendeskLangMap[intl.locale];
        const zendeskUrl = customLang ? returnToUrl.replace('en-us', customLang) : returnToUrl;
        window.open(zendeskUrl);
      },
      [supportChat.id]: () => {
        window.zE.activate();
      },
      [customerService.id]: () => {
        const zendeskUrl = getZendeskUrl(
          session,
          config.zendesk.redirectUrls.customerServiceUrl
        );
        window.open(zendeskUrl);
      },
      [onlineTraining.id]: () => {
        window.open(`${config.urls.onlineTraining}?lang=${intl.locale}`);
      },
      [featureTour.id]: () => {
        userlaneWrapper.openFeatureTour();
      },
    };
  }, [clickHandlers, session, userlaneWrapper, hash, intl.locale]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
  };

  // item visibility
  const isItemVisible = useMemo(() => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible), [
    tabVisibleMap,
  ]);

  if (!isItemVisible) {
    return null;
  }

  return (
    <>
      <MinibarItem
        key='help'
        icon='help-o'
        label={<FormattedMessage id='help' />}
        clickHandler={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {tabVisibleMap[helpManual.id] && (
          <DropdownItem
            id={helpManual.id}
            icon={helpManual.icon}
            label={<FormattedMessage id={helpManual.labelId} />}
            clickHandler={clickHandler}
          />
        )}
        {tabVisibleMap[supportChat.id] && _isChatEnabled && (
          <DropdownItem
            id={supportChat.id}
            icon={supportChat.icon}
            label={<FormattedMessage id={supportChat.labelId} />}
            clickHandler={clickHandler}
          />
        )}
        {tabVisibleMap[customerService.id] && (
          <DropdownItem
            id={customerService.id}
            icon={customerService.icon}
            label={<FormattedMessage id={customerService.labelId} />}
            clickHandler={clickHandler}
          />
        )}
        {tabVisibleMap[onlineTraining.id] && (
          <DropdownItem
            id={onlineTraining.id}
            icon={onlineTraining.icon}
            label={<FormattedMessage id={onlineTraining.labelId} />}
            clickHandler={clickHandler}
          />
        )}
        {tabVisibleMap[featureTour.id] && isUserlaneEnabled && (
          <DropdownItem
            id={featureTour.id}
            icon={featureTour.icon}
            label={<FormattedMessage id={featureTour.labelId} />}
            clickHandler={clickHandler}
          />
        )}
      </Menu>
    </>
  );
};

HelpItem.propTypes = {
  clickHandlers: PropTypes.shape({
    [helpManual.id]: PropTypes.func,
    [supportChat.id]: PropTypes.func,
    [customerService.id]: PropTypes.func,
    [onlineTraining.id]: PropTypes.func,
    [featureTour.id]: PropTypes.func,
  }),
};
export default HelpItem;
