import React from 'react';
import { Box, Stack, Typography } from '@trustyou/ty-brew/mui';
import { FormattedMessage } from 'react-intl';

import config from '../../../config';

const Partners = () => (
  <Stack alignItems='center' paddingBlock={2}>
    <Typography variant='body2' sx={{ color: 'text.primary' }}>
      <FormattedMessage id='partner_text' defaultMessage='Official partner' />
    </Typography>
    <Box
      sx={{
        display: 'inline-block',
        maxWidth: '70px',
        maxHeight: '25px',
        verticalAlign: 'middle',
        marginTop: 1,
      }}
      component='img'
      src={`${config.urls.tyAssets}/common/img/logos/partner-google.svg`}
    />
  </Stack>
);

export default Partners;
