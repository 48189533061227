import PropTypes, { string } from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faAngleDown, faAngleUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { grey } from '@trustyou/ty-brew/colors';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@trustyou/ty-brew/mui';

const CustomCollapsibleItem = ({ title, tableHead, children }) => (
  <Box sx={{ margin: 1 }}>
    <Typography variant='h6'>{title}</Typography>
    <Table size='small'>
      {tableHead && <TableHead>{tableHead}</TableHead>}
      <TableBody>{children}</TableBody>
    </Table>
  </Box>
);

CustomCollapsibleItem.propTypes = {
  title: PropTypes.object,
  tableHead: PropTypes.element,
  TableBody: PropTypes.element,
};

const RowItem = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {open ? <Icon icon={faAngleUp} /> : <Icon icon={faAngleDown} />}
          </IconButton>
        </TableCell>
        <TableCell>{row.property_name}</TableCell>
        <TableCell align='right'>{row.property_url}</TableCell>
        <TableCell align='right'>{new Date(row.created_at).toDateString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0, backgroundColor: grey[200] }} colSpan={7}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <CustomCollapsibleItem
              tableHead={
                <TableRow>
                  <TableCell>
                    <FormattedMessage id='booking_url' defaultMessage='Booking URL' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='google_url' defaultMessage='Google URL' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='tripadvisor_url' defaultMessage='Tripadvisor URL' />
                  </TableCell>
                  <TableCell align='right'>
                    <FormattedMessage id='pms_name' defaultMessage='PMS Name' />
                  </TableCell>
                </TableRow>
              }
            >
              <TableCell sx={{ maxWidth: '300px' }}>
                <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {row.booking_url}
                </Typography>
              </TableCell>
              <TableCell sx={{ maxWidth: '300px' }}>
                <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {row.google_url}
                </Typography>
              </TableCell>
              <TableCell sx={{ maxWidth: '300px' }}>
                <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {row.tripadvisor_url}
                </Typography>
              </TableCell>
              <TableCell align='right'>{row.pms_name}</TableCell>
            </CustomCollapsibleItem>
            <CustomCollapsibleItem title={<FormattedMessage id='products' defaultMessage='Products' />}>
              {row.product.map((product) => (
                <TableRow key={product}>
                  <TableCell component='th' scope='row'>
                    {product}
                  </TableCell>
                </TableRow>
              ))}
            </CustomCollapsibleItem>

            <CustomCollapsibleItem
              title={<FormattedMessage id='users' defaultMessage='Users' />}
              tableHead={
                <TableRow>
                  <TableCell>
                    <FormattedMessage id='first_name' defaultMessage='First Name' />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id='last_name' defaultMessage='Last Name' />
                  </TableCell>
                  <TableCell align='right'>
                    <FormattedMessage id='email' defaultMessage='Email' />
                  </TableCell>
                  <TableCell align='right'>
                    <FormattedMessage id='role' defaultMessage='Role' />
                  </TableCell>
                </TableRow>
              }
            >
              {row.users.map((usersRow) => (
                <TableRow key={usersRow.email}>
                  <TableCell>{usersRow.first_name}</TableCell>
                  <TableCell>{usersRow.last_name}</TableCell>
                  <TableCell align='right'>{usersRow.email}</TableCell>
                  <TableCell align='right'>{usersRow.role}</TableCell>
                </TableRow>
              ))}
            </CustomCollapsibleItem>

            {row.competitors.length > 0 && (
              <CustomCollapsibleItem
                title={<FormattedMessage id='competitors' defaultMessage='Competitors' />}
                tableHead={
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id='property_name' defaultMessage='Property Name' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='property_url' defaultMessage='Property URL' />
                    </TableCell>
                  </TableRow>
                }
              >
                {row.competitors.map((competitorsRow) => (
                  <TableRow key={competitorsRow.property_name}>
                    <TableCell>{competitorsRow.property_name}</TableCell>
                    <TableCell>{competitorsRow.property_url}</TableCell>
                  </TableRow>
                ))}
              </CustomCollapsibleItem>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

RowItem.propTypes = {
  row: PropTypes.shape({
    property_name: PropTypes.string,
    property_url: PropTypes.string,
    created_at: PropTypes.string,
    booking_url: PropTypes.string,
    google_url: PropTypes.string,
    tripadvisor_url: PropTypes.string,
    pms_name: PropTypes.string,
    product: PropTypes.arrayOf(string),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.string,
      })
    ),
    competitors: PropTypes.arrayOf(
      PropTypes.shape({
        property_name: PropTypes.string,
        property_url: PropTypes.string,
      })
    ),
  }),
};

export default RowItem;
