import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import config from '../../../../config';

const WidgetRedirect = ({ session }) => {
  const sessionId = session.user.id;
  const { hotelId } = useParams();
  const baseUrl = config.urls.api;

  useEffect(() => {
    window.location.href = `${baseUrl}/hotels/${hotelId}/reviews.html?key=${sessionId}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>Redirecting...</>;
};

WidgetRedirect.propTypes = {
  session: PropTypes.object
};

export default WidgetRedirect;
