/*
 * Get user view from DB or create new one with default preset,
 * if view is not found (first login)
 */
export const getCreateView = async (tyraService, defaultView) => {
  let view;
  try {
    view = await tyraService.getView(defaultView.pageId);
  } catch (error) {
    // if view was not found we create new one with default view preset
    if (error.response?.status === 404) {
      view = await tyraService.createView(defaultView);
    }
  }

  const { parameters, filter, ...others } = view;
  return {
    ...others,
    parameters: parameters && JSON.parse(parameters),
    filter: filter && JSON.parse(filter)
  };
};
