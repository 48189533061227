import React from 'react';

import { Box } from '@trustyou/ty-brew/mui';

export const VogTrainings = () => (
  <Box
    component='iframe'
    sx={{ position: 'absolute', width: '100%', height: '100%' }}
    src='https://www.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;mode=AGENDA&amp;height=600&amp;wkst=1&amp;hl=en&amp;bgcolor=%23FFFFFF&amp;src=trustyou.net_22tsh0qje3mo9ommn5mlirdpck%40group.calendar.google.com&amp;color=%23B1440E&amp;ctz=Europe%2FParis'
  />
);
