import config from '../../config';
import BaseService from '../../services/BaseService';

const cxpSetPassword = async ({ userId, firstName, lastName, password, ssoToken }) => {
  const response = await BaseService.fetchOk(
    `${config.urls.host}/sso/api/users/migrate/${userId}?sso_token=${ssoToken}`,
    {
      method: 'POST',
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        password,
      }),
    }
  );

  return response;
};

export { cxpSetPassword };
