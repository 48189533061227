import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@trustyou/ty-brew/mui';

const SecondaryButton = ({ ...props }) => (
  <Link
    variant='body2'
    underline='hover'
    color='text.secondary'
    sx={{ marginBottom: 2 }}
    {...props}
  />
);

SecondaryButton.propTypes = {
  props: PropTypes.object
};

export default SecondaryButton;
