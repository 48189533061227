import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Stack from '@trustyou/ty-brew/mui/Stack';
import Button from '@trustyou/ty-brew/mui/Button';
import Typography from '@trustyou/ty-brew/mui/Typography';
import { MarketingSection, MarketingItemsList } from './MarketingCategory';
import ExternalLink from './ExternalLink';

import { getAccorCategories } from './getUpgradeCategories';
import { CATEGORIES, SURVEYS } from './const/MarketingConstants';

const UpgradeAccorContainer = ({ upgradeAccorUrls = {} }) => {
  const intl = useIntl();
  const {
    registrationUrl,
    unlockLiveExperienceTutorialUrl,
    miceTutorialUrl,
    spaTutorialUrl,
    fbTutorialUrl,
    vogJourneyTutorialUrl,
  } = upgradeAccorUrls;
  const buildExternalLink = (link, label, ariaLabel) => (
    <ExternalLink link={link || '#/'} aria-label={ariaLabel}>
      {label}
    </ExternalLink>
  );
  const unlockLiveExperienceTutorialLink = buildExternalLink(
    unlockLiveExperienceTutorialUrl,
    intl.formatMessage({ id: 'click_here', defaultMessage: '@Click here' }),
    `${intl.formatMessage({
      id: CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID,
      defaultMessage: '@Unlock Live Experience',
    })} tutorial information`
  );
  const miceTutorialLink = buildExternalLink(
    miceTutorialUrl,
    SURVEYS.MICE.VALUE,
    `${SURVEYS.MICE.VALUE} tutorial information`
  );
  const spaTutorialLink = buildExternalLink(
    spaTutorialUrl,
    SURVEYS.SPA.VALUE,
    `${SURVEYS.SPA.VALUE} tutorial information`
  );
  const fbTutorialLink = buildExternalLink(fbTutorialUrl, SURVEYS.FB.VALUE, `${SURVEYS.FB.VALUE} tutorial information`);
  const accessVOGJourneyTutorialLink = buildExternalLink(
    vogJourneyTutorialUrl,
    intl.formatMessage({ id: 'tutorial', defaultMessage: '@tutorial' }),
    `${intl.formatMessage({
      id: CATEGORIES.ACCESS_VOG_JOURNEY.ID,
      defaultMessage: '@Access VOG Journey',
    })} tutorial information`
  );
  const allCategories = getAccorCategories({
    intl,
    registrationUrl,
    unlockLiveExperienceTutorialLink,
    miceTutorialLink,
    spaTutorialLink,
    fbTutorialLink,
    accessVOGJourneyTutorialLink,
  });
  return (
    <Stack alignItems='center' spacing={7} paddingY={7}>
      {allCategories.map(({ id, title, description, link, items, tutorial }) => (
        <MarketingSection key={id} {...{ id, title, description, link }} descriptionAlignment='left'>
          {items?.length && <MarketingItemsList aria-label={`${title} list`} {...{ items }} />}
          <Typography paddingY={3} variant='body1' textAlign='left'>
            {tutorial}
          </Typography>
          <Stack alignItems='center'>
            <Button
              variant='contained'
              size='large'
              color='secondary'
              data-testid={`${id}_registration_btn`}
              href={link}
              target='_blank'
            >
              <FormattedMessage id='register_here' defaultMessage='@Register here' />
            </Button>
          </Stack>
        </MarketingSection>
      ))}
    </Stack>
  );
};

UpgradeAccorContainer.propTypes = {
  /**
   * Urls related to static content per category for upgrade Accor page
   */
  upgradeAccorUrls: PropTypes.shape({
    unlockLiveExperienceTutorialUrl: PropTypes.string,
    miceTutorialUrl: PropTypes.string,
    spaTutorialUrl: PropTypes.string,
    fbTutorialUrl: PropTypes.string,
    vogJourneyTutorialUrl: PropTypes.string,
    registrationUrl: PropTypes.string,
  }),
};

export default UpgradeAccorContainer;
