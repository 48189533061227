import innerSidebarTabs, { redirects } from '../../features/Tyra/Sidebar/InnerSidebar/innerSidebarTabs';
import minibarTabs from '../../features/Tyra/Sidebar/Minibar/minibarTabs';
import PERMISSIONS from '../../models/Permissions/Permissions';
import VERTICALS from '../../models/Verticals';

const TabsPermissionsMap = {
  // INBOX
  [innerSidebarTabs.reviews.id]: {
    grantedPermissions: [PERMISSIONS.REVIEWS],
    hiddenPermissions: [PERMISSIONS.HIDE_ANALYTICS, PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.conversations.id]: {
    grantedPermissions: [
      PERMISSIONS.MESSAGING_ADMIN_USER,
      PERMISSIONS.MESSAGING_MANAGER_USER,
      PERMISSIONS.MESSAGING_EMPLOYEE_USER,
      PERMISSIONS.ONSITE_ADMIN,
      PERMISSIONS.ONSITE_MANAGER,
      PERMISSIONS.ONSITE,
    ],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.rir.id]: {
    grantedPermissions: [PERMISSIONS.RIR],
    hiddenPermissions: [PERMISSIONS.HIDE_ANALYTICS, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },

  // REPORTS
  [innerSidebarTabs.dashboard.id]: {
    grantedPermissions: [PERMISSIONS.DASHBOARD],
    hiddenPermissions: [PERMISSIONS.HIDE_ANALYTICS, PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.portfolio.id]: {
    grantedPermissions: [PERMISSIONS.PORTFOLIO],
    hiddenPermissions: [PERMISSIONS.HIDE_ANALYTICS, PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.comparison.id]: {
    grantedPermissions: [PERMISSIONS.COMPARISON],
    hiddenPermissions: [PERMISSIONS.HIDE_ANALYTICS, PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.survey.id]: {
    grantedPermissions: [PERMISSIONS.SURVEY, PERMISSIONS.SURVEY_LITE, PERMISSIONS.MESSAGING],
    hiddenPermissions: [PERMISSIONS.HIDE_SURVEY, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.statistics.id]: {
    grantedPermissions: [
      PERMISSIONS.MESSAGING_ADMIN_USER,
      PERMISSIONS.MESSAGING_MANAGER_USER,
      PERMISSIONS.MESSAGING_EMPLOYEE_USER,
    ],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.goals.id]: {
    grantedPermissions: [PERMISSIONS.OPERATIONS],
    hiddenPermissions: [PERMISSIONS.HIDE_OPERATIONS, PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.advancedBi.id]: {
    grantedPermissions: [PERMISSIONS.ADVANCED_BI],
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },

  // PROMOTIONS
  [innerSidebarTabs.widgets.id]: {
    grantedPermissions: [PERMISSIONS.MARKETING],
    hiddenPermissions: [PERMISSIONS.HIDE_MARKETING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },

  // SETUP
  [innerSidebarTabs.forms.id]: {
    grantedPermissions: [PERMISSIONS.FORMS],
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.outreaches.id]: {
    grantedPermissions: [PERMISSIONS.MESSAGING_ADMIN_USER],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.distribution.id]: {
    grantedPermissions: [PERMISSIONS.ONSITE_ADMIN, PERMISSIONS.ONSITE_MANAGER, PERMISSIONS.ONSITE_DISTRIBUTION],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.integrations.id]: {
    grantedPermissions: [PERMISSIONS.MESSAGING_ADMIN_USER],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.customers.id]: {
    grantedPermissions: [PERMISSIONS.MESSAGING_ADMIN_USER],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.templates.id]: {
    grantedPermissions: [PERMISSIONS.SURVEY_TEMPLATES],
    hiddenPermissions: [PERMISSIONS.HIDE_SURVEY, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.tasks.id]: {
    grantedPermissions: [PERMISSIONS.OPERATIONS],
    hiddenPermissions: [PERMISSIONS.HIDE_OPERATIONS, PERMISSIONS.MANAGE_USERS],
  },

  // SETTINGS for miniBar
  [minibarTabs.postStay.id]: {
    grantedPermissions: [PERMISSIONS.SETTINGS],
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [minibarTabs.live.id]: {
    grantedPermissions: [
      PERMISSIONS.MESSAGING_ADMIN_USER,
      PERMISSIONS.MESSAGING_MANAGER_USER,
      PERMISSIONS.MESSAGING_EMPLOYEE_USER,
      PERMISSIONS.ONSITE_ADMIN,
      PERMISSIONS.ONSITE_MANAGER,
      PERMISSIONS.ONSITE,
    ],
    hiddenPermissions: [PERMISSIONS.HIDE_MESSAGING, PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },

  // HELP
  [minibarTabs.helpManual.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [minibarTabs.supportChat.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [minibarTabs.customerService.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [minibarTabs.onlineTraining.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [minibarTabs.featureTour.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },

  [minibarTabs.upgrade.id]: {
    grantedWithNoPermission: true,
    hiddenPermissions: [PERMISSIONS.MANAGE_USERS],
  },

  // ADMIN
  [innerSidebarTabs.hotelAdmin.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.usersAdmin.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
  },
  [innerSidebarTabs.surveysAdmin.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.newUsersAdmin.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.clientOnboarding.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS],
  },
  [innerSidebarTabs.responseAi.id]: {
    grantedPermissions: [PERMISSIONS.MANAGE_USERS],
  },

  // REDIRECTS
  [redirects.widgetRedirect.id]: {
    grantedPermissions: [PERMISSIONS.SURVEY],
    hiddenOnVerticals: [VERTICALS.RESTAURANTS, VERTICALS.HOTELS],
  },
};

export default TabsPermissionsMap;
