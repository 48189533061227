import { Icon } from '@trustyou/ty-brew';
import { Typography, Tooltip, ListItemIcon, ListItemButton, ListItemText, Chip } from '@trustyou/ty-brew/mui';
import PropTypes from 'prop-types';
import React from 'react';

const TabChip = ({ label, max = 99, ...props }) => (
  <Chip
    sx={{
      height: '20px',
      fontSize: '0.75rem',
      fontWeight: 500,
      cursor: 'inherit',
    }}
    size='small'
    label={Number(label) > max ? `${max}+` : label}
    {...props}
  />
);

TabChip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.number,
};

const ConditionalTooltip = ({ id, condition, children, ...other }) => {
  if (condition) {
    return (
      <Tooltip key={id} {...other}>
        {children}
      </Tooltip>
    );
  }
  return children;
};

ConditionalTooltip.propTypes = {
  id: PropTypes.string,
  condition: PropTypes.bool,
};

const useEnhancedEffect = typeof window === 'undefined' ? React.useEffect : React.useLayoutEffect;

const Tab = ({ id, icon, label, badge, chip, clickHandler, ...listItemProps }) => {
  const labelRef = React.useRef();
  const [showLabelTooltip, setShowLabelTooltip] = React.useState(false);

  const _clickHandler = (e) => {
    // eslint-disable-next-line no-unused-expressions
    clickHandler?.(e, id);
  };

  if (listItemProps.href) {
    listItemProps.component = 'a';
  }

  useEnhancedEffect(() => {
    if (labelRef.current?.scrollWidth > labelRef.current?.offsetWidth) {
      setShowLabelTooltip(true);
    } else {
      setShowLabelTooltip(false);
    }
  });
  return (
    <ConditionalTooltip
      key={id}
      condition={showLabelTooltip}
      id={id}
      title={label}
      placement='right'
      enterDelay={500}
      arrow
    >
      <ListItemButton
        data-gtm-class='sidebar-menuitem'
        data-gtm-id={`sidebar-menuitem-${id}`}
        dense
        sx={{
          color: 'text.secondary',
          '& .MuiListItemIcon-root': {
            minWidth: '24px',
            color: 'inherit',
          },
        }}
        onClick={_clickHandler}
        {...listItemProps}
      >
        {icon && (
          <ListItemIcon>
            <Icon icon={icon} />
          </ListItemIcon>
        )}
        {label && (
          <ListItemText>
            <Typography
              variant='body2'
              ref={labelRef}
              sx={{
                color: 'inherit',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {label}
            </Typography>
          </ListItemText>
        )}
        {!!chip && <TabChip color='info' label={chip} />}
        {!!badge && <TabChip color='error' label={badge} />}
      </ListItemButton>
    </ConditionalTooltip>
  );
};

Tab.displayName = 'NavigationItem';

Tab.propTypes = {
  id: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clickHandler: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.any.isRequired,
  selected: PropTypes.bool,
  chip: PropTypes.string,
};

export default Tab;
