import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography, styled } from '@trustyou/ty-brew/mui';

import {
  adminSection,
  inboxSection,
  promotionsSection,
  reportsSection,
  setupSection,
} from '../../Sidebar/InnerSidebar/innerSidebarTabs';
import { primaryTabs, settingsSections } from '../../Sidebar/Minibar/minibarTabs';

const StyledTypography = styled(Typography)({
  fontWeight: '500',
  position: 'relative',
  paddingLeft: '24px',
  paddingRight: '24px',
});

// get hash > breadcrumbs labels mapping
// { '#reviews': ['inbox_i18n_id', 'reviews_i18n_id'], ... }
const hashMap = {
  [primaryTabs.upgrade.hash]: [primaryTabs.upgrade.labelId],
};
[settingsSections, reportsSection, inboxSection, promotionsSection, setupSection, adminSection].forEach((section) => {
  section.tabsList.forEach((tab) => {
    if (tab.hash) {
      hashMap[tab.hash] = [section.labelId, tab.labelId, tab.labelDefaultMessage];
    }
  });
});

const Breadcrumbs = ({ tabHash }) => {
  const [sectionLabelId, tabLabelId, tabLabelDefaultMessage] = hashMap[tabHash] || [];
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {sectionLabelId && (
        <StyledTypography
          variant='body1'
          className='breadcrumb_bundle'
          sx={[
            !!tabLabelId && {
              color: 'text.disabled',
              '&::before, &::after': {
                content: '""',
                width: '33px',
                height: '1px',
                backgroundColor: 'grey.200',
                display: 'block',
                position: 'absolute',
                transformOrigin: '0 0',
              },
              '&::before': {
                transform: 'rotate(70deg)',
                top: '-19px',
                right: '-22px',
              },
              '&::after': {
                transform: 'rotate(-70deg)',
                bottom: '-20px',
                right: '-21px',
              },
            },
          ]}
        >
          <FormattedMessage id={sectionLabelId} />
        </StyledTypography>
      )}
      {tabLabelId && (
        <StyledTypography variant='body1' className='breadcrumb_tab'>
          <FormattedMessage id={tabLabelId} defaultMessage={tabLabelDefaultMessage} />
        </StyledTypography>
      )}
    </Box>
  );
};

Breadcrumbs.propTypes = {
  tabHash: PropTypes.string,
};

export default Breadcrumbs;
