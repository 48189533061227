import React from 'react';
import PropTypes from 'prop-types';
import Button from '@trustyou/ty-brew/mui/Button';

const ExternalLink = ({ link, children, ...otherProps }) => (
  <Button
    sx={{
      textTransform: 'inherit',
      verticalAlign: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      p: 0,
      minWidth: 0,
      textDecoration: 'underline',
      '&:hover': { textDecoration: 'underline' },
    }}
    href={link}
    target='_blank'
    color='secondary'
    {...otherProps}
  >
    {children}
  </Button>
);

ExternalLink.propTypes = {
  /**
   * Represents href attr of anchor element
   */
  link: PropTypes.string,
};

export default ExternalLink;
