/* eslint-disable object-shorthand, prefer-destructuring */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import envConfig from '../../../../config';
import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../../contexts/services/servicesContext';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import PageLoading from '../../PageLoading';
import { getCreateView } from '../service';
import { getDefaultTiles } from '../tiles';
import { isTileDisabledByConfig, normalizeDate } from '../utils';
import DashboardView, { DashboardGlobalStyles } from './DashboardView';
import { getRenderData } from './service';

const Dashboard = ({ require }) => {
  const [forceCounter, setForceCounter] = useState(0);

  const session = useSelector((state) => state.app.session);

  const { permissionsWorker } = usePermissions();
  const { portfolioService, tyraService, hotelsService, ssoService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get view from DB or set default one
      const defaultView = {
        type: 'DASHBOARD_MODULES',
        pageId: 'dashboard',
        parameters: {
          modules: getDefaultTiles(envConfig.defaultTiles.dashboard, {
            generic: permissionsWorker.hasPermission([PERMISSIONS.DASHBOARD_TILES]),
            special: [
              {
                permission: permissionsWorker.hasPermission([PERMISSIONS.DASHBOARD_MESSAGING_DIRECTION_TILE]),
                tiles: ['MessageDirection'],
              },
            ],
          }),
          filter: {
            time: { time: 'last30days' },
          },
        },
      };
      const view = await getCreateView(tyraService, defaultView);

      const isSwitchedToCompetitor =
        !permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]) &&
        session.selectedCluster?.id !== session.defaultCluster.id;

      const renderData = await getRenderData({
        portfolioService,
        hotelsService,
        session,
        isSwitchedToCompetitor,
      });
      require(['dashboard-agg'], () => {
        require([
          'jquery',
          'tyra-5/static/js/config',
          'ty-i18n!analytics_js_4x',
          'ty-i18n!survey-app',
          'ty-i18n!tyra-5',
          'ty-i18n!dashboard-app',
          'ty-i18n!tasks-app_js',
          'ty-i18n!goals-app_js',
          'ty-i18n!metacategory',
          'dashboard-app-export',
          'export-pane',
          'tyra-5/static/js/views/dashboard_modules_utils',
          'dropdownsmartlist-template',
          'add-tile-button',
          'dashboard/static/js/utils',
          'tyra-5/static/js/topbar',
          'timedropdown-v2',
          'dashboard-predefined',
          'review-plugins',
          'tyra-5/static/js/common/chooser',
          'export-app',
          'table',
          'paginator',
          'jquery-ui',
          'jquery-textfill'
        ], (
          $,
          config,
          i18n,
          surveyI18n,
          tyra5I18n,
          dashboardI18n,
          tasksI18n,
          goalsI18n,
          metacategoryI18n,
          exportModule,
          exportPane,
          moduleUtils,
          ddSmartList,
          AddTileButton,
          dashboardUtils
        ) => {
          // get to/from dates that are relevant to now()
          view.parameters.filter = {
            time: $.ty.timedropdown.relativeValue(view.parameters.filter?.time || defaultView.parameters.filter.time),
          };

          const content = $('#dashboard-view');
          content.empty();
          // Add topbar
          const topBar = $('<div></div>').appendTo(content).TopBar();
          topBar.init({
            timeDropdown: true,
            timeDropdownParams: {
              time: view.parameters.filter.time,
              minDate: dashboardUtils.getTimeDropdownMinDate ? dashboardUtils.getTimeDropdownMinDate() : undefined,
              saveTimeToView: async (time) => {
                view.parameters.filter = { time };
                // save filter in DB view parameters
                if (view?.id) {
                  await tyraService.updateView(view);
                }
                // reload the tab
                setForceCounter((counter) => counter + 1);
              },
            },
          });

          const tabKey = 'dashboard';

          // Inevitable Tiles/Modules (tiles that canot be reordered nor removed nor added, will be present always)
          // TODO: make this configurable
          const inevitableModuleNames = ['ReviewsBreakdown', 'PerformanceScore', 'ResponseRate'];
          // TODO: remove all code from deprecated tiles on next iteration.
          const deprecatedTiles = ['MessageDirection'];
          const exportParameters = moduleUtils.exportParameters.bind(content, tabKey);

          const filter = view.parameters.filter,
            parameters = view.parameters,
            clusterId = session.selectedCluster.id,
            clusterName = session.selectedCluster.name,
            reviewsPerPage = 5,
            monthLabels = [
              i18n.common_january_3,
              i18n.common_february_3,
              i18n.common_march_3,
              i18n.common_april_3,
              i18n.common_may_3,
              i18n.common_june_3,
              i18n.common_july_3,
              i18n.common_august_3,
              i18n.common_september_3,
              i18n.common_october_3,
              i18n.common_november_3,
              i18n.common_december_3,
            ],
            defaultThresholds = {
              negative: 0,
              neutral: 40,
              positive: 60,
            };

          let mainGrid = null,
            currentTile,
            exportErrorController;

          const addTileButtonId = '#add-dashboard-tile-container';
          const onAddModules = (deferreds, grid) => {
            $.when(...deferreds.tileDeferreds).then(() => {
              grid.find('.tile-blank').remove().DashboardModule().destroy();
              const onAddTileButton = () => $(addTileButtonId).trigger('click');
              const ghostTile = new AddTileButton(onAddTileButton);
              grid.addModule(ghostTile.iconParams, ghostTile.detailsParams);
              grid.rearrange();
            });
          };
          const hasTilesPermission = permissionsWorker.hasPermission([PERMISSIONS.DASHBOARD_TILES]);
          const addTileButton = function () {
            topBar.find(addTileButtonId).remove();

            topBar.addAction(
              $(
                `<li id="add-dashboard-tile-container"><a><span>${i18n.common_addtiles}</span><i class="ty-icon ty-icon-plus"></i></a></li>`
              ).click(
                (function () {
                  let allModules = hasTilesPermission
                    ? [
                        {
                          type: 'TrustScore',
                          label: i18n.overall_score,
                        },
                        {
                          type: 'PerformanceIndex',
                          label: i18n.dashboard_lbl_impact_score,
                        },
                        {
                          type: 'Reviews',
                          label: i18n.reviews,
                        },
                        {
                          type: 'Languages',
                          label: dashboardI18n.languages,
                        },
                        {
                          type: 'PopularityRank',
                          label: i18n.dashboard_popularity_header,
                        },
                        {
                          type: 'Goals',
                          label: i18n.common_goals,
                        },
                        {
                          type: 'Tasks',
                          label: i18n.common_tasks,
                        },
                        {
                          type: 'SurveyAlerts',
                          label: i18n.common_alerts,
                        },
                        {
                          type: 'CategoryScores',
                          label: i18n.common_categoryscores,
                        },
                        {
                          type: 'Comparison',
                          label: i18n.onlinereport_comparison,
                        },
                        {
                          type: 'Highlights',
                          label: i18n.dashboard_highlights_header,
                        },
                      ]
                    : [];

                  if (permissionsWorker.hasPermission([PERMISSIONS.DASHBOARD_MESSAGING_DIRECTION_TILE])) {
                    allModules.push({
                      type: 'MessageDirection',
                      label: tyra5I18n.messageDirectionTileTitle,
                    });
                  }

                  if (permissionsWorker.hasPermission([PERMISSIONS.SOCIAL])) {
                    allModules.push({
                      type: 'Social',
                      label: i18n.common_social,
                    });
                  }

                  // if (session.roles.indexOf('enterprise') === -1) {
                  allModules.push({
                    type: 'ReviewsBreakdown',
                    label: i18n.dashboard_volume_title || 'Reviews Breakdown',
                  });
                  allModules.push({
                    type: 'ResponseRate',
                    label: i18n.help_response_rate_name || 'Response Rate',
                  });
                  allModules.push({
                    type: 'PerformanceScore',
                    label: i18n.performance_score || 'Performance Score',
                  });
                  // }

                  // Remove Inevitable Modules/Tiles from the "Add Tiles" Menu (they are included by default in the grid)
                  allModules = allModules.filter(({ type }) => !inevitableModuleNames.includes(type));

                  // Remove any deprecated tile. (Remove any deprecated tile)
                  allModules = allModules.filter(({ type }) => !deprecatedTiles.includes(type));

                  // TODO Remove Responses tile if new tiles are enabled
                  return function (event) {
                    const selectedModules = view.parameters.modules,
                      selectedModuleTypes = $.map(selectedModules, (module) => {
                        // Append the source id if it's present
                        if (module.sourceId) {
                          return `${module.type}_${module.sourceId}`;
                        } else if (module.departmentId) {
                          return `${module.type}_${module.departmentId}`;
                        }

                        return module.type;
                      }),
                      preSelectedItems = [],
                      selectedItems = [],
                      departments = config.departments,
                      preselectedSourceIDs = [
                        config.sources['booking.com'],
                        config.sources.holidaycheck,
                        config.sources.tripadvisor,
                        config.sources.expedia,
                        config.sources['hotels.com'],
                      ],
                      starwoodRMISourceID = config.sources.starwoodrmi;

                    const generalItems = $.map(allModules, (tile) => ({
                      name: tile.label,
                      data: {
                        type: tile.type,
                      },
                      disabled: selectedModuleTypes.indexOf(tile.type) !== -1,
                    }));

                    const sourceItems = hasTilesPermission
                      ? $.map(renderData.sources, (source) => {
                          let sourceType = 'Source';

                          if (source.id === starwoodRMISourceID) {
                            sourceType = 'StarwoodRMISource';
                          } else if (source.id === '-1') {
                            return;
                          }

                          const result = {
                            name: source.name,
                            data: {
                              type: sourceType,
                              sourceId: source.id,
                            },
                            disabled:
                              selectedModuleTypes.indexOf(`Source_${source.id}`) !== -1 ||
                              selectedModuleTypes.indexOf(`StarwoodRMISource_${source.id}`) !== -1,
                          };

                          // move selected out of the list and into the selected sources area;
                          if (preselectedSourceIDs.indexOf(source.id) !== -1) {
                            preSelectedItems.push(result);

                            return;
                          }
                          if (result.disabled) {
                            selectedItems.push(result);

                            return;
                          }

                          return result; // eslint-disable-line consistent-return
                        })
                      : [];

                    const departmentItems = hasTilesPermission
                      ? $.map(departments, (categories, department) => {
                          const sourceType = 'CategoryScores';

                          const result = {
                            name: metacategoryI18n[department],
                            data: {
                              type: sourceType,
                              departmentId: department,
                            },
                            disabled: selectedModuleTypes.indexOf(`${sourceType}_${department}`) !== -1,
                          };

                          if (result.disabled) {
                            selectedItems.push(result);

                            return;
                          }

                          return result; // eslint-disable-line consistent-return
                        })
                      : [];

                    $.fancybox.showActivity();
                    $.fancybox({
                      margin: 13,
                      padding: 10,
                      content: `\
<div class="modal-add-tile-dashboard select-tiles">
  <div class="modal-header">
    <h2>${dashboardI18n.add_tiles_title}</h2>
    <h3>${dashboardI18n.add_tiles_subtitle}</h3>
  </div>
  <div class="modal-body">
    <section class="general-tiles is-hidden">
      <h3>${i18n.common_general}</h3>
    </section>
    <section class="department-tiles is-hidden">
      <h3>${tyra5I18n.departments}</h3>
    </section>
    <section class="sources-tiles">
      <h3>${i18n.sources}</h3>
      <div class="sources-wrapper">
        <div class="module-sources"></div>
        <div class="active-sources">
          <div class="preselected-sources"></div>
          <div class="selected-sources"></div>
        </div>
      </div>
    </section>
  </div>
  <div class="modal-footer">
    <div class="save-button-panel l-align-right">
      <div class="trustyou-ui btn btn-primary btn-lg save-button">${i18n.save}</div>
    </div>
  </div>
</div>`,
                      onComplete: function () {
                        const $content = $('#fancybox-content'),
                          chooserContainerEl = $content.find('.module-sources'),
                          generalContainerEl = $content.find('.general-tiles'),
                          departmentContainerEl = $content.find('.department-tiles'),
                          sourceTileContainerEl = $content.find('.sources-tiles'),
                          selectedContainerEl = $content.find('.selected-sources'),
                          saveButton = $content.find('.save-button').button(),
                          selectedClass = 'ui-selected',
                          disabledClass = 'ui-state-disabled';
                        let addTileHandler, removeTileHandler;

                        const generateButton = function (label, selected, classes, type, sourceid, departmentId) {
                          const button = $('<div></div>').addClass(`selectable-tile ${classes}`).button({
                            label,
                          });

                          if (selected) {
                            button.addClass(selectedClass);
                          }

                          button.click(function () {
                            const $el = $(this);

                            if ($el.hasClass(disabledClass)) {
                              // do nothing
                              return;
                            }

                            if ($el.hasClass(selectedClass)) {
                              // remove from grid
                              removeTileHandler(type, sourceid, departmentId);
                            } else {
                              // add to grid
                              addTileHandler(type, sourceid, departmentId);
                            }
                          });

                          // store type and source information in button
                          button.attr('data-type', type);
                          if (sourceid) {
                            button.attr('data-sourceid', sourceid);
                          }
                          if (departmentId) {
                            button.attr('data-departmentid', departmentId);
                          }

                          return button;
                        };

                        addTileHandler = function (type, sourceid, departmentId) {
                          const button = generalContainerEl.find(`[data-type="${type}"]`);

                          if (sourceid && (type === 'Source' || type === 'StarwoodRMISource')) {
                            const selectedButton = selectedContainerEl.find(`[data-sourceid="${sourceid}"]`);
                            const sourceListItem = chooserContainerEl.find(`[data-sourceid="${sourceid}"]`);

                            selectedButton.addClass(selectedClass);

                            // important!! remove .ui-selected class, or else it will be added twice!!
                            sourceListItem.removeClass(selectedClass);
                            sourceListItem.addClass(`disabled ${disabledClass}`);
                            sourceListItem.hide();

                            // prevent searching for the source again
                            chooserContainerEl.find('.text-search-input.search').keyup(() => {
                              chooserContainerEl.find('.disabled').hide();
                            });
                          } else if (departmentId && type === 'CategoryScores') {
                            departmentContainerEl.find(`[data-departmentid="${departmentId}"]`).addClass(selectedClass);
                          } else {
                            button.addClass(selectedClass);
                          }
                        };

                        removeTileHandler = function (type, sourceid, departmentId) {
                          const button = generalContainerEl.find(`[data-type="${type}"]`);

                          if (sourceid && (type === 'Source' || type === 'StarwoodRMISource')) {
                            const selectedButton = selectedContainerEl.find(`[data-sourceid="${sourceid}"]`);
                            const sourceListItem = chooserContainerEl.find(`[data-sourceid="${sourceid}"]`);

                            selectedButton.removeClass(selectedClass);

                            if (preselectedSourceIDs.indexOf(sourceid.toString()) === -1) {
                              selectedButton.remove();
                            }

                            // important!! remove .ui-selected class, or else it will be added twice!!
                            sourceListItem.removeClass(selectedClass);
                            sourceListItem.removeClass(disabledClass);
                            sourceListItem.removeClass('disabled');

                            // show it again in the chooser
                            sourceListItem.show();
                          } else if (departmentId && type === 'CategoryScores') {
                            departmentContainerEl
                              .find(`[data-departmentid="${departmentId}"]`)
                              .removeClass(selectedClass);
                          } else {
                            button.removeClass(selectedClass);
                          }
                        };

                        if (!generalContainerEl.hasClass('is-hidden')) {
                          generalContainerEl.addClass('is-hidden');
                        }

                        $.each(generalItems, (idx, generalTileItem) => {
                          if (isTileDisabledByConfig(config, generalTileItem.data.type)) {
                            return;
                          }

                          if (generalTileItem.disabled) {
                            return;
                          }

                          generalContainerEl.removeClass('is-hidden');

                          const button = generateButton(
                            generalTileItem.name,
                            generalTileItem.disabled,
                            'general',
                            generalTileItem.data.type
                          );
                          button.appendTo(generalContainerEl);
                        });

                        if (sourceItems.length > 0) {
                          // Add source list
                          chooserContainerEl.chooser({
                            items: sourceItems,
                            labels: {
                              title: i18n.renderers_dashboard_findmoresources,
                            },
                          });
                        } else {
                          $('<div class="sources-notification"></div>').appendTo(chooserContainerEl);

                          if (!selectedContainerEl.find('.selectable-tile').length) {
                            chooserContainerEl
                              .find('.sources-notification')
                              .html(i18n.renderers_dashboard_message_alladded);
                          } else {
                            chooserContainerEl
                              .find('.sources-notification')
                              .html(i18n.renderers_dashboard_message_selectone);
                          }
                        }

                        if (!hasTilesPermission) {
                          sourceTileContainerEl.addClass('is-hidden');
                        }

                        if (!departmentContainerEl.hasClass('is-hidden')) {
                          departmentContainerEl.addClass('is-hidden');
                        }

                        const departmentTilesDisabled = isTileDisabledByConfig(config, 'departmenttiles');

                        if (!departmentTilesDisabled) {
                          $.each(departmentItems, (idx, departmentTileItem) => {
                            if (departmentTileItem.disabled) {
                              return;
                            }

                            departmentContainerEl.removeClass('is-hidden');

                            if (!(config.vertical.name === 'rest' && departmentTileItem.data.departmentId === '36')) {
                              const button = generateButton(
                                departmentTileItem.name,
                                departmentTileItem.disabled,
                                'departments',
                                departmentTileItem.data.type,
                                undefined,
                                departmentTileItem.data.departmentId
                              );
                              button.appendTo(departmentContainerEl);
                            }
                          });
                        }

                        $.each(preSelectedItems, (idx, preSelectedTileItem) => {
                          if (preSelectedTileItem.disabled) {
                            // don't continue if the item is already in the dashboard
                            return;
                          }

                          generateButton(
                            preSelectedTileItem.name,
                            preSelectedTileItem.disabled,
                            'preselected',
                            preSelectedTileItem.data.type,
                            preSelectedTileItem.data.sourceId
                          ).appendTo(selectedContainerEl);
                        });

                        chooserContainerEl.find('.chooser-item').click(function () {
                          const $el = $(this),
                            type = $el.data('type'),
                            sourceid = $el.data('sourceid');

                          if (!$el.hasClass(disabledClass) && !$el.hasClass('disabled')) {
                            addTileHandler(type, sourceid);
                            generateButton($el.text(), true, 'moved-source', type, sourceid).appendTo(
                              selectedContainerEl
                            );
                          }
                        });

                        // Save action
                        $(saveButton)
                          .button()
                          .click(function () {
                            const $el = $(this),
                              newModules = [],
                              newSelectedItems = $content.find(`.select-tiles .${selectedClass}`);

                            $el.button('disable'); // to avoid duplicate sources.

                            newSelectedItems.each((_, item) => {
                              const $item = $(item),
                                dataType = $item.attr('data-type'),
                                dataSourceId = $item.attr('data-sourceid'),
                                departmentId = $item.attr('data-departmentid');

                              let candidate = {
                                  type: dataType,
                                  expanded: false,
                                },
                                detailedParams;

                              if (departmentId && dataType === 'CategoryScores') {
                                detailedParams = {
                                  departmentId,
                                };
                                candidate = $.extend(candidate, detailedParams);
                              } else if (dataSourceId && dataType === 'Source') {
                                detailedParams = {
                                  sourceId: dataSourceId,
                                  details: [
                                    {
                                      type: 'Comparison',
                                      sourceId: dataSourceId,
                                      expanded: false,
                                    },
                                    {
                                      type: 'PerformanceIndex',
                                      sourceId: dataSourceId,
                                      expanded: false,
                                    },
                                    {
                                      type: 'Reviews',
                                      sourceId: dataSourceId,
                                      expanded: false,
                                    },
                                    {
                                      type: 'Responses',
                                      sourceId: dataSourceId,
                                      expanded: false,
                                    },
                                    {
                                      type: 'CategoryScores',
                                      sourceId: dataSourceId,
                                      expanded: false,
                                    },
                                  ],
                                };

                                if (dataSourceId !== config.sources.holidaycheck) {
                                  detailedParams.details.push({
                                    type: 'SourcePopularityRank',
                                    sourceId: dataSourceId,
                                    expanded: false,
                                  });
                                }

                                if (dataSourceId === config.sources.tripadvisor) {
                                  detailedParams.details.push({
                                    type: 'SourceGuestProfile',
                                    sourceId: dataSourceId,
                                    expanded: false,
                                  });
                                }

                                if (dataSourceId === config.sources.holidaycheck) {
                                  detailedParams.details.push({
                                    type: 'SourceRecommendationRate',
                                    sourceId: dataSourceId,
                                    expanded: false,
                                  });
                                }

                                candidate = $.extend(candidate, detailedParams);
                              }

                              if (dataSourceId && dataType === 'StarwoodRMISource') {
                                candidate = $.extend(candidate, {
                                  sourceId: dataSourceId,
                                });
                              }

                              newModules.push(candidate);
                            });

                            if (newModules.length > 0 && mainGrid) {
                              addModules(mainGrid, newModules); // eslint-disable-line no-use-before-define
                              saveModuleStates(mainGrid); // eslint-disable-line no-use-before-define

                              onAddModules(moduleDeferreds, mainGrid); // eslint-disable-line no-use-before-define
                            }

                            $('.undo-remove-container').parent().css('display', 'none');

                            $.fancybox.close();
                          });

                        $.fancybox.resize();
                        $.fancybox.center();
                        $.fancybox.hideActivity();
                      },
                    });
                  };
                })()
              ),
              0
            );
          };

          const addCompetitorSwitchButton = () => {
            // show only for gold users
            if (permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO])) {
              return;
            }

            async function switchHotel(newClusterId) {
              await ssoService.putUserProperty({
                userId: session.user.id,
                name: 'selected_cluster',
                value: newClusterId,
                ssoToken: session.ssoToken,
              });
              window.location.reload();
            }

            const compList = [...renderData.competitors];
            if (compList.length === 0) {
              return;
            }

            if (isSwitchedToCompetitor) {
              $('#dashboard-return-to-default-hotel').hide();

              const defaultHotel = {
                clusterId: session.defaultCluster.id,
                clusterName: session.defaultCluster.name,
              };
              compList.push(defaultHotel);
            }

            const idStr = 'dashboard-see-competitors';
            topBar.find(`#${idStr}`).remove();
            const items = compList
              .filter((comp) => comp.clusterName)
              .map((comp) => ({
                value: comp.clusterId,
                label: comp.clusterName,
              }))
              .sort((a, b) => a.label.localeCompare(b.label));
            const $dd = $(
              ddSmartList({
                items: items,
                search: false,
                selectAll: false,
                toggle: tyra5I18n.change_hotel,
                icon: 'ty-icon-shuffle',
                callToAction: isSwitchedToCompetitor && {
                  text: tyra5I18n.competitor_dropdown_cta,
                  buttonText: tyra5I18n.back_to_own_hotel,
                  buttonClass: 'ty-icon-undo l-left',
                },
                lright: false,
              })
            );

            $dd.find(`[data-value="${session.defaultCluster.id}"]`).addClass('default-hotel');

            const $btn = $(`<li id="${idStr}" class="${$dd.prop('class')}"></li>`);
            $btn.append($dd.contents());
            $btn.find('.smart-list-cta > a.btn').click((e) => {
              switchHotel(session.defaultCluster.id);
            });
            $btn.dropdownsmartlist({
              change: (evt, data) => {
                const newClusterId = data.val[0];
                if (newClusterId) {
                  switchHotel(newClusterId);
                }
              },
            });
            topBar.addAction($btn, 3);
          };

          if (renderData.noData) {
            $('<div class="dashboard-no-data"></div>').text(i18n.dashboard_no_data).appendTo(content);

            return;
          }

          addTileButton();
          addCompetitorSwitchButton();

          const from = normalizeDate(filter.time.fromDate);
          const to = filter.time.toDate && normalizeDate(filter.time.toDate);
          const time = filter.time.time;

          // The module data is saved in the 'module-data' attribute so that it's not lost when destroying them
          function getModuleData(module) {
            return JSON.parse($(module).attr('module-data') || '{}');
          }

          function setModuleData(module, data) {
            const $el = $(module),
              oldStr = $el.attr('module-data'),
              newStr = JSON.stringify(data);

            if (oldStr !== newStr) {
              $el.attr('module-data', newStr);
            }
          }

          function saveModuleStates(grid) {
            const getModules = function (newGrid) {
              const modules = [];

              $.each($.makeArray(newGrid.getModules()), (_, module) => {
                const moduleData = getModuleData(module),
                  detailModules = [];
                let detailGrid, detailPanel;

                if (moduleData.type === 'Source') {
                  detailPanel = $(module).DashboardModule().getDetailPanel();
                  detailGrid = $(detailPanel).find('.dashboard-grid').DashboardGrid();
                  $.each(detailGrid.getModules(), (__, detailModule) => {
                    detailModules.push(getModuleData(detailModule));
                  });
                  if (detailModules.length > 0) {
                    // don't override details that aren't not loaded yet (possible because of lazy loading)
                    moduleData.details = detailModules;
                  }
                }

                modules.push(moduleData);
              });

              return modules;
            };

            const mods = getModules(grid);
            if (isSwitchedToCompetitor) {
              // we must take care of comparison tile
              const idxComparison = view.parameters.modules.findIndex((x) => x.type === 'Comparison');
              if (idxComparison !== -1) {
                mods.splice(idxComparison, 0, view.parameters.modules[idxComparison]);
              }
            }

            view.parameters = { ...view.parameters, modules: mods };
            // save filter in DB view parameters
            view?.id && tyraService.updateView(view);
          }

          function expandedToggled(module, expanded) {
            const el = $(module).DashboardModule();

            if (expanded) {
              // Mark all other modules as collapsed
              el.getGrid()
                .getModules()
                .each(function () {
                  const data = getModuleData(this);

                  data.expanded = false;
                  setModuleData(this, data);
                });
            }

            // Save state
            const newData = getModuleData(el);
            newData.expanded = expanded;
            setModuleData(el, newData);

            saveModuleStates(mainGrid);
          }

          function moduleDestroyed(el) {
            let $noticeBar, controller;
            const module = getModuleData(el);

            saveModuleStates(mainGrid);

            // Only outer level modules
            if (!module.sourceId || module.type === 'Source' || module.type === 'StarwoodRMISource') {
              $noticeBar = $(
                `${'' + '<div class="undo-remove-container">'}${i18n.renderers_dashboard_tileremoved} ` +
                  `<span class="clickable bring-it-back">${i18n.renderers_dashboard_bringitback}</span>&nbsp;` +
                  `<span class="clickable dismiss">${i18n.common_dismiss}</span>` +
                  `</div>`
              );

              controller = topBar.notifyInfo($noticeBar, 300, 600, 5000);

              $noticeBar.find('.bring-it-back').one('click', { module }, (e) => {
                addModules(mainGrid, [e.data.module]); // eslint-disable-line no-use-before-define
                saveModuleStates(mainGrid);

                onAddModules(moduleDeferreds, mainGrid); // eslint-disable-line no-use-before-define

                controller.hide();
              });

              $noticeBar.find('.dismiss').click(() => {
                controller.hide(100);
              });
            }
          }

          // Icon (tile) default options
          const moduleIconParams = function (params) {
            return $.extend(
              {
                loadingHtml: '<div style="width: 100%; height: 16px"></div>',
                expandedToggled: expandedToggled,
                destroyed: moduleDestroyed,
              },
              params,
              { app: 'Dashboard', tile: currentTile }
            );
          };

          // Details panel default options
          const moduleDetailsParams = function (params) {
            return $.extend(
              {
                loadingHtml: '<div style="width: 100%; height: 16px"></div>',
              },
              params,
              { app: 'Dashboard', tile: currentTile }
            );
          };

          const moduleParams = (content.moduleParams = function (module, comesFromSourceTile) {
            let sourceName = null;
            const sourceId = module.sourceId;

            if (sourceId) {
              // Get source name
              /* eslint-disable consistent-return */
              $.each(renderData.sources, (_, source) => {
                if (source.id === sourceId) {
                  sourceName = source.name;

                  return false;
                }
              });
              /* eslint-enable consistent-return */
            }

            switch (module.type) {
              case 'TrustScore': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  scoreLineOptions: {
                    title: i18n.renderers_dashboard_overallscore,
                  },
                  reviewLineOptions: {
                    title: i18n.common_new_reviews,
                  },
                  reviewPieOptions: {
                    chartArea: {
                      top: 25,
                    },
                    title: i18n.renderers_dashboard_review_distribution,
                    titleTextStyle: {
                      fontSize: 12,
                    },
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.overall_score),
                    headerKey: 'overall_score',
                    noTrustScore: i18n.common_nodata,
                    source: i18n.charts_source_name,
                    trustyou: `<span class="overall">${i18n.dashboard_overall}</span><span class="logo"></span>`,
                    trustyou_chart: i18n.dashboard_overall,
                    score: i18n.renderers_dashboard_overallscore,
                    scoreTrend: i18n.common_trend,
                    reviewTrend: i18n.common_new_reviews,
                    responseTrend: i18n.common_new_responses_short,
                    months: monthLabels,
                    otherSources: i18n.common_others,
                    poor: i18n.common_score_poor,
                    mediocre: i18n.common_score_mediocre,
                    ok: i18n.common_score_ok,
                    veryGood: i18n.common_score_very_good,
                    excellent: i18n.common_score_excellent,
                    trustScore: i18n.overall_score,
                    overall: i18n.dashboard_overall,
                    reviewDistribution: i18n.renderers_dashboard_review_distribution,
                  },
                };
              }
              case 'PerformanceIndex': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,
                  lang: session.properties.locale,
                  scale: session.properties.scale,
                  categoryReviewsPopup: moduleUtils.categoryReviewsPopup.bind(null, {
                    clusterId: clusterId,
                    from: from,
                    to: to,
                    reviewsPerPage: reviewsPerPage,
                    clusterName: clusterName,
                    sourceList: renderData.sources,
                  }),
                  onSaveFilter: function (newFilter) {
                    view.parameters.filter = newFilter;
                    // save filter in DB view parameters
                    view?.id && tyraService.updateView(view);
                  },
                  getViewParameter: function (key) {
                    return parameters[key];
                  },
                  performanceChartOptions: {},
                  reviewChartOptions: {},
                  reviewChartEvents: {
                    select() {
                      const selection = this.chart.getSelection()[0],
                        row = selection.row,
                        column = selection.column;
                      let slice, count;

                      // Only handle review column clicks
                      if (typeof row === 'number' && (column === 1 || column === 2 || column === 3)) {
                        slice = this.data.getRowProperty(row, 'slice');
                        count = this.data.getValue(row, column);

                        $.fancybox({
                          content: '<div class="review-list-popup"></div>',
                          padding: 0,
                          scrolling: 'no',
                          onComplete: function () {
                            const $fancyboxContent = $('#fancybox-content'),
                              container = $fancyboxContent.find('.review-list-popup');
                            let header;

                            // This is a hack to override predefined fancybox styles
                            $fancyboxContent.css('overflow', 'visible');
                            container.parent().css('overflow', 'visible');

                            // Show review list
                            const reviewReqData = {
                              cluster_list: JSON.stringify([clusterId]),
                              from: slice.from,
                              to: slice.to,
                              page_size: reviewsPerPage,
                            };

                            // Add source id if needed
                            if (module.sourceId) {
                              reviewReqData.source_list = JSON.stringify([module.sourceId]);
                            }

                            // Get positive or negative reviews
                            // TODO the thresholds may change in the future
                            if (column === 1) {
                              // Positive
                              reviewReqData.score_from = defaultThresholds.positive + 1; // hack so that > 60;
                              header = `${i18n.charts_positive_reviews} (${slice.label})`;
                            } else {
                              // Negative = up until the end of neutral.
                              reviewReqData.score_to = defaultThresholds.positive;
                              reviewReqData.score_from = 0;
                              header = `${i18n.charts_negative_reviews} (${slice.label})`;
                            }

                            moduleUtils.renderReviewList(container, reviewReqData, count, {
                              rendered: function () {
                                $.fancybox.resize();
                                $.fancybox.center();
                              },
                              labels: {
                                header,
                              },
                              clusterName: clusterName,
                              sourceList: renderData.sources,
                            });
                          },
                        });
                      }

                      // Clear selection
                      this.chart.setSelection(null);
                    },
                  },
                  labels: {
                    header: !comesFromSourceTile
                      ? moduleUtils.moduleHeader(i18n.dashboard_lbl_impact_score || 'Impact score')
                      : moduleUtils.moduleHeader(i18n.dashboard_performance_header),
                    headerKey: !comesFromSourceTile ? 'dashboard_lbl_impact_score' : 'dashboard_performance_header',
                    // Used only when exporting modules
                    detailsHeader: sourceName
                      ? moduleUtils.moduleHeader(`${i18n.dashboard_performance_header}&nbsp;-&nbsp;${sourceName}`)
                      : null,
                    upTrend: i18n.common_trend,
                    downTrend: i18n.common_trend,
                    noTrend: i18n.common_trend_no,
                    months: monthLabels,
                    performanceIndex: i18n.dashboard_performance_header,
                    performanceAvg: i18n.common_average,
                    performanceGoalTargetScore: dashboardI18n.dashboard_performance_goal_target_column_header,
                    performanceGoalCurrentScore: dashboardI18n.dashboard_performance_goal_current_column_header,
                    positiveReviews: i18n.charts_positive_reviews,
                    negativeReviews: i18n.charts_negative_reviews,
                    performance: i18n.dashboard_performance_header,
                    newReviews: i18n.common_new_reviews,
                    notAvailable: i18n.common_nodata,
                    performanceChartTitle: i18n.charts_performance_title,
                    reviewsChartTitle: i18n.common_new_reviews,
                    yourPerformance: dashboardI18n.performance_your,
                    negativeImpactTitle: dashboardI18n.impact_negative,
                    negativeImpactMessage: dashboardI18n.impact_negative_message,
                    positiveImpactTitle: dashboardI18n.impact_positive,
                    positiveImpactMessage: dashboardI18n.impact_positive_message,
                    category: i18n.category_word,
                    complaints: i18n.common_complaints,
                    compliments: i18n.common_compliments,
                    details: i18n.common_details,
                    competitors: i18n.portfolio_competitors,
                    showNumber: dashboardI18n.impact_show_number,
                    viewPraises: dashboardI18n.impact_view_praises_number,
                    showMentions: dashboardI18n.impact_view_mentions,
                    competitorBetter: dashboardI18n.impact_competitor_better,
                    competitorWorse: dashboardI18n.impact_competitor_worse,
                    scoreImpact: dashboardI18n.impact_score_effect,
                    fixToImprove: dashboardI18n.impact_fix_to_improve,
                    impactScoresWelcomeMessageTitle: dashboardI18n.impact_scores_welcome_message_title,
                    impactScoresWelcomeMessageContent: dashboardI18n.impact_scores_welcome_message_content,
                  },
                };
              }
              case 'Reviews': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,
                  lang: session.properties.locale,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_reviews_header),
                    headerKey: 'dashboard_reviews_header',

                    // Used only when exporting modules
                    detailsHeader: sourceName
                      ? moduleUtils.moduleHeader(`${i18n.dashboard_reviews_header}&nbsp;-&nbsp;${sourceName}`)
                      : null,
                    positive: i18n.charts_positive,
                    negative: i18n.charts_negative,
                    noScore: i18n.charts_no_score,
                    total: i18n.common_total,
                    allLabel: i18n.common_reviews_to_check,
                    negativeLabel: i18n.common_reviews_negative,
                    respondableLabel: i18n.common_reviews_to_respond,
                    categoriesTitle: i18n.common_category_details,
                    praiseLabel: i18n.common_praises_about,
                    praisesTotalLabel: i18n.common_praises,
                    complaintLabel: i18n.common_complaints_about,
                    complaintsTotalLabel: i18n.common_complaints,
                    reviews: i18n.dashboard_reviews_header,
                    category: i18n.renderers_dashboard_category,
                  },
                };
              }
              case 'Languages': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(dashboardI18n.languages),
                    headerKey: 'dashboard_languages_header',

                    // Used only when exporting modules
                    detailsHeader: moduleUtils.moduleHeader(dashboardI18n.languages),
                    coverage: i18n.response_rate,
                    star: `{0} ${i18n.common_star}`,
                    stars: `{0} ${i18n.common_stars}`,
                    total: i18n.common_total,
                    performance: i18n.performance,
                  },
                };
              }
              case 'Responses': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_responses_header),
                    headerKey: 'dashboard_responses_header',

                    // Used only when exporting modules
                    detailsHeader: sourceName
                      ? moduleUtils.moduleHeader(`${i18n.dashboard_responses_header}&nbsp;-&nbsp;${sourceName}`)
                      : null,
                    goodCoverage: i18n.good_response_rate,
                    mediumCoverage: i18n.medium_response_rate,
                    badCoverage: i18n.bad_response_rate,
                    noReviews: i18n.common_no_reviews_to_respond,
                    months: monthLabels,
                    reviews: i18n.common_reviews,
                    reviewsFootnote: i18n.dashboard_respondable_reviews_footnote,
                    notRated: surveyI18n.not_rated,
                    responses: i18n.responses,
                    coverage: i18n.response_rate,
                    star: `{0} ${i18n.common_star}`,
                    stars: `{0} ${i18n.common_stars}`,
                    total: i18n.common_total,
                    responsesHeader: i18n.dashboard_responses_header,
                  },
                };
              }
              case 'PopularityRank': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  from: from,
                  to: to,
                  time: time,
                  timelineChartOptions: {
                    title: i18n.dashboard_popularity_header,
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_popularity_header),
                    headerKey: 'dashboard_popularity_header',
                    source: i18n.charts_source_name,
                    rank: i18n.dashboard_popularity_header,
                    originalRank: i18n.common_ranking,
                    outOf: i18n.common_out_of,
                    trend: i18n.common_trend,
                    iconNoData: i18n.common_nodata,
                    detailsNoData: i18n.dashboard_noviewdata,
                    trustyou: `<span class="overall">${i18n.dashboard_overall}</span><span class="logo"></span>`,
                    months: monthLabels,
                    popularity: i18n.dashboard_popularity_header,
                  },
                };
              }
              case 'Goals': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  userId: session.user.id,
                  userName: session.properties.displayname,
                  scaling: session.properties.scale,
                  email: session.user.email, // TODO
                  locale: session.properties.locale,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  defaultClusterId: session.defaultCluster.id,
                  defaultClusterName: session.defaultCluster.name,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_goals_header),
                    headerKey: 'dashboard_goals_header',
                    open: i18n.common_goals_open,
                    goals: i18n.dashboard_goals_header,
                    achievedGoals: goalsI18n.achieved,
                    openGoals: goalsI18n.open,
                    name: goalsI18n.common_name,
                    createdBy: goalsI18n.column_created_by,
                    kpi: goalsI18n.kpi,
                    current: goalsI18n.common_current,
                    targeted: goalsI18n.common_targeted,
                    achieved: goalsI18n.column_achieved,
                    measuredFrom: goalsI18n.measured_from,
                    due: goalsI18n.common_due,
                    hotelgroup: goalsI18n.hotelgroup,
                    notAvailable: i18n.common_nodata,
                  },
                };
              }
              case 'Tasks': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  userId: session.user.id,
                  userName: session.properties.displayname,
                  isEnterprise: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]),
                  clusterId: clusterId,
                  email: session.user.email, // TODO
                  scale: session.properties.scale,
                  locale: session.properties.locale,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_tasks_header),
                    headerKey: 'dashboard_tasks_header',
                    tasks: i18n.dashboard_tasks_header,
                    open: i18n.common_tasks_open,
                    openTasks: tasksI18n.open_tasks,
                    totalTasks: tasksI18n.total_tasks,
                    tableName: tasksI18n.table_name,
                    tableReporter: tasksI18n.table_reporter,
                    tableAssignee: tasksI18n.table_assignee,
                    tableCreated: tasksI18n.table_created,
                    tableStatus: tasksI18n.table_status,
                  },
                };
              }
              case 'SurveyAlerts': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  userId: session.user.id,
                  userName: session.properties.displayname,
                  email: session.user.email, // TODO
                  scale: session.properties.scale,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  locale: {
                    language_iso: session.properties.locale,
                  },
                  isEnterprise: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]),
                  setModuleData: function (newModule, data) {
                    setModuleData(newModule, data);
                    saveModuleStates(mainGrid);
                  },
                  getModuleData: getModuleData,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_alerts_header),
                    headerKey: 'dashboard_alerts_header',
                    open: i18n.common_tasks_open,
                  },
                };
              }
              case 'Social': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  tasks: config.urls.tasks,
                  social: config.urls.social,
                  userId: session.user.id,
                  userName: session.properties.displayname,
                  i18n: i18n,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.common_social),
                    headerKey: 'common_social',
                    social: i18n.common_social,
                    name: i18n.name,
                    twitter: i18n.social_header_twitter,
                    facebook: i18n.social_header_facebook,
                    foursquare: i18n.social_header_foursquare,
                    actionsLabel: i18n.common_actions,
                    socialAccount: i18n.common_hotel,
                    newFollowers: i18n.social_twitter_new_followers,
                    newFollowings: i18n.social_twitter_new_followings,
                    newFacebookLikes: i18n.social_facebook_new_likes,
                    facebookLikes: i18n.social_facebook_likes,
                    newFacebookCheckins: i18n.social_facebook_new_checkins,
                    newFoursquareCheckins: i18n.social_foursquare_new_checkins,
                    tweets: i18n.social_twitter_mentions,
                    tips: i18n.social_foursquare_tips,
                    posts: i18n.social_facebook_posts,
                    weibos: i18n.social_weibo_weibos,
                    twitterOlderTweets: i18n.social_twitter_oldertweets,
                    twitterNewerTweets: i18n.social_twitter_newertweets,
                    twitterFollowers: i18n.social_twitter_followers,
                    facebookOlderPosts: i18n.social_facebook_olderposts,
                    facebookNewerPosts: i18n.social_facebook_newerposts,
                    foursquareOlderTips: i18n.social_foursquare_oldertips,
                    foursquareNewerTips: i18n.social_foursquare_newertips,
                    weiboFollowers: i18n.weibo_followers,
                    weiboFollowings: i18n.weibo_followings,
                    respond: i18n.common_respond,
                    retweet: i18n.social_twitter_retweet,
                    favorite: i18n.social_twitter_favorite,
                    addTask: i18n.common_add_task,
                    facebookLike: i18n.social_facebook_like,
                    facebookComment: i18n.social_facebook_comment,
                    goToSocialTab: i18n.social_go_to_social_tab,
                    openExternalLink: i18n.social_open_external_link,
                    noData: i18n.common_nodata,
                  },
                };
              }
              case 'CategoryScores': {
                return {
                  clusterId: clusterId,
                  sourceId: module.sourceId,
                  clusterName: clusterName,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,
                  reviewsPerPage: reviewsPerPage,
                  clusterUrl: config.urls.cluster,
                  setModuleData: function (newModule, data) {
                    setModuleData(newModule, data);
                    saveModuleStates(mainGrid);
                  },
                  departmentId: module.departmentId,
                  getModuleData: getModuleData,
                  labels: {
                    header: module.departmentId
                      ? `${metacategoryI18n[module.departmentId]}&nbsp;-&nbsp;${i18n.dashboard_sentiment_header}`
                      : i18n.dashboard_sentiment_header,
                    headerKey: 'dashboard_sentiment_header',

                    // Used only when exporting modules
                    detailsHeader: sourceName ? `${i18n.dashboard_sentiment_header}&nbsp;-&nbsp;${sourceName}` : null,
                  },
                };
              }
              case 'Comparison': {
                return {
                  userId: session.user.id,
                  scale: session.properties.scale,
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  from: from,
                  to: to,
                  time: time,

                  // competitors: renderData.competitors,
                  timelineChartOptions: {
                    title: i18n.onlinereport_comparison,
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(
                      !module.sourceId ? i18n.onlinereport_comparison : i18n.common_score
                    ),
                    headerKey: !module.sourceId ? 'onlinereport_comparison' : 'common_score',
                    source: i18n.charts_source_name,
                    popularity: i18n.dashboard_popularity_header,
                    competitorAvg: i18n.common_competitor_average,
                    months: monthLabels,
                    score: i18n.common_score,
                    overall_score: i18n.overall_score,
                    performance: i18n.dashboard_performance_header,
                    responses: i18n.responses,
                    reviews: i18n.reviews,
                    noCompetitors: i18n.dashboard_no_competitors,
                    helpMessage: i18n.dashboard_competitors_helpmessage,
                    compIndexLabel: i18n.common_compIndex,
                    response_rate: i18n.response_rate,
                    comparison: !module.sourceId ? i18n.onlinereport_comparison : i18n.common_score,
                    notAvailable: i18n.common_nodata,
                  },
                };
              }
              case 'StarwoodRMISource': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(sourceName, true),
                    months: monthLabels,
                    score: i18n.score,
                    notAvailable: i18n.common_nodata,
                  },
                };
              }
              case 'Source': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(sourceName, true),
                    newReviews: i18n.common_new_reviews,
                  },
                };
              }
              case 'SourceTotalScore': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  scoreChartOptions: {
                    width: 860,
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.common_total_score),
                    headerKey: 'common_total_score',

                    // Used only when exporting modules
                    detailsHeader: moduleUtils.moduleHeader(`${i18n.common_total_score}&nbsp;-&nbsp;${sourceName}`),
                    months: monthLabels,
                    score: i18n.common_score,
                    overall_score: i18n.overall_score,
                    totalScore: i18n.common_total_score,
                  },
                };
              }
              case 'SourcePopularityRank': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  sourceId: module.sourceId,
                  sourceName: sourceName,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  rankChartOptions: {
                    width: 860,
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_popularity_header),
                    headerKey: 'dashboard_popularity_header',

                    // Used only when exporting modules
                    detailsHeader: moduleUtils.moduleHeader(
                      `${i18n.dashboard_popularity_header}&nbsp;-&nbsp;${sourceName}`
                    ),
                    outOf: i18n.common_out_of,
                    notAvailable: i18n.common_nodata,
                    months: monthLabels,
                    rank: i18n.dashboard_popularity_header,
                    popularity: i18n.dashboard_popularity_header,
                  },
                };
              }
              case 'ReviewsBreakdown': {
                return {
                  userId: session.user.id,
                  widget5: config.urls.widget5,
                  lang: session.properties.locale,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  isExportable: true,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_volume_title || 'Reviews Breakdown'),
                    headerKey: 'dashboard_volume_title',
                  },
                };
              }
              case 'ResponseRate': {
                return {
                  userId: session.user.id,
                  widget5: config.urls.widget5,
                  lang: session.properties.locale,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  isExportable: true,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.help_response_rate_name || 'Response Rate'),
                    headerKey: 'help_response_rate_name',
                  },
                };
              }
              case 'PerformanceScore': {
                return {
                  userId: session.user.id,
                  widget5: config.urls.widget5,
                  lang: session.properties.locale,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  isExportable: true,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.performance_score || 'Performance Score'),
                    headerKey: 'performance_score',
                  },
                };
              }
              case 'SourceGuestProfile': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  reviews: config.urls.reviews,
                  sourceId: module.sourceId,
                  deprecated: module.sourceId === '16',
                  sourceName: sourceName,
                  clusterId: clusterId,
                  lang: session.properties.locale,
                  from: from,
                  to: to,
                  time: time,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.guest_profile),
                    headerKey: 'guest_profile',
                    detailsHeader: moduleUtils.moduleHeader(`${i18n.guest_profile}&nbsp;-&nbsp;${sourceName}`),
                    notAvailable: i18n.common_nodata,
                    guestProfile: i18n.guest_profile,
                    typeOfTrip: tyra5I18n.type_of_trip,
                    detailsNoData: i18n.dashboard_noviewdata,
                    guest_data_deprecation: i18n.guest_data_deprecation,
                  },
                };
              }
              case 'SourceRecommendationRate': {
                return {
                  widget5: config.urls.widget5,
                  clusterUrl: config.urls.cluster,
                  sourceId: module.sourceId,
                  deprecated: module.sourceId === '16',
                  sourceName: sourceName,
                  clusterId: clusterId,
                  from: from,
                  to: to,
                  time: time,
                  rankChartOptions: {
                    width: 860,
                  },
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_recommendation_header),
                    headerKey: 'dashboard_recommendation_header',

                    // Used only when exporting modules
                    detailsHeader: moduleUtils.moduleHeader(
                      `${i18n.dashboard_recommendation_header}&nbsp;-&nbsp;${sourceName}`
                    ),
                    recommendation: i18n.dashboard_recommendation_header,
                    notAvailable: i18n.common_nodata,
                    months: monthLabels,
                  },
                };
              }
              case 'Highlights': {
                return {
                  userId: session.user.id,
                  widget5: config.urls.widget5,
                  lang: session.properties.locale,
                  clusterUrl: config.urls.cluster,
                  clusterId: clusterId,
                  clusterName: clusterName,
                  from: from,
                  to: to,
                  time: time,
                  scale: session.properties.scale,
                  labels: {
                    header: moduleUtils.moduleHeader(i18n.dashboard_highlights_header),
                    headerKey: 'dashboard_highlights_header',
                    months: monthLabels,
                    compAverage: i18n.competitor_average,
                    compIndex: i18n.competitor_index,
                    performance: i18n.performance,
                    positiveReviews: i18n.charts_positive_reviews,
                    negativeReviews: i18n.charts_negative_reviews,
                    topSources: i18n.top_sources,
                    competitors: i18n.portfolio_competitors,
                    bestRated: i18n.best_rated,
                    worstRated: i18n.worst_rated,
                    source: i18n.source,
                    overallScore: i18n.overall_score,
                    overallScoreTrend: i18n.overall_score_trend,
                    performanceTrend: i18n.performance_trend,
                    newReviews: i18n.new_reviews,
                    overall: goalsI18n.all_sources,
                    performanceScore: i18n.performance_score,
                    numberOfReviews: i18n.number_of_reviews,
                    name: i18n.name,
                    responses: i18n.common_new_responses_short,
                    responseRate: i18n.response_rate,
                    category: i18n.category_word,
                    mentions: i18n.mentions,
                    noDataAvailableChart: i18n.no_data_available_chart,
                    highlightsReport: i18n.dashboard_highlights_report,
                  },
                };
              }
              case 'MessageDirection':
                return {
                  clusterId,
                  from,
                  to,
                };
            }
            return null;
          });

          function addModules(grid, modules, tileDeferreds, detailDeferreds, comesFromSourceTile) {
            const removeFromIndexes = [];

            tileDeferreds = tileDeferreds || [];
            detailDeferreds = detailDeferreds || [];

            $.each(modules, (idx, module) => {
              const tileDeferred = $.Deferred(),
                detailDeferred = $.Deferred();
              let added = true,
                srcName,
                innerGrid,
                sourceId;
              const moduleParameters = moduleParams(module, comesFromSourceTile);
              currentTile = module.type;

              let exportable = true;
              if (moduleParameters && moduleParameters.isExportable !== undefined) {
                exportable = moduleParameters.isExportable;
              }

              const onIconRendered = (isExportable, tile) => {
                if (isExportable) {
                  exportModule(tile, exportParameters(module, moduleParameters));
                }
                tileDeferred.resolve(module);
              };
              const onIconRenderedExportable = onIconRendered.bind(null, exportable);

              const onDetailsRendered = (isExportable, details) => {
                if (isExportable) {
                  exportModule(
                    details,
                    exportParameters(module, moduleParameters),
                    $(details).find('.module-details-header').first(),
                    true
                  );
                }
                detailDeferred.resolve(module);
              };
              const onDetailsRenderedExportable = onDetailsRendered.bind(null, exportable);

              const createTile = (functionName, tileModule) => {
                grid[functionName](
                  moduleParameters,
                  moduleIconParams({
                    expanded: tileModule.expanded,
                    rendered: onIconRenderedExportable,
                  }),
                  moduleDetailsParams({
                    rendered: onDetailsRenderedExportable,
                  })
                );
              };

              switch (module.type) {
                case 'TrustScore': {
                  createTile('trustScore', module);
                  break;
                }
                case 'PerformanceIndex': {
                  createTile('PerformanceIndex', module);
                  break;
                }
                case 'Reviews': {
                  grid.Reviews(
                    moduleParameters,
                    moduleIconParams({
                      expanded: module.expanded,
                      rendered: function (tile) {
                        // Mark the tile as exportable
                        exportModule(tile, exportParameters(module, moduleParameters));

                        // WANALYTIC-4366
                        $(tile).find('.has-data .all').textfill({
                          maxFontPixels: 20,
                          minFontPixels: 9,
                          explicitWidth: 60,
                          widthOnly: true,
                        });

                        tileDeferred.resolve(module);
                      },
                    }),
                    moduleDetailsParams({
                      rendered(details, renderedDeferred) {
                        const $el = $(details),
                          reviewReqData = {
                            cluster_list: JSON.stringify([clusterId]),
                            from: from,
                            page_size: reviewsPerPage,
                          },
                          reviewsDeferred = [];

                        exportModule(
                          details,
                          exportParameters(module, moduleParameters),
                          $el.find('.module-details-header').first(),
                          true
                        );

                        // Add end of time range if needed
                        if (to) {
                          reviewReqData.to = to;
                        }

                        // Add source id if needed
                        if (module.sourceId) {
                          reviewReqData.source_list = JSON.stringify([module.sourceId]);
                        }

                        $el.find('.reviews-panel').each(function () {
                          const $panel = $(this);
                          let data,
                            count = +$panel.data('count');

                          if (Number.isNaN(count)) {
                            count = Infinity;
                          }

                          if (count > 0) {
                            data = $.extend({}, reviewReqData);

                            if ($panel.hasClass('negative')) {
                              data.score_to = defaultThresholds.positive;
                              data.score_from = 0;
                            } else if ($panel.hasClass('respondable')) {
                              if (!module.sourceId) {
                                // No selected source. Query for all.
                                data.source_list = JSON.stringify(renderData.respondableSourceIds);
                              } else if (renderData.respondableSourceIds.indexOf(module.sourceId) === -1) {
                                // The current source doesn't accept responses.
                                // Skip to the next iteration.
                                return;
                              }

                              // Reviews without responses and not marked as responded
                              data.has_response = false;
                              data.respondable = true;
                              data.exclude_flag_list = JSON.stringify(['responded']);
                            }

                            reviewsDeferred.unshift($.Deferred());
                            moduleUtils.renderReviewList(
                              $panel,
                              data,
                              count,
                              {
                                clusterName: clusterName,
                                sourceList: renderData.sources,
                              },
                              reviewsDeferred[0]
                            );
                          }
                        });

                        $el.find('.categories-panel .complaint,.categories-panel .praise').toggle(
                          function () {
                            const $item = $(this),
                              count = $item.data('count');
                            let reviews, data;

                            if (count > 0) {
                              reviews = $item.next();
                              $item.addClass('expanded');

                              if (!reviews.hasClass('item-reviews')) {
                                reviews = $('<div class="item-reviews"></div>').insertAfter($item);

                                data = $.extend({}, reviewReqData);
                                data.category_list = JSON.stringify([$item.data('category_id').toString()]);
                                data.sentiment_list = JSON.stringify(
                                  $item.hasClass('complaint') ? ['neg', 'neu'] : ['pos']
                                );

                                reviewsDeferred.unshift($.Deferred());

                                // The match count is not the same as the review count
                                moduleUtils.renderReviewList(
                                  reviews,
                                  data,
                                  Infinity,
                                  {
                                    snippetMatches: {
                                      category_list: JSON.parse(data.category_list),
                                      sentiment_list: JSON.parse(data.sentiment_list),
                                    },
                                    clusterName: clusterName,
                                    sourceList: renderData.sources,
                                  },
                                  reviewsDeferred[0]
                                );
                              } else {
                                reviews.show();
                              }
                            }
                          },
                          function () {
                            $(this).removeClass('expanded').next().hide();
                          }
                        );

                        /**
                         * Used to track lazy loading of Reviews at ga
                         */
                        if (renderedDeferred && renderedDeferred.promise) {
                          $.when(...reviewsDeferred).done(() => {
                            renderedDeferred.resolve({});
                          });
                        }

                        detailDeferred.resolve(module);
                      },
                    })
                  );
                  break;
                }
                case 'Languages': {
                  createTile('Languages', module);
                  break;
                }
                case 'Responses': {
                  added = false;
                  removeFromIndexes.push(idx);
                  break;
                }
                case 'PopularityRank': {
                  createTile('PopularityRank', module);
                  break;
                }
                case 'Goals': {
                  createTile('Goals', module);
                  break;
                }
                case 'Tasks': {
                  createTile('Tasks', module);
                  break;
                }
                case 'SurveyAlerts': {
                  grid.SurveyAlerts(
                    moduleParameters,
                    moduleIconParams({
                      expanded: module.expanded,
                      rendered: onIconRenderedExportable,
                    }),
                    moduleDetailsParams({
                      rendered(details) {
                        exportModule(
                          details,
                          exportParameters(
                            module,
                            $.extend(
                              true,
                              {},
                              moduleParameters,
                              getModuleData($(details).closest('.dashboard-grid').find('.module-icon.expanded'))
                            )
                          ),
                          $(details).find('.module-details-header').first(),
                          true
                        );

                        detailDeferred.resolve(module);
                      },
                      filterUpdated(details) {
                        // Mark the tile as exportable
                        exportModule(
                          $(details).closest('.dashboard-grid').find('.module-icon.expanded'),
                          exportParameters(
                            module,
                            $.extend(
                              true,
                              {},
                              moduleParameters,
                              getModuleData($(details).closest('.dashboard-grid').find('.module-icon.expanded'))
                            )
                          ),
                          $(details).find('.module-details-header').first(),
                          true
                        );
                        exportModule(
                          $(details).closest('.module-details'),
                          exportParameters(
                            module,
                            $.extend(
                              true,
                              {},
                              moduleParameters,
                              getModuleData($(details).closest('.dashboard-grid').find('.module-icon.expanded'))
                            )
                          ),
                          $(details).find('.module-details-header').first(),
                          true
                        );
                      },
                    })
                  );
                  break;
                }
                case 'Social': {
                  // if no social permission then don't show the tile and remove it from modules
                  if (!permissionsWorker.hasPermission([PERMISSIONS.SOCIAL])) {
                    added = false;
                    removeFromIndexes.push(idx);

                    // don't add social if not authorized
                    break;
                  }

                  createTile('addSocial', module);
                  break;
                }
                case 'CategoryScores': {
                  moduleParameters.sourceId = module.sourceId;
                  moduleParameters.parentFilterUpdated = module.parentFilterUpdated;
                  moduleParameters.departmentId = module.departmentId;
                  moduleParameters.departments = config.departments;
                  moduleParameters.module = module;
                  moduleParameters.tabKey = tabKey;
                  moduleParameters.sourceList = renderData.sources;
                  grid.addCategoryScores(
                    moduleParameters,
                    moduleIconParams({
                      expanded: module.expanded,
                      rendered: function (tile) {
                        // Mark the tile as exportable
                        exportModule(
                          tile,
                          exportParameters(module, $.extend(true, {}, moduleParameters, getModuleData(tile)))
                        );
                        tileDeferred.resolve(module);
                      },
                    }),
                    moduleDetailsParams({
                      rendered(details) {
                        exportModule(
                          details,
                          exportParameters(
                            module,
                            $.extend(
                              true,
                              {},
                              moduleParameters,
                              getModuleData($(details).closest('.dashboard-grid').find('.module-icon.expanded'))
                            )
                          ),
                          $(details).find('.module-details-header').first(),
                          true
                        );
                        detailDeferred.resolve(module);
                      },
                    })
                  );
                  break;
                }
                case 'Comparison': {
                  // hide comparison tile when single-property user is switched to competitor hotel
                  if (isSwitchedToCompetitor) {
                    added = false;
                    removeFromIndexes.push(idx);
                    break;
                  }

                  createTile('Comparison', module);
                  break;
                }
                case 'Source': {
                  sourceId = module.sourceId;
                  srcName = (renderData.sources.find((s) => s.id === sourceId) || {}).name;
                  if (!srcName) {
                    /*
                     * something went wrong.
                     * prevent any unwanted tiles by breaking here
                     */
                    added = false;
                    break;
                  }

                  grid.Source(
                    moduleParameters,
                    moduleIconParams({
                      expanded: module.expanded,
                      rendered: onIconRenderedExportable,
                    }),
                    moduleDetailsParams({
                      renderer: (function () {
                        const detailModules = module.details;
                        const sourceModule = module;

                        // fail-safe not to show subtiles with different sources
                        $.each(detailModules, (i, newModule) => {
                          newModule.sourceId = sourceId;
                          newModule.parentFilterUpdated = function (details) {
                            const $parentDetails = $(details);
                            const $parentIcon = $parentDetails
                              .closest('.dashboard-grid')
                              .find('.module-icon.expanded')
                              .first();

                            const $parentIcons = $parentDetails.find(
                              '.module-icon'
                            );

                            const parentSubModuleParams = $parentIcons.map(
                              (_, el) => getModuleData($(el))
                            );

                            const updatedParentExportParams = exportParameters(
                              sourceModule,
                              $.extend(
                                true,
                                {},
                                moduleParameters,
                                getModuleData($parentIcon)
                              ),
                              parentSubModuleParams
                            );

                            exportModule(
                              $parentIcon,
                              updatedParentExportParams,
                              $(details).find('.module-details-header').first(),
                              true
                            );
                            exportModule(
                              $parentDetails,
                              updatedParentExportParams,
                              $(details).find('.module-details-header').first(),
                              true
                            );
                          };
                        });

                        return function (_, header, newContent) {
                        // Render header
                          $(header).html(moduleUtils.moduleHeader(srcName, true));

                          // Render content
                          innerGrid = $(newContent)
                            .DashboardGrid()
                            .init({
                              expandMultiple: false,
                              rearranged: function () {
                                // Save the state of the main, outer grid
                                saveModuleStates(mainGrid);
                              }
                            });
                          addModules(
                            innerGrid,
                            detailModules,
                            tileDeferreds,
                            detailDeferreds,
                            true
                          );
                          detailDeferred.resolve(module);

                          return $.Deferred().resolve();
                        };
                      }()), // prettier-ignore

                      rendered: onDetailsRenderedExportable,
                    })
                  );
                  break;
                }
                case 'StarwoodRMISource': {
                  srcName = null;
                  sourceId = module.sourceId;

                  // Get source name
                  $.each(renderData.sources, (_, source) => {
                    if (source.id === sourceId) {
                      srcName = source.name;
                    }
                  });
                  if (!srcName) {
                    /*
                     * something went wrong.
                     * prevent any unwanted tiles by breaking here
                     */
                    added = false;

                    break;
                  }

                  createTile('StarwoodRMISource', module);
                  break;
                }
                case 'SourceTotalScore': {
                  createTile('SourceTotalScore', module);
                  break;
                }
                case 'SourcePopularityRank': {
                  createTile('SourcePopularityRank', module);
                  break;
                }
                case 'SourceRecommendationRate': {
                  createTile('SourceRecommendationRate', module);
                  break;
                }
                case 'SourceGuestProfile': {
                  createTile('SourceGuestProfile', module);
                  break;
                }
                case 'Highlights': {
                  createTile('Highlights', module);
                  break;
                }
                case 'MessageDirection':
                  grid.MessageDirection(
                    moduleParameters,
                    moduleIconParams({
                      expanded: module.expanded,
                      rendered: onIconRendered.bind(null, false),
                    }),
                    moduleDetailsParams({
                      rendered: onDetailsRendered.bind(null, false),
                    })
                  );
                  break;
                case 'ReviewsBreakdown': {
                  // don't add Reviews Breakdown if not a single property user (thus a enterprise user)
                  // if (session.roles.indexOf('enterprise') !== -1) {
                  //   added = false;
                  //   removeFromIndexes.push(idx);
                  //   break;
                  // }
                  // else, add it
                  createTile('ReviewsBreakdown', module);
                  break;
                }
                case 'ResponseRate': {
                  // don't add ResponseRate if not a single property user (thus a enterprise user)
                  // if (session.roles.indexOf('enterprise') !== -1) {
                  //   added = false;
                  //   removeFromIndexes.push(idx);
                  //   break;
                  // }
                  // else, add it
                  createTile('ResponseRate', module);
                  break;
                }
                case 'PerformanceScore': {
                  // don't add PerformanceScore if not a single property user (thus a enterprise user)
                  // if (session.roles.indexOf('enterprise') !== -1) {
                  //   added = false;
                  //   removeFromIndexes.push(idx);
                  //   break;
                  // }
                  // else, add it
                  createTile('PerformanceScore', module);
                  break;
                }
                default: {
                  added = false;
                  break;
                }
              }

              // Attach module state and save deferreds
              if (added) {
                tileDeferreds.push(tileDeferred);
                detailDeferreds.push(detailDeferred);

                setModuleData(grid.getModules().last(), module);
              }
            });

            if (removeFromIndexes && removeFromIndexes.length > 0) {
              $.each(removeFromIndexes.sort(), (arrIdx, removeIdx) => {
                modules.splice(removeIdx - arrIdx, 1);
              });

              // save the new state of the grid
              saveModuleStates(mainGrid);
            }

            return {
              tileDeferreds,
              detailDeferreds,
            };
          }

          // Create grid and modules
          mainGrid = $('<div></div>')
            .appendTo(content)
            .DashboardGrid()
            .init({
              expandMultiple: false,
              rearranged: function (moduleItems) {
                saveModuleStates(mainGrid);
              },
            });

          // Read parameters.modules and create an array with the missing Inevitable Tiles/Modules
          const moduleNames = parameters.modules.map(({ type }) => type);
          const missingInevitableModuleNames = inevitableModuleNames.filter(
            (moduleName) => !moduleNames.includes(moduleName)
          );

          // If there are missing Inevitable Modules, push them in the modules/tiles array
          if (missingInevitableModuleNames.length) {
            missingInevitableModuleNames.forEach((moduleName) => {
              parameters.modules.unshift({ type: moduleName, expanded: false });
            });
          }

          // Remove old tiles (old tiles that are replaced by new inevitable tiles)
          parameters.modules = parameters.modules.filter((module) => !deprecatedTiles.includes(module.type));

          const moduleDeferreds = addModules(mainGrid, parameters.modules);

          // Save the grid state if new modules where pushed in
          if (missingInevitableModuleNames.length) {
            saveModuleStates(mainGrid);
          }

          // Allow the entire dashboard to be exported to pdf
          const exportableModules = [
            'TrustScore',
            'PerformanceIndex',
            'Reviews',
            'Responses',
            'PopularityRank',
            'Goals',
            'Tasks',
            'SurveyAlerts',
            'CategoryScores',
            'Comparison',
            'Source',
            'StarwoodRMISource',
            'SourceTotalScore',
            'SourcePopularityRank',
            'SourceRecommendationRate',
            'SourceGuestProfile',
            'Highlights',
            'Languages',
          ];

          if (permissionsWorker.hasPermission([PERMISSIONS.SOCIAL])) {
            exportableModules.push('Social');
          }

          if (permissionsWorker.hasPermission([PERMISSIONS.EXPORT])) {
            $('#dash-to-pdf').remove();
            topBar.addAction($('<li id="dash-to-pdf"></li>'));

            const $exportActions = $('#dash-to-pdf');

            const setExportToken = function (token) {
              let save = false;
              if (token) {
                view.parameters.token = token;
                save = true;
              } else if (view.parameters.token) {
                delete view.parameters.token;
                save = true;
              }

              // save filter in DB view parameters
              if (save) {
                view?.id && tyraService.updateView(view);
              }
            };

            $exportActions.Exportable().init({
              defaultTemplate: true,
              autoDownload: !$('html').hasClass('ie8'),
              token: parameters && parameters.token,

              exportPane: exportPane,

              error: function () {
                const $exportError = $('<p id="dash-export-error"></p>');

                exportErrorController = topBar.notifyError($exportError.text(i18n.dashboard_export_error), 100, 400);
                setExportToken();
              },
              stateChanged: function (state) {
                if (state === 'running') {
                  setExportToken(this.token());
                }
              },
              downloaded: function () {
                if (exportErrorController) {
                  exportErrorController.hide();
                }
                setExportToken();
              },
              getReport: function () {
                const modules = view.parameters.modules;

                const getExportModules = function (newModules) {
                  const mList = [];

                  $.each(newModules, (idx, module) => {
                    if (exportableModules.indexOf(module.type) === -1) {
                      // Continue to next module
                      return true;
                    }

                    const newExportModule = {
                      app: 'dashboard',
                      module: module.type,
                      options: $.extend(true, {}, moduleParams(module), module),
                    };
                    if (module.details) {
                      // Get inner modules
                      $.extend(true, newExportModule.options, {
                        details: getExportModules(module.details),
                      });
                    }

                    mList.push(newExportModule);
                    return true;
                  });

                  return mList;
                };

                return {
                  user_id: session.user.id,
                  cluster_name: session.selectedCluster.name,
                  time_range_label: $.ty.timedropdown.labels(filter.time).selected,
                  locale: session.properties.locale,
                  format: 'pdf',
                  name: i18n.dashboard_report_name,
                  module_list: getExportModules(modules),
                };
              },

              labels: {
                export_: i18n.dashboard_export_to_pdf,
                running: i18n.dashboard_export_running,
                download: i18n.dashboard_download_pdf,
              },
            });
          }

          onAddModules(moduleDeferreds, mainGrid);
        });
      });
    };
    render();
  }, [require, session, tyraService, hotelsService, permissionsWorker, portfolioService, ssoService, forceCounter]);

  return (
    <>
      <DashboardGlobalStyles />
      <DashboardView id='dashboard-view' className='view DASHBOARD_MODULES'>
        <PageLoading />
      </DashboardView>
    </>
  );
};

Dashboard.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Dashboard);
