/* eslint-disable object-shorthand, prefer-destructuring */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../../contexts/services/servicesContext';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import PageLoading from '../../PageLoading';
import innerSidebarTabs from '../../Sidebar/InnerSidebar/innerSidebarTabs';
import { getCreateView } from '../service';
import PortfolioView, { PorfolioGlobalStyles } from './PortfolioView';
import { getFilterData } from './service';
import { getDefaultView } from './utils';

const Portfolio = ({ require }) => {
  const session = useSelector((state) => state.app.session);

  const { permissionsWorker } = usePermissions();
  const { portfolioService, tyraService, clusterService, ssoService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get filter data
      const { clusterMappings, filterOptions } = await getFilterData({
        session,
        portfolioService,
        clusterService,
      });

      // get view from DB or set default one
      const defaultView = getDefaultView({ permissionsWorker });
      const view = await getCreateView(tyraService, defaultView);
      require(['portfolio-app', 'dashboard-agg'], () => {
        require([
          'jquery',
          'tyra-5/static/js/config',
          'ty-i18n!analytics_js_4x',
          'ty-i18n!metacategory',
          'ty-i18n!domain_category#optional',
          'ty-i18n!portfolio',
          'export-pane',
          'add-tile-button',
          'timedropdown-v2',
          'portfolio-plugins',
          'export-app',
          'dashboard-plugins',
          'review-plugins',
          // make sure portfolio config is loaded, main.js > init > require('portfolio/static/js/config') fails sometimes
          'portfolio/static/js/config',
        ], ($, config, i18n, metacategory, domainCategory, portfolioI18n, exportPane, AddTileButton) => {
          const content = $('#portfolio-view');
          content.empty();

          function saveCallback(tiles) {
            view.parameters.tiles = tiles;
            // save filter and tile in DB view parameters
            view?.id && tyraService.updateView(view);
          }

          async function selectClusterCallback(clusterId) {
            await ssoService.putUserProperty({
              userId: session.user.id,
              name: 'selected_cluster',
              value: clusterId,
              ssoToken: session.ssoToken,
            });
            window.location.hash = innerSidebarTabs.dashboard.hash;
            window.location.reload();
          }

          const urls = {
            groupURL: config.urls.group,
            portfolioURL: config.urls.portfolio,
            publicWidgetsURL: config.urls.widget5,
            ssoURL: config.urls.sso,
            clusterURL: config.urls.cluster,
            tasksURL: config.urls.tasks,
            goalsURL: config.urls.goals,
            impactURL: config.urls.impact,
          };

          let defaultFilterColumns = [];

          if (permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO_STATISTICS])) {
            defaultFilterColumns = [
              {
                type: 'performance_score',
                timeframe: 'timerange_30d',
                compIndex: false,
              },
              { type: 'score', timeframe: 'value_today', compIndex: false },
              { type: 'reviews', timeframe: 'trend_30d', compIndex: false },
              {
                type: 'response_rate',
                timeframe: 'trend_30d',
                compIndex: false,
              },
              {
                type: 'popularity',
                timeframe: 'value_today',
                compIndex: false,
              },
            ];
          } else if (permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO_MESSAGING_STATISTICS])) {
            defaultFilterColumns = [
              {
                type: 'inbound_messages',
                timeframe: 'value_today',
                compIndex: false,
              },
              {
                type: 'outbound_messages',
                timeframe: 'value_today',
                compIndex: false,
              },
              {
                type: 'inbound_outbound_ratio',
                timeframe: 'value_today',
                compIndex: false,
              },
            ];
          }

          const params = {
            // IMPROVE this is needed because the web app part of the portfolio app doesn't use RequireJS
            exportModule: function (element, actionContainer, param, alwaysShow) {
              const urlOptions = {
                options: $.extend(true, { userId: session.user.id }, urls),
              };

              const portfolioDeferred = $.Deferred();
              require(['portfolio/static/js/export/export-module'], (exportModule) => {
                portfolioDeferred.resolve(
                  exportModule(element, actionContainer, $.extend(true, urlOptions, param), exportPane, alwaysShow)
                );
              }, portfolioDeferred.reject);

              return $.when(portfolioDeferred).pipe((portfolio) => portfolio);
            },

            groupFilterOptions: filterOptions,
            defaultFilter: {
              globalTimeframe: 'trend_30d',
              // an object of the form { source_id: true, source_id: true, ... }
              sources: { '-1': true },
              // each element has the form { type: 'sentiment', subtype: 'hotel' (optional),
              // timeframe: 'last30days', compIndex: true/false }
              values: defaultFilterColumns,
            },
            userId: session.user.id,
            userName: session.user.username,
            email: session.user.email,
            scaling: session.properties.scale,
            defaultCluster: session.defaultCluster,
            regionSetId: session.properties.region_set_id,
            isEnterprise: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]),
            canEditPortfolio:
              permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]) &&
              !permissionsWorker.hasPermission([PERMISSIONS.DISABLE_PORTFOLIO_EDITING]),
            canEditComparison:
              permissionsWorker.hasPermission([PERMISSIONS.COMPARISON]) &&
              !permissionsWorker.hasPermission([PERMISSIONS.DISABLE_COMPARISON_EDITING]),
            canViewSocial: permissionsWorker.hasPermission([PERMISSIONS.SOCIAL]),
            canViewStatistics: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO_STATISTICS]),
            canViewMessagingStatistics: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO_MESSAGING_STATISTICS]),
            tiles: view.parameters.tiles,
            presets: view.parameters.presets,
            hiddenTiles: view.parameters.hiddenTiles,
            location: 'PORTFOLIO',
            locale: session.properties.locale,
            popularityDisabled: true,
            fromDate: null,
            toDate: null,
            time: 'last30days',
            clusterMappings: clusterMappings,

            // Render review lists
            renderReviewList: function (container, reqData, totalReviews, clusterName, sourceNames, extraParams) {
              const param = $.extend(
                true,
                {
                  dataprovider(page) {
                    page -= 1;

                    const reviewsReq = $.ajax({
                      url: `${config.urls.reviews}reviews`,
                      data: $.extend({}, reqData, {
                        page,
                      }),
                      cache: true,
                    });

                    const tasksReq = $.ajax({
                      url: `${config.urls.tasks}tasks/`,
                      data: {
                        association_type: 'review',
                      },
                    });

                    const assigneesReq = $.ajax({
                      url: `${config.urls.tasks}tasks/`,
                      data: {
                        dictionary: 'assignees',
                      },
                      cache: true,
                    });

                    return $.when(reviewsReq, tasksReq, assigneesReq).pipe(
                      // success
                      (reviews, tasks, assignees) => {
                        reviews = reviews[0];
                        tasks = tasks[0];
                        assignees = assignees[0];

                        if (!reviews.response.review_list || !reviews.response.review_list.length) {
                          // No reviews were found. This may happen if the total number of reviews is unknown
                          return {
                            reviews: [],
                            assignees: [],
                          };
                        } else {
                          $.each(reviews.response.review_list, (idx, review) => {
                            review.cluster_name = clusterName;
                            review.source_name = sourceNames[review.source_id];

                            $.each(tasks, (_, task) => {
                              if (review.review_id === task.association.ref_id) {
                                review.task = task;
                              }
                              return false;
                            });
                          });

                          return {
                            reviews: reviews.response.review_list,
                            assignees: assignees,
                            totalPages:
                              typeof totalReviews === 'number' && Number.isFinite(totalReviews)
                                ? Math.ceil(totalReviews / 10)
                                : undefined,
                          };
                        }
                      }
                    );
                  },
                },
                extraParams
              );

              $('<div></div>').appendTo(container).ReviewList().init(param);
            },
          };

          $.each(metacategory, (id, label) => {
            i18n[`category_${id}`] = label;
          });

          $.each(portfolioI18n, (id, label) => {
            i18n[id] = label;
          });

          $.each(domainCategory, (id, label) => {
            i18n[id] = label;
          });

          /**
             Initializes the export button and handles the tokens.
             */
          params.initExportBtn =
            permissionsWorker.hasPermission([PERMISSIONS.EXPORT]) &&
            function (exportActions) {
              let exportErrorController;

              /**
               * Saves the export token in the view parameters
               * @param token
               */
              const setExportToken = function (token) {
                let save = false;
                if (token) {
                  view.parameters.token = token;
                  save = true;
                } else if (view.parameters.token) {
                  delete view.parameters.token;
                  save = true;
                }

                // save view
                if (save) {
                  view?.id && tyraService.updateView(view);
                }
              };

              // Initialize the plugin
              exportActions.Exportable().init({
                defaultTemplate: true,
                autoDownload: !$('html').hasClass('ie8'),
                token: view.parameters.token,
                iconClass: 'excel',

                exportPane: exportPane,

                error: function () {
                  setExportToken();
                },

                stateChanged: function (state) {
                  if (state === 'running') {
                    setExportToken(this.token());
                  }
                },

                downloaded: function () {
                  if (exportErrorController) {
                    exportErrorController.hide();
                  }

                  // Remove the saved token
                  setExportToken();
                },

                /**
                 * @return {Object} the report data
                 */
                getReport: function () {
                  const overviewModuleType = 'OverviewTabContainer';

                  const overviewModule = (view.parameters.tiles || []).filter(
                    (tile) => tile.type === overviewModuleType
                  );

                  const folder = {
                    folder:
                      $.isEmptyObject(overviewModule) || !overviewModule[0].filter
                        ? null
                        : overviewModule[0].filter.folder,
                  };

                  const modules = $.map(view.parameters.tiles || [], (module) => {
                    module.filter = $.extend(true, module.filter, folder);
                    return {
                      app: 'portfolio',
                      module: module.type,
                      options: module,
                    };
                  });

                  return {
                    user_id: session.user.id,
                    locale: session.properties.locale,
                    format: 'excel',
                    name: portfolioI18n.export_file_name,
                    options: $.extend(true, { userId: session.user.id }, urls),
                    module_list: modules,
                  };
                },

                labels: {
                  export_: portfolioI18n.export_excel_generate,
                  running: portfolioI18n.export_excel_running,
                  download: portfolioI18n.export_excel_download,
                },
              });
            };

          // Don't show the feature to all the users
          if (config.excel && config.excel.indexOf(session.user.id) === -1) {
            params.initExportBtn = null;
          }

          $.Topic('portfolio/tilesLoaded').subscribe((grid) => {
            grid.find('.tile-blank').remove().DashboardModule().destroy();
            const onAddTileButton = () => content.find('.add-tile-button').trigger('click');
            const ghostTile = new AddTileButton(onAddTileButton);
            grid.addModule(ghostTile.iconParams, ghostTile.detailsParams);
            grid.rearrange();
          });

          // See portfolio app documentation
          return content.Portfolio(params, urls, i18n, saveCallback, selectClusterCallback);
        });
      });
    };
    render();
  }, [require, session, tyraService, permissionsWorker, portfolioService, clusterService, ssoService]);

  return (
    <>
      <PorfolioGlobalStyles />
      <PortfolioView id='portfolio-view' className='view PORTFOLIO_MODULES'>
        <PageLoading />
      </PortfolioView>
    </>
  );
};

Portfolio.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Portfolio);
