import { styled, Box } from '@trustyou/ty-brew/mui';

const SettingsView = styled(Box)({
  margin: 0,
  padding: '20px 20px 0 20px',
  width: '100%',
  '.modules-container': {
    display: 'flex',
    width: '100%',

    '.modules-menu': {
      flex: '0 0 280px',
      marginRight: '20px',
    },

    '.modules-content': {
      flex: 1,
    },
    '.modules-content > div': {
      minWidth: '690px',
      maxWidth: '965px',
    },
    '.modules-content #directResponses, .modules-content #pmsIntegrations': {
      maxWidth: '100%',
    },
  },
  '.password-rules': {
    listStyle: 'none',
    padding: 0,

    li: {
      display: 'flex',
      alignContent: 'middle',
      fontSize: '1.0em',

      '.ty-icon': {
        color: '#ddd',
        fontSize: '1.2em',
        lineHeight: '1.2em',
        marginRight: '5px',
      },
    },
  },

  textarea: {
    resize: 'vertical',
  },

  '.basic-section': {
    label: {
      display: 'block',
      paddingBottom: '5px',
    },

    'input, select': {
      padding: '3px',
      width: '100%',
    },

    '.card-footer .basic_container': {
      width: 'auto',
      margin: '6px 0 3px 10px',

      label: {
        marginLeft: 0,
      },
    },
  },

  'input, textarea, select': {
    borderRadius: '4px',
    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
    fontSize: '13px',
    border: '1px solid #ddd',
    padding: '8px 10px',
    background: '#fff',
    color: '#333',

    '&:focus': {
      borderColor: '#c4c4c4',
      outline: 'none',
    },

    '&:disabled': {
      color: '#999',
      borderColor: '#eaeaea',
    },

    '&.has-error': {
      color: '#ef5350',
      borderColor: '#ef5350',
    },
  },

  'input[type="checkbox"]': {
    boxShadow: 'none',
    border: 'none',
    background: 'transparent',
    padding: 0,
    width: '15px',
  },

  '.highlight-error': {
    borderColor: 'rgb(216, 42, 42)',
    boxShadow: '0px 0px 1px 1px rgb(216, 42, 42)',
  },

  '.section-error': {
    color: 'rgb(216, 42, 42)',
  },

  '.highlight-correct': {
    borderColor: 'rgb(3, 138, 73)',
    boxShadow: '0px 0px 1px 1px rgb(6, 162, 87)',
  },

  '.highlight-correct:focus, .highlight-error:focus': {
    outline: 'none',
  },

  '.tabbed_container, .basic_container': {
    width: '92%',
    float: 'left',
    marginBottom: '10px',

    label: {
      float: 'left',
      margin: 0,
    },

    'input[type="checkbox"]': {
      float: 'left',
      margin: '3px 5px 0 0',
    },
  },

  '.tabbed_container': {
    paddingLeft: '30px',
  },

  '.social_keywords': {
    width: '100%',
    float: 'left',
    marginTop: '20px',
  },

  '.tooltip': {
    backgroundColor: '#fff',
    border: '1px solid #D3E1F9',
    display: 'none',
    fontSize: '13px',
    fontWeight: 'normal',
    padding: '6px',
    position: 'absolute',
    textAlign: 'left',
    width: '145px',
    zIndex: '9999',
    textIndent: '0px',
    borderRadius: '4px',
  },

  '#errorSection': {
    padding: '1% 1% 3% 1%',
    display: 'none',
  },

  '.selection .groups': {
    height: '250px',
  },

  '.groups, .clusters, .sections, .keywords, .social-clusters': {
    '.content': {
      borderRadius: '4px',
      width: '92%',
      overflowY: 'auto',
      height: '175px',
      border: '1px solid #ddd',
    },

    'h3 + p': {
      marginBottom: '10px',
      lineHeight: '14px',
    },
  },

  '#keyword_list, #social_keyword_list': {
    width: '96%',
    height: '144px',
  },

  '#user-signature': {
    width: '100%',
    resize: 'vertical',
  },

  '.ty-list input.search': {
    width: '97%',
    borderColor: '#ddd',
    backgroundPosition: '98% 8px',
    padding: '0 25px 0 5px',
  },

  '.full-review-box': {
    float: 'left',
    width: '100%',
  },

  '.detailsLabel:': {
    fontStyle: 'italic',
    paddingLeft: '10px',
    width: '25%',
    float: 'left',
    textAlign: 'right',
  },

  '.ty-list .list-container li': {
    border: 'none',
    float: 'left',
    width: '100%',
    background: '#fff',
    color: '#505050',

    '& + li': {
      borderTop: '1px dashed #e5e5e5',
    },

    '&:hover': {
      color: '#333',
      background: '#fafafa',
    },

    '&.selected': {
      color: '#3498db',
      background: '#eaf4fb',

      '&:hover': {
        background: '#ddeef9',
      },
    },
  },

  '.item-name': {
    float: 'left',
    width: '69%',
  },
});

export default SettingsView;
