// PRIMARY
export const primaryTabs = {
  hotels: {
    id: 'hotels',
    icon: 'bed',
    labelId: 'hotels'
  },
  restaurants: {
    id: 'restaurants',
    icon: 'food',
    labelId: 'restaurants'
  },
  upgrade: {
    id: 'upgrade',
    hash: '#/marketing_upgrade',
    route: '/marketing_upgrade',
    icon: 'rocket',
    labelId: 'upsell'
  },
};

// HELP
export const helpTabsList = [{
  id: 'helpManual',
  hash: '#/help-manual',
  icon: 'book',
  labelId: 'help_manual',
}, {
  id: 'supportChat',
  hash: '#/support-chat',
  icon: 'comment',
  labelId: 'chat_navbar_start_chat',
}, {
  id: 'customerService',
  hash: '#/customer-service',
  icon: 'email',
  labelId: 'footer_customer_service',
}, {
  id: 'onlineTraining',
  hash: '#/online-training',
  icon: 'graduation-cap',
  labelId: 'footer_online_trainings',
}, {
  id: 'featureTour',
  hash: '#/feature-tour',
  icon: 'directions',
  labelId: 'access_feature_tour',
}];
export const helpTabs = helpTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

// SETTINGS
export const settingsTabsList = [{
  id: 'postStay',
  hash: '#/settings',
  route: '/settings',
  icon: 'settings',
  labelId: 'post_stay',
}, {
  id: 'live',
  hash: '#/live-settings',
  icon: 'settings',
  labelId: 'module_live',
}];
export const settingsTabs = settingsTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const settingsSections = {
  id: 'main-settings',
  labelId: 'settings',
  tabsList: settingsTabsList
};

// ALL TABS
const minibarTabs = {
  ...primaryTabs,
  ...helpTabs,
  ...settingsTabs
};
export default minibarTabs;
