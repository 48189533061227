import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box, Link, List, ListItem } from '@trustyou/ty-brew/mui';

import config from '../../../config';
import { THEMES } from '../constants';

const CustomListItem = ({ link, text }) => (
  <ListItem
    sx={{
      display: 'inline',
      color: 'text.secondary',
      padding: 0,
      '&:before': {
        content: '"\u2022"',
        margin: '0 7px',
        display: 'inline',
        color: 'text.secondary',
      },
      '&:first-child:before': {
        display: 'none',
      },
    }}
  >
    <Link
      href={link}
      underline='hover'
      target='_blank'
      variant='body2'
      sx={{
        transition: 'color 100ms ease-in-out',
        textDecoration: 'none',
        cursor: 'pointer',
        position: 'relative',
        color: 'text.secondary',
      }}
    >
      {text}
    </Link>
  </ListItem>
);

const Footer = () => {
  const { themeId } = useParams();

  return (
    <Box
      sx={{
        textAlign: 'center',
        typography: 'body2',
      }}
    >
      <List
        sx={{
          display: 'inline',
          textAlign: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
        {themeId === THEMES.VOG.id && (
          <CustomListItem
            link={'https://trustyou-sfawk.force.com/customer/s/login-accor'}
            text={<FormattedMessage id='registration_status' defaultMessage='Registration Status' />}
          />
        )}
        <CustomListItem
          link={'http://trustyou.com/'}
          text={<FormattedMessage id='homepage' defaultMessage='Homepage' />}
        />
        <CustomListItem
          link={
            themeId === THEMES.VOG.id
              ? `${config.urls.hotelsHost}/tyra-5/static/home.html#/trainings/vog`
              : 'https://trustyou.com/events/calendar'
          }
          text={<FormattedMessage id='online_trainings' defaultMessage='Online trainings' />}
        />
        <CustomListItem
          link={'https://trustyou.com/imprint'}
          text={<FormattedMessage id='imprint' defaultMessage='Imprint' />}
        />
        <CustomListItem
          link={'https://trustyou.com/downloads/privacy-policy.pdf'}
          text={<FormattedMessage id='privacy_policy' defaultMessage='Privacy Policy' />}
        />
      </List>
    </Box>
  );
};

CustomListItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.object,
};

export default Footer;
