import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, theme } from '@trustyou/ty-brew/mui';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PermissionsProvider } from './permissions/permissionsContext';
import { IdpServiceProvider } from './services/idpContext';
import { ServicesProvider } from './services/servicesContext';

const queryClient = new QueryClient();

const TYIntlProvider = ({ children }) => {
  const labels = useSelector((state) => state.app.labels);
  const session = useSelector((state) => state.app.session);
  return (
    <IntlProvider locale={session.properties.locale || 'en'} onError={() => null} messages={labels}>
      {children}
    </IntlProvider>
  );
};

const ContextsProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <TYIntlProvider>
      <IdpServiceProvider>
        <QueryClientProvider client={queryClient}>
          <ServicesProvider>
            <PermissionsProvider>{children}</PermissionsProvider>
          </ServicesProvider>
        </QueryClientProvider>
      </IdpServiceProvider>
    </TYIntlProvider>
  </ThemeProvider>
);

export default ContextsProvider;
