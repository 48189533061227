import React, { useState } from 'react';
import { Box } from '@trustyou/ty-brew/mui';
import { useSelector } from 'react-redux';

import PageLoading from '../../PageLoading';

const SurveysAdmin = () => {
  const session = useSelector((state) => state.app.session);
  const [isFrameLoading, setIsFrameLoading] = useState(true);

  return (
    <Box
      id='surveys-admin-view'
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {isFrameLoading && <PageLoading />}
      <iframe
        id='surveys-admin-iframe'
        src={`/survey-admin/site/main_admin?sso_token=${session.ssoToken}`}
        width='100%'
        height='100%'
        style={{ border: 'none', ...(isFrameLoading && { display: 'none' }) }}
        onLoad={() => {
          setIsFrameLoading(false);
          document
            .getElementById('surveys-admin-iframe')
            .contentWindow.document.getElementsByClassName('top-wrapper')[0]
            .remove();
        }}
      />
    </Box>
  );
};

export default React.memo(SurveysAdmin);
