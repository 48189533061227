import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { faCircleCheck, faCircleXmark, faEye, faEyeSlash, faKey } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { green, red } from '@trustyou/ty-brew/colors';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { Alert, Box, CircularProgress, InputAdornment, Stack, TextField, Typography } from '@trustyou/ty-brew/mui';

import VERTICALS from '../../../models/Verticals';
import SecondaryButton from '../Buttons/SecondaryButton';
import SubmitButton from '../Buttons/SubmitButton';
import FormContainer from '../FormContainer';
import MainContainer from '../MainContainer';
import { changePassword } from '../service';

const NewPasswordForm = ({ vertical }) => {
  const { themeId } = useParams();
  const navigate = useNavigate();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  // Errors
  const [minCharIconColor, setMinCharIconColor] = useState(red[700]);
  const [oneDigitIconColor, setOneDigitIconColor] = useState(red[700]);
  const [oneLetterIconColor, setOneLetterIconColor] = useState(red[700]);
  const [matchingPassIconColor, setMatchingPassIconColor] = useState(red[700]);

  const {
    register,
    formState: { isDirty, isValid },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const subscribeChangesOfPassword = watch('password');
  const subscribeChangesOfConfirmPassword = watch('confirmPassword');

  const {
    isError,
    isLoading,
    mutate: submitNewPassword,
  } = useMutation(changePassword, {
    onSuccess: () => {
      setIsPasswordChanged(true);
    },
  });

  useEffect(() => {
    if (!!watch('password') || !!watch('confirmPassword')) {
      // Check length at least 8 char long
      const isPassHasEightChar = watch('password').length > 7;
      setMinCharIconColor(isPassHasEightChar ? green[800] : red[700]);
      // Check if contains one digit
      const isPassHasOneDigit = /\d/.test(watch('password'));
      setOneDigitIconColor(isPassHasOneDigit ? green[800] : red[700]);
      // Checking if contains one letter
      const isPassHasOneLetter = /[a-zA-Z]/.test(watch('password'));
      setOneLetterIconColor(isPassHasOneLetter ? green[800] : red[700]);
      // Checking if passwords matching
      const isPasswordsMatching = watch('password') === watch('confirmPassword');
      setMatchingPassIconColor(isPasswordsMatching ? green[800] : red[700]);
    } else {
      setMinCharIconColor(red[700]);
      setOneDigitIconColor(red[700]);
      setOneLetterIconColor(red[700]);
    }
  }, [watch, subscribeChangesOfPassword, subscribeChangesOfConfirmPassword]);

  const submitNewPasswordCredentials = async (values) => {
    // Getting query params
    const queryParams = window.location.hash.split('?')[1];
    const params = new URLSearchParams(queryParams);
    const passwordToken = params.get('password_token');
    const username = params.get('username');

    submitNewPassword({
      vertical,
      username,
      password: values.password,
      confirmPassword: values.confirmPassword,
      passwordToken,
    });
  };

  const navigateToLogin = () => {
    const themeSubpath = themeId ? `/${themeId}` : '';
    navigate(`/auth/${vertical}/login${themeSubpath}`);
  };

  const ErrorSubBox = ({ text, color }) => (
    <Stack direction='row' alignItems='center'>
      <Icon icon={color === red[700] ? faCircleXmark : faCircleCheck} color={color} size='sm' />
      <Typography variant='body2' color='text.hint' sx={{ marginLeft: 0.8 }}>
        {text}
      </Typography>
    </Stack>
  );
  ErrorSubBox.propTypes = {
    text: PropTypes.node,
    color: PropTypes.string,
  };

  const ErrorsList = () => (
    <Box
      sx={{
        width: '350px',
        marginTop: 3,
      }}
    >
      <ErrorSubBox
        text={<FormattedMessage id='eight_char_long' defaultMessage='Must be at least 8 characters long' />}
        color={minCharIconColor}
      />
      <ErrorSubBox
        text={<FormattedMessage id='one_digit' defaultMessage='Must contain at least one digit' />}
        color={oneDigitIconColor}
      />
      <ErrorSubBox
        text={<FormattedMessage id='one_letter' defaultMessage='Must contain at least one letter' />}
        color={oneLetterIconColor}
      />
      <ErrorSubBox
        text={<FormattedMessage id='confirm_retype' defaultMessage='Must be confirmed by retyping' />}
        color={matchingPassIconColor}
      />
    </Box>
  );

  const PasswordChangedSuccessMessage = () => (
    <Stack spacing={2.5}>
      <Alert severity='success'>
        <Typography variant='body2'>
          <FormattedMessage id='pass_reset_success' defaultMessage='Your new password has been set!' />
        </Typography>
      </Alert>
      <SubmitButton onClick={navigateToLogin}>
        <FormattedMessage id='login' defaultMessage='Login' />
      </SubmitButton>
    </Stack>
  );

  return (
    <MainContainer>
      <Typography variant='h6' sx={{ marginBlock: 3 }}>
        <FormattedMessage id='new_password' defaultMessage='Set new password' />
      </Typography>
      <FormContainer isError={isError}>
        {isPasswordChanged ? (
          <PasswordChangedSuccessMessage />
        ) : (
          <Stack spacing={2.5}>
            {isError && (
              <Alert severity='error'>
                <Typography variant='body2'>
                  <FormattedMessage
                    id='token_expired'
                    defaultMessage='The Password changing time span has expired. Please request a new link to change your password'
                  />
                </Typography>
              </Alert>
            )}
            <TextField
              id='password'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='password'
              variant='standard'
              fullWidth
              error={isError}
              {...register('password', {
                required: true,
                minLength: 8,
                pattern: /\d|[a-zA-Z]/,
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                    <Icon icon={faKey} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5, cursor: 'pointer' }}>
                    {!!watch('password') && (
                      <Icon
                        icon={isPasswordVisible ? faEyeSlash : faEye}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id='newPassword'
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              placeholder='confirm new password'
              variant='standard'
              fullWidth
              error={isError}
              {...register('confirmPassword', {
                required: true,
                validate: (value) => watch('password') === value || '',
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                    <Icon icon={faKey} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5, cursor: 'pointer' }}>
                    {!!watch('confirmPassword') && (
                      <Icon
                        icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <ErrorsList />
            <Stack alignItems='center' spacing={3}>
              <SubmitButton
                disabled={!isDirty || !isValid || isLoading}
                onClick={handleSubmit(submitNewPasswordCredentials)}
              >
                {isLoading ? <CircularProgress size={28} /> : <FormattedMessage id='save' defaultMessage='Save' />}
              </SubmitButton>
              <SecondaryButton onClick={navigateToLogin}>
                <FormattedMessage id='sign_in' defaultMessage='Sign in' />
              </SecondaryButton>
            </Stack>
          </Stack>
        )}
      </FormContainer>
    </MainContainer>
  );
};

NewPasswordForm.propTypes = {
  vertical: PropTypes.oneOf([VERTICALS.HOTELS, VERTICALS.RESTAURANTS]),
};

export default NewPasswordForm;
