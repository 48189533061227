import TabsPermissionsMap from './PermissionsMap';
import PermissionsWorker from './PermissionsWorker';
import SessionFilter from './SessionFilter';

export const TAB_STATUS = {
  GRANTED: 'granted',
  HIDDEN: 'hidden',
};

export default class TabsPermissionsManager {
  constructor(session, vertical) {
    this.permissionsWorker = new PermissionsWorker(session);
    this.vertical = vertical;
    this.tabsPermissionsMap = TabsPermissionsMap;
    this.sessionFilter = new SessionFilter(session);
  }

  isHidden(id) {
    const hiddenByVertical = (this.tabsPermissionsMap[id]?.hiddenOnVerticals ?? []).includes(this.vertical);
    const hiddenPermissions = this.tabsPermissionsMap[id]?.hiddenPermissions ?? [];

    const hiddenByPermissions = hiddenPermissions.length
      ? this.permissionsWorker.hasPermission(hiddenPermissions) : false;
    return hiddenByVertical || hiddenByPermissions;
  }

  isGranted(id) {
    /**
     * If a permission that grants access is present then is granted OR
     * If its granted without the need of a permission we give access
    */
    const grantedWithNoPermissions = this.tabsPermissionsMap[id]?.grantedWithNoPermission;
    if (grantedWithNoPermissions) {
      return true;
    }

    const grantedPermissions = this.tabsPermissionsMap[id]?.grantedPermissions || [];
    return grantedPermissions.length ? this.permissionsWorker.hasPermission(grantedPermissions) : false;
  }

  getStatus(id) {
    if (this.isHidden(id)) {
      return TAB_STATUS.HIDDEN;
    }
    if (this.isGranted(id)) {
      return TAB_STATUS.GRANTED;
    }
    return null;
  }

  isVisible(id) {
    // show tab only if it's granted
    return this.getStatus(id) === TAB_STATUS.GRANTED;
  }
}
