import React, { useContext, createContext } from 'react';
import { useSelector } from 'react-redux';

import { useIdpService } from './idpContext';
import TyraService from '../../services/TyraService';
import I18nService from '../../services/I18nService';
import MessagingService from '../../services/MessagingService';
import ClusterService from '../../services/ClusterService';
import PortfolioService from '../../services/PortfolioService';
import HotelsService from '../../services/HotelsService';
import SsoService from '../../services/SsoService';
import config from '../../config';

const ServicesContext = createContext();

const ServicesProvider = ({ children }) => {
  const session = useSelector((state) => state.app.session);

  const idpService = useIdpService();
  const tyraService = new TyraService(config.urls.host);
  const i18nService = new I18nService(config.urls.host);
  const clusterService = new ClusterService(config.urls.host);
  const portfolioService = new PortfolioService(config.urls.host);
  const ssoService = new SsoService(config.urls.host);

  const messagingService = new MessagingService(config.urls.messagingProxy, {
    idpService,
    analyticsUrl: config.urls.host,
    linksUrl: config.urls.links,
    messagingWebsocketUrl: config.urls.messagingWebSocket,
    messagingUrl: config.urls.messaging,
    ssoToken: session.ssoToken,
  });

  const hotelsService = new HotelsService(config.urls.api);

  const value = {
    tyraService,
    i18nService,
    messagingService,
    clusterService,
    portfolioService,
    hotelsService,
    ssoService,
  };
  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
};

const useServices = () => useContext(ServicesContext);

export { ServicesProvider, useServices };
