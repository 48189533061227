import React from 'react';
import { styled, Box, GlobalStyles } from '@trustyou/ty-brew/mui';

const PortfolioView = styled(Box)({
  width: '1000px',
  margin: 0,
  padding: '20px 20px 0 20px',
});

// SUR-3221: reviews popup css (all sentiment tiles)
export const PorfolioGlobalStyles = () => (
  <GlobalStyles
    styles={{
      '.review-list-popup': {
        width: '940px',
        minHeight: '50px',
        padding: '20px',
        '.review-list-header': {
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          marginBottom: '20px',
          fontSize: '16px',
          fontWeight: '300',
        },
      },
    }}
  />
);

export default PortfolioView;
