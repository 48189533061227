import React from 'react';
import PropTypes from 'prop-types';
import { styled, lighten, darken, Box, List } from '@trustyou/ty-brew/mui';

import PrimaryItems from './PrimaryItems';
import SecondaryItems from './SecondaryItems';
import { primaryTabs, helpTabs, settingsTabs } from './minibarTabs';

const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: darken(theme.palette.secondary.main, 0.12),
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: lighten(theme.palette.secondary.main, 0.12),
  },
}));

const Minibar = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => (
  <Box
    sx={{
      width: '60px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      backgroundColor: 'secondary.main',
      paddingBottom: '20px',
    }}
  >
    <StyledList>
      <PrimaryItems clickHandlers={clickHandlers} selectedTabId={selectedTabId} />
    </StyledList>
    <StyledList>
      <SecondaryItems clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
    </StyledList>
  </Box>
);

Minibar.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  clickHandlers: PropTypes.shape({
    // primary tabs
    [primaryTabs.hotels.id]: PropTypes.func,
    [primaryTabs.restaurants.id]: PropTypes.func,
    [primaryTabs.upgrade.id]: PropTypes.func,
    // help tabs
    [helpTabs.helpManual.id]: PropTypes.func,
    [helpTabs.supportChat.id]: PropTypes.func,
    [helpTabs.customerService.id]: PropTypes.func,
    [helpTabs.onlineTraining.id]: PropTypes.func,
    [helpTabs.featureTour.id]: PropTypes.func,
    // settings tabs
    [settingsTabs.postStay.id]: PropTypes.func,
    [settingsTabs.live.id]: PropTypes.func,
  }),
};

export default Minibar;
