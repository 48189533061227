// PROFILE TABS
export const profileTabsList = [
  {
    id: 'logout',
    icon: 'sign-out',
    labelId: 'header_logout',
  },
];

export const profileTabs = profileTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

// INBOX TABS
export const inboxTabsList = [
  {
    id: 'reviews',
    hash: '#/reviews',
    route: '/reviews',
    icon: 'reviews',
    labelId: 'tab_reviews',
  },
  {
    id: 'conversations',
    hash: '#/conversations',
    icon: 'comments',
    labelId: 'tab_messages_inbox',
  },
  {
    id: 'rir',
    hash: '#/rir',
    route: '/rir',
    icon: 'reviews',
    labelId: 'tab_inbox',
    chip: 'BETA',
  },
];

export const inboxTabs = inboxTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const inboxSection = {
  id: 'inbox',
  labelId: 'bundle_inbox',
  tabsList: inboxTabsList,
};

// PROMOTIONS TABS
export const promotionsTabsList = [
  {
    id: 'widgets',
    hash: '#/widgets',
    route: '/widgets',
    icon: 'widget',
    labelId: 'tab_widgets',
    chip: 'NEW',
  },
];

export const promotionsTabs = promotionsTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const promotionsSection = {
  id: 'promotions',
  labelId: 'bundle_promotions',
  tabsList: promotionsTabsList,
};

// REPORTS TABS
export const reportsTabsList = [
  {
    id: 'dashboard',
    hash: '#/dashboard',
    route: '/dashboard',
    icon: 'gauge',
    labelId: 'tab_dashboard',
  },
  {
    id: 'advancedBi',
    hash: '#/advancedBi',
    icon: 'chart-line',
    labelId: 'tab_advanced_bi',
    route: '/advancedBi',
  },
  {
    id: 'portfolio',
    hash: '#/portfolio',
    route: '/portfolio',
    icon: 'folder-open',
    labelId: 'tab_portfolio',
  },
  {
    id: 'comparison',
    hash: '#/comparison',
    route: '/comparison',
    icon: 'compare',
    labelId: 'tab_competitors',
  },
  {
    id: 'survey',
    hash: '#/survey',
    route: '/survey',
    icon: 'survey-alt',
    labelId: 'tab_surveys',
  },
  {
    id: 'forms',
    hash: '#/forms',
    route: '/forms',
    icon: 'survey-alt',
    labelId: 'tab_forms',
    chip: 'BETA',
  },
  {
    id: 'statistics',
    hash: '#/statistics',
    icon: 'comments',
    labelId: 'tab_messages_statistics',
  },
  {
    id: 'goals',
    hash: '#/goals',
    route: '/goals',
    icon: 'target',
    labelId: 'tab_goals',
  }
];

export const reportsTabs = reportsTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const reportsSection = {
  id: 'reports',
  labelId: 'bundle_reports',
  tabsList: reportsTabsList,
};

// SETUP TABS
export const setupTabsList = [
  {
    id: 'outreaches',
    hash: '#/outreaches',
    icon: 'signal',
    labelId: 'tab_outreaches',
  },
  {
    id: 'distribution',
    hash: '#/distribution',
    icon: 'distribution',
    labelId: 'tab_distribution',
  },
  {
    id: 'integrations',
    hash: '#/integrations',
    icon: 'puzzle',
    labelId: 'tab_integrations',
  },
  {
    id: 'customers',
    hash: '#/customers',
    icon: 'group',
    labelId: 'tab_guests',
  },
  {
    id: 'templates',
    hash: '#/templates',
    route: '/templates',
    icon: 'template',
    labelId: 'tab_survey_templates',
  },
  {
    id: 'tasks',
    hash: '#/tasks',
    route: '/tasks',
    icon: 'task',
    labelId: 'tab_tasks',
  },
];

export const setupTabs = setupTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const setupSection = {
  id: 'setup',
  labelId: 'bundle_setup',
  tabsList: setupTabsList,
};

// ADMIN TABS
export const adminTabsList = [
  {
    id: 'hotelAdmin',
    hash: '#/hotel-admin',
    route: '/hotel-admin',
    icon: 'building',
    labelId: 'tab_hotel_admin',
  },
  {
    id: 'usersAdmin',
    hash: '#/users-admin',
    route: '/users-admin',
    icon: 'users',
    labelId: 'tab_users_admin',
  },
  {
    id: 'surveysAdmin',
    hash: '#/surveys-admin',
    route: '/surveys-admin',
    icon: 'survey',
    labelId: 'tab_surveys_admin',
  },
  {
    id: 'newUsersAdmin',
    hash: '#/admin/users',
    icon: 'users',
    labelId: 'tab_new_users_admin',
  },
  {
    id: 'clientOnboarding',
    hash: '#/client-onboarding',
    route: '/client-onboarding',
    icon: 'users',
    labelId: 'tab_client_onboarding',
    labelDefaultMessage: 'Client Onboarding',
  },
  {
    id: 'responseAi',
    hash: '#/response-ai',
    route: '/response-ai',
    icon: 'comment-check',
    labelId: 'tab_response_ai',
    labelDefaultMessage: 'ResponseAI',
  },
];

export const adminTabs = adminTabsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

export const adminSection = {
  id: 'admin',
  labelId: 'bundle_admin',
  tabsList: adminTabsList,
};

const redirectsList = [
  {
    id: 'widgetRedirect',
    hash: '#/widget-redirect',
    route: '/widget-redirect/:hotelId',
    icon: 'comment-check',
    labelId: 'widget_redirect',
    labelDefaultMessage: 'WidgetRedirect',
  }
];

export const redirects = redirectsList.reduce((map, tab) => {
  map[tab.id] = tab;
  return map;
}, {});

// ALL TABS
const innerSidebarTabs = {
  ...profileTabs,
  ...inboxTabs,
  ...promotionsTabs,
  ...reportsTabs,
  ...setupTabs,
  ...adminTabs,
};
export default innerSidebarTabs;
