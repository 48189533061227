import config from '../../config';
import VERTICALS from '../../models/Verticals';
import BaseService from '../../services/BaseService';

const hostUrl = {
  [VERTICALS.HOTELS]: config.urls.hotelsHost,
  [VERTICALS.RESTAURANTS]: config.urls.restaurantsHost,
};

const login = async ({ vertical, username, password }) => {
  const body = new FormData();

  body.append('username', username);
  body.append('password', password);

  const response = await BaseService.fetchOk(`${hostUrl[vertical]}/sso/api/sessions/`, {
    method: 'POST',
    body,
  });

  return response;
};

const forgotPassword = async ({ vertical, username, email }) => {
  const body = new FormData();

  body.append('username', username);
  body.append('email', email);

  const response = await BaseService.fetchOk(`${hostUrl[vertical]}/sso/api/users/passwords_tokens/`, {
    method: 'POST',
    body,
  });

  return response;
};

const changePassword = async ({ vertical, username, password, confirmPassword, passwordToken }) => {
  const body = new FormData();

  body.append('username', username);
  body.append('password_token', passwordToken);
  body.append('new_password', password);
  body.append('new_password_confirmed', confirmPassword);

  const response = await BaseService.fetchOk(`${hostUrl[vertical]}/sso/api/users/passwords/`, {
    method: 'POST',
    body,
  });

  return response;
};

const fetchEventData = async () => {
  const data = await BaseService.fetchOk(config.urls.eventUrl, {
    method: 'GET',
  });
  const firstFeed = data && data[0];
  if (firstFeed) {
    const eventUrl = firstFeed.title?.rendered;
    const imageUrl =
      firstFeed._embedded &&
      firstFeed._embedded['wp:featuredmedia'] &&
      firstFeed._embedded['wp:featuredmedia'][0] &&
      firstFeed._embedded['wp:featuredmedia'][0].source_url;
    return { eventUrl, imageUrl };
  }
  return {};
};

export { hostUrl, login, forgotPassword, changePassword, fetchEventData };
