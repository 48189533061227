/* eslint-disable no-undef */
import React, { useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import TopbarMenuItem, { TopbarMenuItemWrapper } from '../TopbarMenuItem';

const Exports = () => {
  const referenceRef = useRef(null);
  useEffect(() => {
    requirejs(['tyra-5/static/js/init-slidepane'], (slidePane) => {
      slidePane(referenceRef.current);
    });
  }, []);

  return (
    <TopbarMenuItemWrapper ref={referenceRef} id='reports_loader'>
      <TopbarMenuItem icon='export'>
        <FormattedMessage id='exports' />
      </TopbarMenuItem>
    </TopbarMenuItemWrapper>
  );
};

export default Exports;
