import { TILE_IDS, TILE_MAP } from './constants';
import { getTileDict } from './tileBuilder';

function getTileInfo(tileId) {
  let info = {};
  if (tileId in TILE_MAP) {
    info = TILE_MAP[tileId];
  } else {
    const isNotSupported = !TILE_IDS.includes(tileId);
    if (isNotSupported) {
      // eslint-disable-next-line no-console
      console.warn(`${tileId} not supported! Default values used!`);
    }
    info = {};
  }

  // If no special type is given, the id is the type
  const hasNoType = !('type' in info);
  if (hasNoType) {
    info.type = tileId;
  }

  return info;
}

function checkTilePermissions(tileDict, permissions) {
  const { generic, special } = permissions;

  if (special) {
    for (let index = 0; index < special.length; index++) {
      const { permission, tiles } = special[index];
      const tile = tileDict.type;

      if (tiles.includes(tile)) {
        return permission;
      }
    }
  }

  if (generic) {
    return generic;
  } else {
    throw new Error('A generic permision needs to be suplied!');
  }
}

export function getDefaultTiles(tileIds, permissions) {
  const tiles = [];

  tileIds.forEach((tileId) => {
    const tileInfo = getTileInfo(tileId);
    const tileDict = getTileDict(tileInfo);
    if (checkTilePermissions(tileDict, permissions)) {
      tiles.push(tileDict);
    }
  });

  return tiles;
}
