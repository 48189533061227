import React from 'react';

import { Card, CardContent } from '@trustyou/ty-brew/mui';

import OnboardingTable from './OnboardingTable';

const ClientOnboarding = () => (
  <Card sx={{ margin: 2 }}>
    <CardContent>
      <OnboardingTable />
    </CardContent>
  </Card>
);

export default ClientOnboarding;
