const getCompetitors = async ({
  portfolioService,
  session,
  isSwitchedToCompetitor
}) => {
  const competitorsClustersResponse = await portfolioService.getCompetitorsClusters(
    {
      userId: session.user.id,
      clusterIds: [
        !isSwitchedToCompetitor
          ? session.selectedCluster.id
          : session.defaultCluster.id
      ]
    }
  );
  const competitors = [];
  competitorsClustersResponse.results.forEach((element) => {
    element.competitor_list.forEach((item) => {
      if (
        item.cluster_id &&
        !competitors.includes(item.cluster_id) &&
        item.cluster_id !== session.selectedCluster.id
      ) {
        competitors.push({
          clusterId: item.cluster_id,
          clusterName: item.cluster_name
        });
      }
    });
  });
  return competitors;
};

const getSources = async ({ hotelsService }) => {
  const sourcesResponse = await hotelsService.getSources();
  const sources = [];
  const respondableSourceIds = [];
  const sourceIds = [];
  sourcesResponse.source_list.forEach((source) => {
    const sourceId = source.source_id;
    // Avoid duplicates
    if (!sourceIds.includes(sourceId)) {
      sourceIds.push(sourceId);
      sources.push({
        id: source.source_id,
        name: source.name,
        hasResponses: source.has_responses
      });
      if (source.hasResponses) {
        respondableSourceIds.push(source.source_id);
      }
    }
  });
  return { sources, respondableSourceIds };
};

export const getRenderData = async ({
  portfolioService,
  hotelsService,
  session,
  isSwitchedToCompetitor
}) => {
  try {
    const [competitors, { sources, respondableSourceIds }] = await Promise.all([
      getCompetitors({ portfolioService, session, isSwitchedToCompetitor }),
      getSources({ hotelsService })
    ]);
    return {
      sources,
      competitors,
      respondableSourceIds
    };
  } catch {
    return null;
  }
};
