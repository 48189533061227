import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { faEye, faEyeSlash, faKey, faUser } from '@trustyou/fortawesome/pro-regular-svg-icons';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { Alert, CircularProgress, InputAdornment, Stack, TextField, Typography } from '@trustyou/ty-brew/mui';

import config from '../../../config';
import VERTICALS from '../../../models/Verticals';
import SecondaryButton from '../Buttons/SecondaryButton';
import SubmitButton from '../Buttons/SubmitButton';
import FormContainer from '../FormContainer';
import { hostUrl, login } from '../service';

const LoginForm = ({ vertical }) => {
  const formEl = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { themeId } = useParams();
  const [errorMessage, setErrorMessage] = useState();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const {
    isLoading,
    isError: isLoginError,
    reset: resetLoginState,
    mutate: submitLogin,
  } = useMutation(login, {
    onError: async (err) => {
      // Sending failed login to gtm
      TagManager.dataLayer({
        dataLayer: {
          event: `${vertical}_failed_login`,
        },
      });

      const response = await err.response.json();
      const msg = response.meta?.errors?.[0]?.reason;
      reset({ username: watch('username'), password: '' });
      setErrorMessage(msg);
    },
    onSuccess: () => {
      // Sending successful login to gtm
      TagManager.dataLayer({
        dataLayer: {
          event: `${vertical}_successful_login`,
        },
      });

      reset({ username: '', password: '' });
      const navigateTo = location.state?.previousHash?.substring(1) || '/';
      navigate(navigateTo);
    },
  });

  useEffect(() => {
    // get error and username from search params in case of another vertical redirect error
    const queryParams = window.location.hash.split('?')[1];
    if (!queryParams) return;
    const params = new URLSearchParams(queryParams);
    const username = params.get('username');
    username && reset({ username, password: '' });
    const errorMsg = params.get('error_reason');
    errorMsg && setErrorMessage(errorMsg);
    // cleanup serach params after error is set
    const url = window.location.href.replace(`?${queryParams}`, '');
    const historyState = 'remove search params';
    window.history.pushState(historyState, null, url);
    window.history.replaceState(historyState, null, url);
  }, [reset]);

  const submitLoginCredentials = async (values) => {
    // clean the errors
    resetLoginState();
    setErrorMessage(null);
    // we are trying to login to another vertical (host) need to set action and callbacks(hidden inputs)
    // by setting action form will redirect instantly to another host and will set cookies
    if (vertical !== config.vertical) {
      const form = formEl.current;
      form.method = 'POST';
      form.action = `${hostUrl[vertical]}/sso/api/sessions/`;
      form.submit();
      return;
    }
    submitLogin({ vertical, username: values.username, password: values.password });
  };

  const navigateToForgotPassword = () => {
    const themeSubpath = themeId ? `/${themeId}` : '';
    navigate(`/auth/${vertical}/forgot_password${themeSubpath}`);
  };

  const isEmptyFieldError = errors.username || errors.password;
  const isError = isLoginError || !!errorMessage || isEmptyFieldError;

  const ErrorMessage = () => {
    if (isEmptyFieldError) {
      return (
        <FormattedMessage id='login_empty_field_error' defaultMessage='Please enter your username and password.' />
      );
    }
    if (errorMessage) {
      return errorMessage;
    }
    return <FormattedMessage id='error_occurred' defaultMessage='An error occurred. Try again later.' />;
  };

  return (
    <FormContainer isError={isError} ref={formEl}>
      <Stack spacing={2.5}>
        {isError && (
          <Alert severity='error'>
            <Typography variant='body2'>
              <ErrorMessage />
            </Typography>
          </Alert>
        )}
        <TextField
          id='username'
          type='text'
          placeholder='username'
          variant='standard'
          fullWidth
          error={isError}
          {...register('username', { required: true })}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                <Icon icon={faUser} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id='password'
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder='password'
          variant='standard'
          fullWidth
          error={isError}
          {...register('password', { required: true })}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                <Icon icon={faKey} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start' sx={{ marginBlock: 2.5, cursor: 'pointer' }}>
                {!!watch('password') && (
                  <Icon
                    icon={isPasswordVisible ? faEyeSlash : faEye}
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {vertical !== config.vertical && (
          <>
            <input
              type='hidden'
              value={`${hostUrl[vertical]}/tyra-5/static/home.html`}
              {...register('success_callback')}
            />
            <input
              type='hidden'
              value={`${hostUrl[vertical]}/tyra-5/static/home.html#/auth/${vertical}/login`}
              {...register('failure_callback')}
            />
            <input type='hidden' value='true' {...register('append_params')} />
          </>
        )}
        <Stack alignItems='center' spacing={3}>
          <SubmitButton
            data-gtm-class='login-submit-button'
            data-gtm-id={`login-submit-button-${vertical}`}
            disabled={isLoading}
            onClick={handleSubmit(submitLoginCredentials)}
          >
            {isLoading ? (
              <CircularProgress size={28} />
            ) : (
              <FormattedMessage id='login_submit_button_text' defaultMessage='Login' />
            )}
          </SubmitButton>
          <SecondaryButton onClick={navigateToForgotPassword}>
            <FormattedMessage id='login_secondary_button_text' defaultMessage='Forgot your password?' />
          </SecondaryButton>
        </Stack>
      </Stack>
    </FormContainer>
  );
};

LoginForm.propTypes = {
  vertical: PropTypes.oneOf([VERTICALS.HOTELS, VERTICALS.RESTAURANTS]),
};

export default LoginForm;
