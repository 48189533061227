import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import SessionFilter from '../../../../core/PermissionsManager/SessionFilter';

import UpgradeGenericContainer from './UpgradeGenericContainer';
import UpgradeAccorContainer from './UpgradeAccorContainer';
import config from '../../../../config';

const UpgradeContainer = () => {
  const session = useSelector((state) => state.app.session);

  const upgradeUrls = {
    negativeFeedbackPreventionUrl: config.upsell.negativeFeedbackPreventionUrl,
    guestSatisfactionImprovementUrl: config.upsell.guestSatisfactionImprovementUrl,
    winMoreBookingsUrl: config.upsell.winMoreBookingsUrl,
  };
  const { marketoScriptUrl } = config.upsell;
  const upgradeAccorUrls = {
    unlockLiveExperienceTutorialUrl: config.upsell.unlockLiveExperienceTutorialUrl,
    miceTutorialUrl: config.upsell.miceTutorialUrl,
    spaTutorialUrl: config.upsell.spa_tutorial_url,
    fbTutorialUrl: config.upsell.fbTutorialUrl,
    vogJourneyTutorialUrl: config.upsell.vogJourneyTutorialUrl,
    registrationUrl: config.upsell.registrationUrl,
  };

  const sessionFilter = useMemo(() => new SessionFilter(session), [session]);
  if (sessionFilter.isAccor()) {
    return <UpgradeAccorContainer {...{ upgradeAccorUrls }} />;
  }
  return <UpgradeGenericContainer {...{ upgradeUrls, marketoScriptUrl }} />;
};

export default UpgradeContainer;
