import BaseService from './BaseService';

export default class TyraService extends BaseService {
  constructor(hostUrl, { idpService = null } = {}) {
    super();
    this.hostUrl = hostUrl;
    this.baseUrl = `${this.hostUrl}/tyra-5`;
    this.idpService = idpService;
  }

  async getFlags() {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      target_type: 'marketing_message',
      type: 'read'
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/flag?${queryParams}`,
      {
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async postFlag(targetId) {
    const headers = await this.idpService?.getIdpHeaders();
    const formData = new FormData();
    formData.append(
      'flag',
      JSON.stringify({
        target_id: targetId,
        type: 'read',
        target_type: 'marketing_message'
      })
    );
    const fetchResponse = await BaseService.fetchOk(`${this.baseUrl}/flag`, {
      method: 'POST',
      body: formData,
      headers
    });
    const response = await fetchResponse.json();
    return response?.response;
  }

  async deleteFlag(flagId) {
    const headers = await this.idpService?.getIdpHeaders();
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/flag/${flagId}`,
      {
        method: 'DELETE',
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async getView(pageId) {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      page_id: pageId,
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/view/?${queryParams}`,
      {
        cache: 'no-cache',
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response?.[0];
  }

  async createView({ type, pageId, parameters, filter }) {
    const headers = await this.idpService?.getIdpHeaders();
    const formData = new FormData();
    formData.append(
      'view',
      JSON.stringify({
        type,
        index: 0,
        page_id: pageId,
        parameters: parameters && JSON.stringify(parameters),
        filter: filter && JSON.stringify(filter)
      })
    );
    const fetchResponse = await BaseService.fetchOk(`${this.baseUrl}/view/`, {
      method: 'POST',
      body: formData,
      headers
    });
    const response = await fetchResponse.json();
    return response?.response;
  }

  async updateView({ id, type, pageId, parameters, filter }) {
    const headers = await this.idpService?.getIdpHeaders();
    const formData = new FormData();
    formData.append(
      'view',
      JSON.stringify({
        type,
        index: 0,
        page_id: pageId,
        parameters: parameters && JSON.stringify(parameters),
        filter: filter && JSON.stringify(filter)
      })
    );
    const fetchResponse = await BaseService.fetchOk(`${this.baseUrl}/view/${id}`, {
      method: 'PUT',
      body: formData,
      headers
    });
    const response = await fetchResponse.json();
    return response?.response;
  }
}
