import { faComments, faFilePen, faTrophyStar, faBookmark } from '@trustyou/fortawesome/pro-regular-svg-icons';

import {
  LANG_CODES,
  CATEGORIES,
  PRODUCTS,
  MARKETING_DEFAULT_LINK,
  ACCOR_LANG_CODES,
  ACCOR_REGISTRATION_DEFAULT_LINK,
} from './const/MarketingConstants';

const DESC_PLACEHOLDER = '_upsell_desc';
const ACCOR_DESC_PLACEHOLDER = '_upsell_desc_accor';
const TUTORIAL_PLACEHOLDER = '_upsell_tutorial_desc';
const LANG_CODE_REGEX = /{lang_code}/;

const getParsedUrl = (url, userLangCode, langCodes = LANG_CODES, defaultLangCode = LANG_CODES.en) => {
  const langCode = langCodes?.[userLangCode] ?? defaultLangCode;
  return LANG_CODE_REGEX.test(url) ? url.replace(LANG_CODE_REGEX, langCode) : url;
};

// TODO: Validate with design team if FA icons in each category are the expected ones or we should switch back to old icons.
const getGenericCategories = (
  intl,
  upgradeUrls,
) => [{
  id: CATEGORIES.PREVENT_NEGATIVE_FEEDBACK.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.PREVENT_NEGATIVE_FEEDBACK.ID}`, defaultMessage: '@Prevent negative feedback' }),
  description: intl.formatMessage({ id: `${CATEGORIES.PREVENT_NEGATIVE_FEEDBACK.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Prevent negative feedback description' }),
  link: getParsedUrl(upgradeUrls?.negativeFeedbackPreventionUrl ?? MARKETING_DEFAULT_LINK, intl.locale),
  items: [{
    id: PRODUCTS.LIVE_SURVEY.ID,
    icon: faFilePen,
    title: intl.formatMessage({ id: `${PRODUCTS.LIVE_SURVEY.ID}`, defaultMessage: '@Live survey' }),
    description: intl.formatMessage({ id: `${PRODUCTS.LIVE_SURVEY.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Live survey description' }),
  }, {
    id: PRODUCTS.LIVE_MESSAGING.ID,
    icon: faComments,
    title: intl.formatMessage({ id: `${PRODUCTS.LIVE_MESSAGING.ID}`, defaultMessage: '@Live messaging' }),
    description: intl.formatMessage({ id: `${PRODUCTS.LIVE_MESSAGING.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Live messaging description' }),
  }]
},
{
  id: CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}`, defaultMessage: '@Improve guest satisfaction' }),
  description: intl.formatMessage({ id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Improve guest satisfaction description' }),
  link: getParsedUrl(upgradeUrls?.guestSatisfactionImprovementUrl ?? MARKETING_DEFAULT_LINK, intl.locale),
  items: [{
    id: PRODUCTS.POST_STAY_SURVEY.ID,
    icon: faFilePen,
    title: intl.formatMessage({ id: `${PRODUCTS.POST_STAY_SURVEY.ID}`, defaultMessage: '@Post stay survey' }),
    description: intl.formatMessage({ id: `${PRODUCTS.POST_STAY_SURVEY.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Post stay description' }),
  }, {
    id: PRODUCTS.REPUTATION_MANAGEMENT.ID,
    icon: faTrophyStar,
    title: intl.formatMessage({ id: `${PRODUCTS.REPUTATION_MANAGEMENT.ID}`, defaultMessage: '@Reputation management' }),
    description: intl.formatMessage({ id: `${PRODUCTS.REPUTATION_MANAGEMENT.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Reputation management description' }),
  }]
},
{
  id: CATEGORIES.WIN_MORE_BOOKINGS.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.WIN_MORE_BOOKINGS.ID}`, defaultMessage: '@Win more bookings' }),
  description: intl.formatMessage({ id: `${CATEGORIES.WIN_MORE_BOOKINGS.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Win more bookings description' }),
  link: getParsedUrl(upgradeUrls?.winMoreBookingsUrl ?? MARKETING_DEFAULT_LINK, intl.locale),
  items: [{
    id: PRODUCTS.REVIEW_MARKETING.ID,
    icon: faBookmark,
    title: intl.formatMessage({ id: `${PRODUCTS.REVIEW_MARKETING.ID}`, defaultMessage: '@Review marketing' }),
    description: intl.formatMessage({ id: `${PRODUCTS.REVIEW_MARKETING.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Review marketing description' }),
  }, {
    id: PRODUCTS.PUSH_TO_GOOGLE.ID,
    // FIXME: Workaround to show the first letter of the brand name, till we include brand icons in FA
    icon: 'google',
    title: intl.formatMessage({ id: `${PRODUCTS.PUSH_TO_GOOGLE.ID}`, defaultMessage: '@Push to Google' }),
    description: intl.formatMessage({ id: `${PRODUCTS.PUSH_TO_GOOGLE.ID}${DESC_PLACEHOLDER}`, defaultMessage: '@Push to Google description' }),
  }]
}];

const getAccorCategories = ({
  intl,
  registrationUrl,
  unlockLiveExperienceTutorialLink,
  miceTutorialLink,
  spaTutorialLink,
  fbTutorialLink,
  accessVOGJourneyTutorialLink
}) => [{
  id: CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}`, defaultMessage: '@Unlock live experience' }),
  description: intl.formatMessage({
    id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}${ACCOR_DESC_PLACEHOLDER}`,
    defaultMessage: '@Communicate with your guests in real-time via multiple contactless channels. Unlock Live Experience, to boost overall guest satisfaction in-house.',
  }),
  link: getParsedUrl(registrationUrl ?? ACCOR_REGISTRATION_DEFAULT_LINK, intl.locale, ACCOR_LANG_CODES),
  items: [{
    description: intl.formatMessage({
      id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}${ACCOR_DESC_PLACEHOLDER}_item_1`,
      defaultMessage: '@Give your guests the option of contactless communication, to keep them informed and at ease.'
    }),
  }, {
    description: intl.formatMessage({
      id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}${ACCOR_DESC_PLACEHOLDER}_item_2`,
      defaultMessage: '@Turn detractors into promoters by taking action on-site.'
    }),
  }, {
    description: intl.formatMessage({
      id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}${ACCOR_DESC_PLACEHOLDER}_item_3`,
      defaultMessage: '@Enjoy a no set-up fee and gain access to the full VOG journey.'
    }),
  }],
  tutorial: intl.formatMessage({
    id: `${CATEGORIES.UNLOCK_LIVE_EXPERIENCE.ID}${TUTORIAL_PLACEHOLDER}`,
    defaultMessage: '@Interested? {unlock_live_experience_tutorial_link} to access a step by step tutorial to help you register for Live Experience.'
  },
  { unlock_live_experience_tutorial_link: unlockLiveExperienceTutorialLink }),
},
{
  id: CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}`, defaultMessage: '@Improve guest satisfaction' }),
  description: intl.formatMessage({
    id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}${ACCOR_DESC_PLACEHOLDER}`,
    defaultMessage: '@Gain access to all survey types {mice_tutorial_link}, {spa_tutorial_link}, and {fb_tutorial_link}, and get a full overview of guest satisfaction for your additional hotel services.'
  }, {
    mice_tutorial_link: miceTutorialLink,
    spa_tutorial_link: spaTutorialLink,
    fb_tutorial_link: fbTutorialLink,
  }),
  link: getParsedUrl(registrationUrl ?? ACCOR_REGISTRATION_DEFAULT_LINK, intl.locale, ACCOR_LANG_CODES),
  items: [{
    description: intl.formatMessage({
      id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}${ACCOR_DESC_PLACEHOLDER}_item_1`,
      defaultMessage: '@Collect and manage guest feedback from booking sites as well as from your own Post-Stay Survey in one inbox'
    }),
  }, {
    description: intl.formatMessage({
      id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}${ACCOR_DESC_PLACEHOLDER}_item_2`,
      defaultMessage: '@Understand better how your departments are performing.'
    }),
  }],
  tutorial: intl.formatMessage({
    id: `${CATEGORIES.IMPROVE_GUEST_SATISFACTION.ID}${TUTORIAL_PLACEHOLDER}`,
    defaultMessage: '@If you are interested in accessing a step by step tutorial to guide you in the registration process of each product click on the links above.'
  }),
},
{
  id: CATEGORIES.ACCESS_VOG_JOURNEY.ID,
  title: intl.formatMessage({ id: `${CATEGORIES.ACCESS_VOG_JOURNEY.ID}`, defaultMessage: '@Access the full VOG Journey' }),
  description: intl.formatMessage({
    id: `${CATEGORIES.ACCESS_VOG_JOURNEY.ID}${ACCOR_DESC_PLACEHOLDER}`,
    defaultMessage: '@Upgrade your subscription to become a pro & unlock new features to enhance guest satisfaction.',
  }),
  link: getParsedUrl(registrationUrl ?? ACCOR_REGISTRATION_DEFAULT_LINK, intl.locale, ACCOR_LANG_CODES),
  tutorial: intl.formatMessage({
    id: `${CATEGORIES.ACCESS_VOG_JOURNEY.ID}${TUTORIAL_PLACEHOLDER}`,
    defaultMessage: '@Use this step by step {access_vog_journey_tutorial_link} for an overview of the steps needed to register.'
  }, { access_vog_journey_tutorial_link: accessVOGJourneyTutorialLink }),
}];

export {
  getGenericCategories,
  getAccorCategories,
};
