import { MESSAGING_PERMISSIONS, ONSITE_PERMISSIONS } from '../../../models/Permissions/Permissions';
import TrustYouProductAbstract from './TrustYouProductAbstract';

export default class MessagingProduct extends TrustYouProductAbstract {
  isProductEnabled() {
    const disablerPermissions = [MESSAGING_PERMISSIONS.HIDE_MESSAGING];
    const enablerPermissions = Object.values({ ...MESSAGING_PERMISSIONS, ...ONSITE_PERMISSIONS }).filter(
      p => !disablerPermissions.includes(p)
    );

    const isDisabled = this.permissionsWorker.hasPermission(disablerPermissions);
    const isEnabled = this.permissionsWorker.hasPermission(enablerPermissions);
    return isEnabled && !isDisabled;
  }

  getProductPermissions() {
    return { ...MESSAGING_PERMISSIONS, ...ONSITE_PERMISSIONS };
  }
}
