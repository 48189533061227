const ANALYTICS_PERMISSIONS = {
  REVIEWS: 'reviews',
  RIR: 'rir_reviews',
  DASHBOARD: 'dashboard',
  PORTFOLIO: 'portfolio',
  SOCIAL: 'fake_role', // A fake role name is assigned to remove permission from ui code. Valid role name is 'social'
  COMPARISON: 'comparison',
  HIDE_ANALYTICS: 'hide_module_analytics'
};

const SURVEY_PERMISSIONS = {
  SURVEY: 'survey',
  SURVEY_LITE: 'survey_lite',
  SURVEY_TEMPLATES: 'survey_templates_management',
  HIDE_SURVEY: 'hide_module_survey',
  FORMS: 'forms'
};

// Goals & Tasks permissions
const OPERATIONS_PERMISSIONS = {
  OPERATIONS: 'operations',
  HIDE_OPERATIONS: 'hide_module_operations'
};

const MARKETING_PERMISSIONS = {
  MARKETING: 'marketing',
  HIDE_MARKETING: 'hide_module_marketing'
};

export const MESSAGING_PERMISSIONS = {
  MESSAGING: 'messaging',
  MESSAGING_EMPLOYEE_USER: 'messaging_employee_user',
  MESSAGING_ADMIN_USER: 'messaging_admin_user',
  MESSAGING_MANAGER_USER: 'messaging_manager_user',
  HIDE_MESSAGING: 'hide_module_messaging'
};

// on site permissions are connected with messaging permissions
export const ONSITE_PERMISSIONS = {
  ONSITE: 'on_site',
  ONSITE_ADMIN: 'on_site_admin',
  ONSITE_MANAGER: 'on_site_manager',
  ONSITE_DISTRIBUTION: 'on_site_distribution'
};

const SETTINGS_PERMISSIONS = {
  SETTINGS: 'settings'
};

const HELP_PERMISSIONS = {
  // used to hide tabs for service users (we checked and this permission is only present in service accounts)
  MANAGE_USERS: 'manage_users'
};

const MISC_PERMISSIONS = {
  ADVANCED_BI: 'advanced_bi'
};

const PERMISSIONS = {
  ...HELP_PERMISSIONS,
  ...ANALYTICS_PERMISSIONS,
  ...SURVEY_PERMISSIONS,
  ...OPERATIONS_PERMISSIONS,
  ...MARKETING_PERMISSIONS,
  ...MESSAGING_PERMISSIONS,
  ...ONSITE_PERMISSIONS,
  ...SETTINGS_PERMISSIONS,
  ...MISC_PERMISSIONS,
  SYNCHRONIZE_SESSIONS: 'synchronize_sessions',
  DASHBOARD_TILES: 'dashboard_tiles',
  DASHBOARD_MESSAGING_DIRECTION_TILE: 'dashboard_messaging_direction_tile',
  EXPORT: 'export',
  STARS_LITE: 'stars_lite',
  STARS: 'stars',
  CONNECT_USER: 'connect_user',
  // portfolio
  DISABLE_PORTFOLIO_EDITING: 'disable_portfolio_editing',
  PORTFOLIO_TILES: 'portfolio_tiles',
  PORTFOLIO_STATISTICS: 'portfolio_statistics',
  PORTFOLIO_MESSAGING_STATISTICS: 'portfolio_messaging_statistics',
  DISABLE_COMPARISON_EDITING: 'disable_comparison_editing',
  // settings
  DISABLE_USERS_MANAGEMENT: 'disable_users_management',
  RESPONSE_AI: 'response_ai',
  ADMINISTRATOR: 'administrator'
};

export default PERMISSIONS;
