export default class SessionFilter {
  /*
   * Wrapper around session data
   */
  constructor(session) {
    this.session = session;
  }

  isAccor() {
    return this.session.account?.name?.toLowerCase() === 'accor';
  }
}
