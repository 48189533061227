import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@trustyou/ty-brew/mui';
import { StyledFontAwesomeIcon } from '@trustyou/ty-brew';
import { faSpinnerThird } from '@trustyou/fortawesome/pro-regular-svg-icons';

const PageLoading = ({ sx }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      color: 'grey.400',
      fontSize: '1rem',
      ...sx,
    }}
  >
    <StyledFontAwesomeIcon
      sx={{
        animationDuration: '1s',
      }}
      icon={faSpinnerThird}
      size='2x'
      spin
    />
  </Box>
);

PageLoading.propTypes = {
  sx: PropTypes.object,
};

export default PageLoading;
