import PERMISSIONS from '../../models/Permissions/Permissions';
import minibarTabs from '../../features/Tyra/Sidebar/Minibar/minibarTabs';
import config from '../../config';

/* eslint-disable no-undef */
/**
 * Migrated code from legacy Userlane.
 * */
export default class UserlaneWrapper {
  DEFAULT_SCRIPT_URL = 'https://cdn.userlane.com/userlane.js';

  constructor(session, tabsPermissionsManager, permissionsWorker) {
    this._session = session;
    this._permissionsWorker = permissionsWorker;
    this._tabsPermissionsManager = tabsPermissionsManager;
  }

  // //////////////////// High Level Interfaces ///////////////////////// //

  async loadUserlane() {
    if (!this.hasUserlaneEnabled()) {
      // eslint-disable-next-line no-console
      console.debug(`Userlane enabled: ${this.hasUserlaneEnabled()}`);
      return;
    }

    await this._loadScript(this.DEFAULT_SCRIPT_URL);
    this._setupUserlane();
  }

  openFeatureTour() {
    if (this._hasUserlaneGlobaly()) {
      this._openUserlane();
    } else {
      // eslint-disable-next-line no-console
      console.debug('Userlane is disabled globaly!');
    }
  }

  hasUserlaneEnabled() {
    return this._tabsPermissionsManager.isGranted(minibarTabs.featureTour.id) && (config.userlane.enabled === 'true');
  }

  // //////////////////// Low Level Implementations ///////////////////////// //

  _loadScript = (url) => new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = url;
    s.onload = resolve;
    s.onerror = reject;
    s.async = true;
    document.body.appendChild(s);
  });

  _openUserlane() {
    Userlane('openAssistant');
  }

  _setupUserlane() {
    // When we set userlane up, it will pop up by default if the user is new
    if (!this._hasUserlaneGlobaly()) {
      return;
    }

    Userlane('init', config.userlane.propertyId);
    Userlane('user', this._session.user.id);
    Userlane('lang', this._session.properties.locale);

    this._tagProducts();
    this._tagAccountName();
    this._tagBandB();
    this._tagFeelIQM();
    this._tagAbuDabi();
    this._tagTourismusverbandMecklenburgVorpommern();
    this._tagUserType();
    this._tagAdminUser();
    this._tagCountry();
    this._tagNewNavigation();
    this._tagNewInbox();
    this._tagResponseAI();
  }

  _tagProducts() {
    const userProducts = this._session.products;
    if (userProducts) {
      if (userProducts.length > 0) {
        userProducts.forEach((product) => {
          Userlane('tag', product);
        });
      }
    }
  }

  _tagAccountName() {
    if (this._session.account) {
      Userlane('tag', this._session.account.name);
    }
  }

  _tagBandB() {
    if (this._session.account && this._session.account.name.includes('B&B')) {
      Userlane('tag', 'b&b');
    }
  }

  _tagFeelIQM() {
    if (this._session.user && (this._session.user.email.includes('iqm.hr') || this._session.user.email.includes('iqmdestination.com'))) {
      Userlane('tag', 'feelIQM');
    }
  }

  _tagAbuDabi() {
    if (this._session.user && this._session.user.email.includes('dctabudhabi.ae')) {
      Userlane('tag', 'dctabudabi');
    }
  }

  _tagTourismusverbandMecklenburgVorpommern() {
    if (this._session.user && this._session.user.email.includes('auf-nach-mv.de')) {
      Userlane('tag', 'tourismusverbandMecklenburgVorpommern');
    }
  }

  _tagUserType() {
    if (this._permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO])) {
      Userlane('tag', 'enterprise');
    } else {
      Userlane('tag', 'single-property');
    }
  }

  _tagAdminUser() {
    if (this._permissionsWorker.hasPermission([PERMISSIONS.ADMINISTRATOR])) {
      Userlane('tag', 'administrator');
    }
  }

  _tagCountry() {
    if (this._session.defaultCluster) {
      Userlane('tag', this._session.defaultCluster.iso2_country);
    }
  }

  _hasUserlaneGlobaly() {
    return typeof Userlane !== 'undefined';
  }

  _tagNewNavigation() {
    Userlane('tag', 'new-navigation');
  }

  _tagNewInbox() {
    if (this._permissionsWorker.hasPermission([PERMISSIONS.RIR])) {
      Userlane('tag', 'new-inbox');
    }
  }

  _tagResponseAI() {
    if (this._permissionsWorker.hasPermission([PERMISSIONS.RESPONSE_AI])) {
      Userlane('tag', 'response-ai');
    }
  }
}
