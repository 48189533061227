import BaseService from './BaseService';

export default class I18nService extends BaseService {
  constructor(hostUrl, { idpService = null } = {}) {
    super();
    this.hostUrl = hostUrl;
    this.baseUrl = `${this.hostUrl}/i18n`;
    this.idpService = idpService;
  }

  async getBundle({ bundle, lang = 'en' }) {
    const headers = await this.idpService?.getIdpHeaders();
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/bundles/${bundle}/${lang}`,
      {
        cache: 'no-cache',
        headers
      }
    );
    const response = await fetchResponse.json();
    return response;
  }
}
