import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { faEnvelope, faUser } from '@trustyou/fortawesome/pro-regular-svg-icons';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { Alert, CircularProgress, InputAdornment, Stack, TextField, Typography } from '@trustyou/ty-brew/mui';

import VERTICALS from '../../../models/Verticals';
import SecondaryButton from '../Buttons/SecondaryButton';
import SubmitButton from '../Buttons/SubmitButton';
import FormContainer from '../FormContainer';
import MainContainer from '../MainContainer';
import { forgotPassword } from '../service';

const ForgotPassword = ({ vertical }) => {
  const navigate = useNavigate();
  const { themeId } = useParams();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    formState: { isDirty, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
    },
  });

  const {
    isError,
    isLoading,
    mutate: submitForgotPassword,
  } = useMutation(forgotPassword, {
    onSuccess: () => {
      setIsEmailSent(true);
    },
  });

  useEffect(() => {
    // Getting query params
    const queryParams = window.location.hash.split('?')[1]?.replaceAll('+', '%2b');
    const params = new URLSearchParams(queryParams);
    const username = params.get('username');
    const email = params.get('email');

    username && setValue('username', username, { shouldValidate: true, shouldDirty: true });
    email && setValue('email', email, { shouldValidate: true, shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEmailToResetPassword = async (values) => {
    submitForgotPassword({ vertical, username: values.username, email: values.email });
  };

  const navigateToLogin = () => {
    const themeSubpath = themeId ? `/${themeId}` : '';
    navigate(`/auth/${vertical}/login${themeSubpath}`);
  };

  const EmailSuccessMessage = () => (
    <Stack spacing={2.5}>
      <Alert severity='success'>
        <Typography variant='body2'>
          <FormattedMessage
            id='success_text'
            defaultMessage='An email has been sent with a link where you can reset your password!'
          />
        </Typography>
      </Alert>
      <SubmitButton onClick={navigateToLogin}>
        <FormattedMessage id='login' defaultMessage='Login' />
      </SubmitButton>
    </Stack>
  );

  return (
    <MainContainer>
      <Typography variant='h6' sx={{ marginBlock: 3 }}>
        <FormattedMessage id='reset_password' defaultMessage='Reset password' />
      </Typography>
      <FormContainer isError={isError}>
        {isEmailSent ? (
          <EmailSuccessMessage />
        ) : (
          <Stack spacing={2.5}>
            {isError && (
              <Alert severity='error'>
                <Typography variant='body2'>
                  <FormattedMessage id='no_user' defaultMessage='Could not find such a user account!' />
                </Typography>
              </Alert>
            )}
            <TextField
              id='username'
              type='text'
              placeholder='username'
              variant='standard'
              fullWidth
              error={isError}
              {...register('username', { required: true, minLength: 1 })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                    <Icon icon={faUser} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id='email'
              type='email'
              placeholder='email'
              variant='standard'
              fullWidth
              error={isError}
              {...register('email', { required: true, pattern: /\S+@\S+\.\S+/ })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
                    <Icon icon={faEnvelope} />
                  </InputAdornment>
                ),
              }}
            />
            <Stack alignItems='center' spacing={3}>
              <SubmitButton
                disabled={!isDirty || !isValid || isLoading}
                onClick={handleSubmit(sendEmailToResetPassword)}
              >
                {isLoading ? <CircularProgress size={28} /> : <FormattedMessage id='reset' defaultMessage={'Reset'} />}
              </SubmitButton>
              <SecondaryButton onClick={navigateToLogin}>
                <FormattedMessage id='sign_in' defaultMessage={'Sign in'} />
              </SecondaryButton>
            </Stack>
          </Stack>
        )}
      </FormContainer>
    </MainContainer>
  );
};

ForgotPassword.propTypes = {
  vertical: PropTypes.oneOf([VERTICALS.HOTELS, VERTICALS.RESTAURANTS]),
};

export default ForgotPassword;
