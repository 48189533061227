export default class PermissionsWorker {
  /*
   * Wrapper around the permission data structure
   */
  constructor(session) {
    this.types = {
      OR: 'or',
      AND: 'and'
    };
    this.session = session;
  }

  hasPermission(permissions, type = this.types.OR) {
    return this._check(this.session.permissions, permissions, type);
  }

  hasTrial(permissions, type = this.types.OR) {
    return this._check(this.session.trial_permissions, permissions, type);
  }

  hasExpired(permissions, type = this.types.OR) {
    return this._check(this.session.expired_permissions, permissions, type);
  }

  _check(arr, permissions, type) {
    if (type !== this.types.OR && type !== this.types.AND) {
      throw new Error(`Unknown type ${type}`);
    }
    PermissionsWorker._checkNotEmpty(permissions);
    const initialVal = type !== this.types.OR;
    return permissions.reduce(
      (acc, curr) => {
        if (type === this.types.OR) {
          return acc || arr.includes(curr);
        } else {
          return acc && arr.includes(curr);
        }
      },
      initialVal
    );
  }

  static _checkNotEmpty(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
      throw new Error('At least 1 element is needed!');
    }
  }
}
