import { useState, useEffect } from 'react';
import LOAD_STATUS from './const/LoadStatusConstants';

const getScriptById = (scriptId) => document.getElementById(scriptId);

const useLoadScript = ({ scriptUrl, scriptId, defaultScriptUrl }) => {
  const [scriptStatus, setScriptStatus] = useState(LOAD_STATUS.INITIAL);
  useEffect(() => {
    if (getScriptById(scriptId)) {
      setScriptStatus(LOAD_STATUS.COMPLETED);
      return;
    }
    if (scriptUrl || defaultScriptUrl) {
      const scriptTag = document.createElement('script');
      scriptTag.src = scriptUrl || defaultScriptUrl;
      scriptTag.id = scriptId;
      scriptTag.async = true;
      scriptTag.onload = () => setScriptStatus(LOAD_STATUS.COMPLETED);
      scriptTag.onerror = () => setScriptStatus(LOAD_STATUS.ERROR);
      document.body.appendChild(scriptTag);
    }
  }, [scriptUrl, scriptId, defaultScriptUrl]);
  return scriptStatus;
};

export default useLoadScript;
