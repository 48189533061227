import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import TopbarMenuItem, { TopbarMenuItemWrapper } from '../TopbarMenuItem';
import { usePermissions } from '../../../../../contexts/permissions/permissionsContext';
import UserlaneWrapper from '../../../../../core/Userlane/UserlaneWrapper';

// import { Badge, Drawer, alpha } from '@trustyou/ty-brew/mui';
// import config from '../../../../../config';
// import useHttpStates from '../../../../../hooks/useHttpStates';
// import NoNotification from './NoNotification';
// import NotificationError from './NotificationError';
// import NotificationList from './NotificationList';
// import NotificationLoad from './NotificationLoad';
// import { getNotifications } from './utils';
// import { useServices } from '../../../../../contexts/services/servicesContext';

const MarketingNotification = () => {
  // const { tyraService } = useServices();
  // const intl = useIntl();
  const session = useSelector((state) => state.app.session);
  const { tabsPermissionsManager, permissionsWorker } = usePermissions();

  // const [showDrawer, setToggleDrawer] = useState(false);
  // const toggleNotification = useCallback(() => setToggleDrawer((value) => !value), [setToggleDrawer]);

  const userlaneWrapper = useMemo(() => new UserlaneWrapper(session, tabsPermissionsManager, permissionsWorker), [
    session,
    tabsPermissionsManager,
    permissionsWorker,
  ]);

  // const [forceCounter, setForceCounter] = useState(0);
  // const params = useMemo(
  //   () => ({
  //     tyraService,
  //     feedUrl: `${config.urls.rss}?post_type=notification&notification_language=${intl.locale}`,
  //   }),
  //   [tyraService, intl]
  // );
  // const [initNotifications, isLoadingNotifications, isNotificationsError] = useHttpStates(
  //   getNotifications,
  //   params,
  //   null,
  //   forceCounter
  // );

  // const [notifications, setNotifications] = useState(initNotifications);
  // if (notifications === null && initNotifications !== null) {
  //   setNotifications(initNotifications);
  // }

  // const unreadCount = useMemo(() => notifications && notifications.filter((n) => !n.flagId).length, [notifications]);

  // const handleTryAgain = () => {
  //   setForceCounter((counter) => counter + 1);
  // };
  // const badge = unreadCount ? (
  //   <Badge
  //     color='error'
  //     anchorOrigin={{
  //       vertical: 'bottom',
  //       horizontal: 'right',
  //     }}
  //     badgeContent={unreadCount}
  //     sx={{ '& .MuiBadge-badge': { fontSize: '10px', height: '18px' } }}
  //   />
  // ) : null;

  return (
    <>
      <TopbarMenuItemWrapper id='marketing-notification' onClick={() => {
        userlaneWrapper._openUserlane();
      }}>
        <TopbarMenuItem icon='bell'>
          <FormattedMessage id='notifications' />
        </TopbarMenuItem>
      </TopbarMenuItemWrapper>

      {/* <Drawer
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: alpha('#EEEEEE', 0.5),
          },
        }}
        open={showDrawer}
        elevation={1}
        anchor='right'
        onClose={toggleNotification}
      >
        {isLoadingNotifications && <NotificationLoad setToggleDrawer={toggleNotification} />}
        {isNotificationsError && (
          <NotificationError setToggleDrawer={toggleNotification} tryAgainHandler={handleTryAgain} />
        )}
        {notifications && !!notifications.length && (
          <NotificationList
            setToggleDrawer={toggleNotification}
            notifications={notifications}
            setNotifications={setNotifications}
            unreadCount={unreadCount}
          />
        )}
        {notifications && !notifications.length && <NoNotification setToggleDrawer={toggleNotification} />}
      </Drawer> */}
    </>
  );
};

export default MarketingNotification;
