import config from '../../../../config';
import BaseService from '../../../../services/BaseService';
import { getCookie } from '../../../../utils';

const fetchClientOnboardingData = async ({ page = 1, size = 20 }) => {
  const response = await BaseService.fetchOk(
    `${config.urls.clientOnboardingHost}/onboarding?page=${page}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getCookie('jwt')}`,
      },
    }
  );
  const responseJson = await response.json();

  return responseJson;
};

export { fetchClientOnboardingData };
