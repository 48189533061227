import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Integrations, DirectResponses, SettingsMenu } from '@trustyou/settings-ui';

import config from '../../../../config';
import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import SettingsView from './SettingsView';

const renderComponent = function (Component, target, props = {}) {
  const root = ReactDOM.createRoot(target);
  const ComponentWithProps = <Component {...props} />;
  root.render(ComponentWithProps);
};

const Settings = ({ require }) => {
  const session = useSelector((state) => state.app.session);
  const { permissionsWorker } = usePermissions();

  useEffect(() => {
    const render = async () => {
      // tyra-5 views for goals is managed inside assignments-app
      // both required modules are implemented in tyra-5
      require([
        'jquery',
        'ty-i18n!tyra-5',
        'ty-i18n!survey-app',
        'ty-i18n!analytics_js_4x',
        'manage-users',
        'tyra-5/static/js/views/settings/basic',
        'tyra-5/static/js/views/settings/alerts',
        'tyra-5/static/js/views/settings/response_templates',
        'tyra-5/static/js/views/settings/pms_integrations',
        'tyra-5/static/js/views/settings/direct_responses',
      ], ($, i18n, i18nSurvey, i18n4x, userManage) => {
        const content = $('#settings-view');
        const modulesContent = content.find('.modules-content');

        const renderModule = (id, target, _render = null) => {
          modulesContent.find('.module').hide();
          const moduleEl = modulesContent.find(`#${id}`);
          moduleEl.show();
          if (moduleEl.is(':empty')) {
            _render && _render(moduleEl[0]);
            !_render && moduleEl[target]();
          }
        };

        const urlParams = new URLSearchParams(window.location.search);

        const configuringDirectResponseParam = 'configuring_direct_response';

        const isConfiguringDirectResponse =
          urlParams.has(configuringDirectResponseParam) && $.fn.SettingsDirectResponses.API.permitted;

        if (!isConfiguringDirectResponse) {
          // render default preselected module
          renderModule('basic', 'SettingsBasic');
        }

        const jwt = $.cookie('jwt');
        const menuProps = {
          labels: {
            general: i18nSurvey.general,
            account: i18n.basic_settings,
            responseTemplates: i18n.settings_response_templates,
            alerts: i18nSurvey.alerts,
            emailAlerts: i18n4x.settings_alerting,
            integrations: i18n.integrations,
            guestData: i18n.guest_data,
            directResponses: i18n.direct_responses,
            manageUsers: i18n.manage_users,
          },
          onAccountClick: () => {
            renderModule('basic', 'SettingsBasic');
          },
          isDirectResponsesBetaUser: false,
        };
        const settingsUIProps = {
          session: {
            jwt,
            locale: session.properties.locale,
            isEnterpriseUser: session.roles.indexOf('enterprise') !== -1,
          },
          settingsBffPath: config.urls.settingsBff,
        };

        // set up callback to show items in the menu, due to permission
        if ($.fn.SettingsResponseTemplates.API.permitted) {
          menuProps.onResponseTemplatesClick = () => {
            renderModule('responseTemplates', 'SettingsResponseTemplates');
          };
        }
        if ($.fn.SettingsAlerts.API.permitted) {
          menuProps.onEmailAlertsClick = () => {
            renderModule('alerts', 'SettingsAlerts');
          };
        }

        // user management endpoint to clone a user can clone only users with `dashboard` permission
        const canManageUsers =
          !!session.properties.default_cluster &&
          permissionsWorker.hasPermission([PERMISSIONS.DASHBOARD]) &&
          !permissionsWorker.hasPermission([PERMISSIONS.DISABLE_USERS_MANAGEMENT]);
        if (canManageUsers) {
          menuProps.onManageUsersClick = () => userManage.init({});
        }

        if ($.fn.SettingsPMSIntegrations.API.permitted) {
          const renderPMSIntegrations = (el) => {
            renderComponent(Integrations, el, settingsUIProps);
          };
          menuProps.onPMSIntegrationsClick = () => {
            renderModule('pmsIntegrations', 'SettingsPMSIntegrations', renderPMSIntegrations);
          };
        }
        if ($.fn.SettingsDirectResponses.API.permitted) {
          const renderDirectResponses = (el) => {
            renderComponent(DirectResponses, el, settingsUIProps);
          };

          if (isConfiguringDirectResponse) {
            menuProps.selectedSectionId = 'integrations';
            menuProps.selectedActionId = 'directResponses';
            renderModule('directResponses', 'SettingsDirectResponses', renderDirectResponses);
          }
          menuProps.onDirectResponsesClick = () => {
            renderModule('directResponses', 'SettingsDirectResponses', renderDirectResponses);
          };
        }

        // Render SettingsMenu
        renderComponent(SettingsMenu, content.find('.modules-menu')[0], menuProps);
        return $.Deferred().resolve();
      });
    };
    render();
  }, [require, session, permissionsWorker]);

  return (
    <SettingsView id='settings-view' className='view SETTINGS_MODULES'>
      <div className='modules-container layout-padded'>
        <div className='modules-menu'></div>
        <div className='modules-content'>
          <div className='module' id='basic'></div>
          <div className='module' id='responseTemplates'></div>
          <div className='module' id='alerts'></div>
          <div className='module' id='pmsIntegrations'></div>
          <div className='module' id='directResponses'></div>
        </div>
      </div>
    </SettingsView>
  );
};

Settings.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Settings);
