/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
  isLoading: true,
  labels: {},
  session: {
    account: {},
    defaultCluster: {},
    expired_permissions: [],
    permissions: [],
    products: [],
    properties: {},
    roles: [],
    ssoToken: '',
    trial_permissions: [],
    user: {},
    selectedCluster: {},
  }
};

const appSlice = createSlice({
  name: 'app',
  initialState: { ...defaultState },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLabels(state, action) {
      state.labels = action.payload;
    },
    setSession(state, action) {
      state.session = action.payload;
    },
  }
});

export const {
  setIsLoading,
  setLabels,
  setSession,
} = appSlice.actions;

export default appSlice.reducer;
