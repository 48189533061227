import { styled, Box } from '@trustyou/ty-brew/mui';
import { RowIconText } from '@trustyou/ty-brew';

export const TopbarMenuItemWrapper = styled(Box)({
  height: '100%',
});

const TopbarMenuItem = styled(RowIconText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  height: '100%',
  cursor: 'pointer',
  justifyContent: 'center',
  position: 'relative',
  '&::after': {
    transform: 'scale(0, 1)',
    transition: 'all 0.2s ease-in-out',
    content: '""',
    position: 'absolute',
    height: '2px',
    backgroundColor: theme.palette.text.secondary,
    bottom: '0',
    width: '100%',
    left: '0',
  },
  '&:hover': {
    color: theme.palette.text.primary,
    '&::after': {
      transform: 'scale(1, 1)',
    },
  },
}));

export default TopbarMenuItem;
