import BaseService from './BaseService';

export default class PortfolioService extends BaseService {
  constructor(hostUrl, { idpService = null } = {}) {
    super();
    this.hostUrl = hostUrl;
    this.baseUrl = `${this.hostUrl}/portfolio`;
    this.idpService = idpService;
  }

  async getUserGroups({
    userId,
    location = 'PORTFOLIO',
    includeDetails = false
  }) {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      user_id: userId,
      location,
      include_details: includeDetails
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/groups?${queryParams}`,
      {
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async getUserRegionSets({
    userId,
    location = 'PORTFOLIO',
    regionSetId,
    lang
  }) {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      user_id: userId,
      location,
      region_set_id: regionSetId,
      lang
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/region_sets?${queryParams}`,
      {
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async getUserBrands({ userId, location = 'PORTFOLIO', configured = true }) {
    const headers = await this.idpService?.getIdpHeaders();
    const queryParams = new URLSearchParams({
      user_id: userId,
      location,
      configured
    });
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/brands?${queryParams}`,
      {
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }

  async getCompetitorsClusters({ userId, clusterIds }) {
    const headers = await this.idpService?.getIdpHeaders();
    const fetchResponse = await BaseService.fetchOk(
      `${this.baseUrl}/competitors/clusters`,
      {
        method: 'POST',
        body: JSON.stringify({
          user_id: userId,
          cluster_list: clusterIds
        }),
        headers
      }
    );
    const response = await fetchResponse.json();
    return response?.response;
  }
}
