import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@trustyou/ty-brew/mui';

import { usePermissions } from '../../../contexts/permissions/permissionsContext';
import useHash from '../../../hooks/useHash';
import Breadcrumbs from './Breadcrumbs';
import TopbarMenu from './TopbarMenu';
import MessagingNotification from './MessagingNotification';
import { PRODUCTS_NAMES } from '../../../core/ProductManager/ProductManager';

const Topbar = () => {
  const activeTabHash = useSelector((state) => state.sidebar.activeHash);
  const [activeHash, setActiveHash] = useState(null);

  const tabHash = useHash();

  useEffect(() => {
    const newHash = tabHash || activeTabHash;
    setActiveHash(newHash);
  }, [tabHash, activeTabHash]);

  const { productManager } = usePermissions();

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '640px',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        boxSizing: 'border-box',
        borderWidth: '0 0 1px 1px',
        borderColor: 'grey.300',
        borderStyle: 'solid',
      }}
    >
      <Breadcrumbs tabHash={activeHash} />
      <TopbarMenu />
      {productManager.isProductEnabled(PRODUCTS_NAMES.MESSAGING) && <MessagingNotification />}
    </Box>
  );
};

export default Topbar;
