import React from 'react';
import PropTypes from 'prop-types';
import { ListSubheader } from '@mui/material';

const Section = ({ label, children }) => (
  <>
    <ListSubheader
      disableSticky
      sx={{
        color: 'text.secondary',
        textTransform: 'uppercase',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '0.625rem',
        lineHeight: '2rem',
        fontWeight: '400',
        letterSpacing: '1px',
      }}
    >
      {label}
    </ListSubheader>
    {children}
  </>
);
Section.propTypes = {
  label: PropTypes.node,
};

export default Section;
