import config from '../../../../config';
import BaseService from '../../../../services/BaseService';
import IdpService from '../../../../services/IdpService';

export const createCredits = async (payload) => {
  const idpService = new IdpService({
    idpClientId: config.idp.clientId,
    idpSecret: config.idp.secret,
    idpUrl: config.idp.idpUrl,
  });
  const idpToken = await idpService.getIdpToken();
  const response = await BaseService.fetchOk(`${config.urls.reviewsInboxBff}/response-ai/credits`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idpToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return response.json();
};
