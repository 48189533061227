import React from 'react';
import { Stack, Box, Typography, Paper } from '@trustyou/ty-brew/mui';
import { FormattedMessage } from 'react-intl';
import config from '../../../config';
import SubmitButton from '../../Login/Buttons/SubmitButton';

const CXPAlreadyUpgraded = () => (
  <Box sx={{ display: 'flex', flexGrow: 1 }}>
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Box
        component='img'
        sx={{
          height: '110px',
          width: '110px',
        }}
        src={`${config.urls.tyAssets}/common/img/logos/cxp-rocket.png`}
      />

      <Stack sx={{ alignItems: 'center', marginY: 4 }}>
        <Typography variant='h6' color='text.primary'>
          <FormattedMessage
            id='cxp_already_upgraded_title'
            defaultMessage='You’ve already been upgraded to Customer Experience Platform'
          />
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          <FormattedMessage id='cxp_already_upgraded_description_1' defaultMessage='Please visit' />{' '}
          <a href={'https://cxp.trustyou.com/'} target='_blank' rel='noopener noreferrer'>
            https://cxp.trustyou.com/
          </a>{' '}
          <FormattedMessage id='cxp_already_upgraded_description_2' defaultMessage='to login' />
        </Typography>
      </Stack>
      <SubmitButton sx={{ minWidth: '150px' }} onClick={() => window.open('https://cxp.trustyou.com/')}>
        <FormattedMessage id='login_to_cxp' defaultMessage='Login to CXP' />
      </SubmitButton>
    </Paper>
  </Box>
);

export default CXPAlreadyUpgraded;
