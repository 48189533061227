import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Alert, Box, Link } from '@trustyou/ty-brew/mui';

import PageLoading from '../../PageLoading';
import { useServices } from '../../../../contexts/services/servicesContext';
import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import { getFilterData } from './service';
import { getDefaultTiles } from '../tiles';
import { getCreateView } from '../service';
import envConfig from '../../../../config';

// TODO: remove this deprecation alert on March 31st 2023 (undo SUR-3332)
// https://confluence.trustyou.com/display/PH/Survey+Lite+Deprecation
function DeprecationBanner() {
  const { permissionsWorker } = usePermissions();
  const shouldBeVisible =
    permissionsWorker.hasPermission([PERMISSIONS.STARS_LITE]) ||
    permissionsWorker.hasPermission([PERMISSIONS.SURVEY_LITE]);

  return shouldBeVisible ? (
    <Alert severity='warning' sx={{ width: '100%' }}>
      <p>
        This product is being deprecated and will not be accessible anymore from March 31st onwards. To continue using
        it please contact{' '}
        <Link
          href='mailto:service@trustyou.com'
          sx={{ color: 'inherit', fontWeight: '700', textDecoration: 'underline !important' }}
        >
          service@trustyou.com
        </Link>{' '}
        to move to the paid version.
      </p>
    </Alert>
  ) : null;
}

const Survey = ({ require }) => {
  const session = useSelector((state) => state.app.session);

  const { permissionsWorker } = usePermissions();
  const { portfolioService, clusterService, tyraService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get view from DB or set default one
      const surveyTiles = [
        {
          type: 'OverviewTabContainerSurvey',
          filter: {
            activeTab: 1,
            folder: {},
            tablePreset: {
              sources: [],
              columns: [],
            },
            averagesTotals: 'totals',
            name: '',
            pageSize: 20,
          },
        },
        ...getDefaultTiles(envConfig.defaultTiles.survey, {
          generic: true,
        }),
      ];
      const surveyLiteTiles = getDefaultTiles(envConfig.defaultTiles.surveyLite, { generic: true });
      const view = await getCreateView(tyraService, {
        type: 'SURVEY_VIEW',
        pageId: 'survey',
        parameters: {
          tiles: permissionsWorker.hasPermission([PERMISSIONS.STARS_LITE]) ? surveyLiteTiles : surveyTiles,
          filter: {
            time: { time: 'last30days' },
          },
        },
      });

      const { clusterMappings, filterOptions } = await getFilterData({
        session,
        portfolioService,
        clusterService,
        view,
      });
      require(['survey-app', 'tyra-5/static/js/config', 'dashboard-agg', 'portfolio-app'], (surveys, config) => {
        require(['jquery', 'survey-app-export', 'add-tile-button', 'portfolio/static/js/filterbar'], (
          $,
          createModule,
          AddTileButton
        ) => {
          const content = $('#survey-view');

          $('#survey-summary-container .ty-loading-small').show();

          $.Topic('survey/tilesLoaded').subscribe((grid) => {
            grid.find('.tile-blank').remove().DashboardModule().destroy();
            const onAddTileButton = () => content.find('.add-tile-button').trigger('click');
            const ghostTile = new AddTileButton(onAddTileButton);
            grid.addModule(ghostTile.iconParams, ghostTile.detailsParams);
            grid.rearrange();
          });

          const params = {
            exportModule: (element, actionContainer, exportParams, restoring, alwaysShow) => {
              createModule(
                element,
                {
                  app: 'survey',
                  module: exportParams.module,
                  filter: exportParams.filter,
                  moduleLabel: exportParams.moduleLabel,
                },
                restoring,
                alwaysShow,
                actionContainer
              );
            },
            detailsElement: content.find('#survey-details-container'),
            summaryElement: content.find('#survey-summary-container'),
            // not used anymore
            starsLiteTermsElement: content.find('#stars-lite-terms-wrapper'),
            showSummaryHeader: false,
            showDetailsHeader: true,
            singleMode: permissionsWorker.hasPermission([PERMISSIONS.STARS]),
            onRendered: (filter) => {
              createModule(
                $(
                  '#survey-summary-container .surveys-view .survey-overview-container .survey-overview-table-container'
                ),
                { app: 'survey', module: 'survey-overview', filter },
                true
              );
            },
            onFilterUpdated(filter) {
              // remove existing actions
              $('#survey-summary-container .surveys-view .survey-overview-container .survey-overview-table-container')
                .ExportModule()
                .destroy();

              createModule(
                $(
                  '#survey-summary-container .surveys-view .survey-overview-container .survey-overview-table-container'
                ),
                { app: 'survey', module: 'survey-overview', filter },
                true
              );
            },
            brandsEnabled: !!session.properties.region_set_id,
            filterOptions,
            filter: view.parameters.filter,
            onSaveFilter: (newFilter) => {
              view.parameters.filter = {
                ...view.parameters.filter,
                ...newFilter,
              };
              // save filter in DB view parameters
              view?.id && tyraService.updateView(view);
            },
            tiles: view.parameters.tiles,
            saveCallback: (tiles, newFilter) => {
              view.parameters.tiles = tiles;
              view.parameters.filter = {
                ...view.parameters.filter,
                ...newFilter,
              };
              // save filter and tile in DB view parameters
              view?.id && tyraService.updateView(view);
            },
            clusterMappings,
            starsLiteWelcomeFeed: `${config.feeds.stars_lite_welcome}&lang=${session.properties.locale}`,
            welcomeFeed: `${config.feeds.stars_welcome}&lang=${session.properties.locale}`,
            onWelcomeClose() {
              view.parameters.show_welcome = false;
              // save filter and tile in DB view parameters
              view?.id && tyraService.updateView(view);
            },
            showWelcomeMessage() {
              if (typeof view.parameters.show_welcome === 'undefined') {
                view.parameters.show_welcome = true;
              }
              return view.parameters.show_welcome;
            },
            urls: config.urls,
          };
          surveys(params);
        });
      });
    };
    render();
  }, [require, session, clusterService, permissionsWorker, portfolioService, tyraService]);

  return (
    <>
      <DeprecationBanner />
      <Box
        sx={{
          width: '1000px',
          margin: 0,
          padding: '20px 20px 0 20px',
          a: {
            textDecoration: 'none',
          },
        }}
        id='survey-view'
        className='view SURVEY_VIEW'
      >
        <Box
          className='view-wrapper'
          sx={{
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            background: '#fff',
            color: '#333333',
            width: '962px',
            margin: '0 0 20px',
            '.mailing-config-container': {
              marginTop: '-46px',
              '.btn': {
                padding: '4px 12px 4px 12px',
                fontSize: '.95em',
              },
            },
            ...(permissionsWorker.hasPermission([PERMISSIONS.STARS, PERMISSIONS.STARS_LITE]) && {
              display: 'none',
            }),
          }}
        >
          <Box
            className='view-header'
            sx={{
              borderRadius: '4px 4px 0 0',
              padding: '10px 20px',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                background: '#e5e5e5',
                height: '1px',
                left: '20px',
                right: '20px',
                bottom: 0,
                zIndex: 0,
              },
              h1: {
                color: '#3498db',
                lineHeight: '28px',
                margin: 0,
                padding: 0,
                fontSize: '16px',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontWeight: 500,
              },
            }}
          >
            <h1>
              <span className='header-title'>
                <FormattedMessage id='my_surveys_on_turstyou_survey' defaultMessage='@My Surveys on TrustYou Survey' />
              </span>
            </h1>
          </Box>
          <div id='survey-summary-container' />
        </Box>
        <div id='survey-details-container'>
          <PageLoading />
        </div>
      </Box>
    </>
  );
};

Survey.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Survey);
