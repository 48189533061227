export default class BaseService {
  static async fetchOk(...args) {
    const response = await fetch(...args);
    if (!response.ok) {
      const error = new Error(
        `Bad response from server: ${response.status} (${response.statusText})`
      );
      error.response = response;
      throw error;
    }
    return response;
  }
}
