import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Stack, ThemeProvider, alpha, createTheme, darken, lighten, theme } from '@trustyou/ty-brew/mui';

import config from '../../../config';
import Footer from '../Footer';
import Partners from '../Partners';
import { THEMES } from '../constants';
import { fetchEventData } from '../service';

const MainContainer = ({ children }) => {
  const { themeId } = useParams();
  const upperCaseThemeID = themeId?.toUpperCase();
  const themeConfigs = {
    [THEMES.VOG.id]: {
      logoImage: 'vog_logo.jpg',
      applyOverlay: true,
    },
    [THEMES.LHG.id]: {
      logoImage: 'lhg_logo.svg',
      applyOverlay: false,
    },
    [THEMES.DCT.id]: {
      logoImage: 'dct_logo.png',
      applyOverlay: false,
    },
    [THEMES.RAKTDA.id]: {
      logoImage: 'raktda_logo.svg',
      applyOverlay: false,
    },
    default: {
      logoImage: 'ty_logo_300x32.svg',
      applyOverlay: false,
    },
  };
  const filename = themeConfigs[themeId]?.logoImage || themeConfigs.default.logoImage;
  const logo = `${config.urls.tyAssets}/common/img/logos/${filename}`;

  const { data: tyEvent } = useQuery({
    queryKey: ['tyEvent'],
    queryFn: () => fetchEventData(),
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const customTheme = useMemo(
    () =>
      createTheme(
        theme,
        themeId &&
          !!THEMES[upperCaseThemeID] && {
          palette: {
            primary: {
              main: THEMES[upperCaseThemeID].primaryColor,
              light: lighten(THEMES[upperCaseThemeID].primaryColor, 0.24),
              dark: darken(THEMES[upperCaseThemeID].primaryColor, 0.12),
            },
          },
        }
      ),
    [themeId, upperCaseThemeID]
  );

  const sidebarImage = tyEvent?.imageUrl
    ? tyEvent.imageUrl
    : `${config.urls.tyAssets}/common/img/logos/login_sidebar.jpg`;

  const SideBarImage = () => (
    <Box
      sx={(_theme) => {
        const sidebarImageColorOverlay = themeConfigs[themeId]?.applyOverlay
          ? `linear-gradient(${alpha(_theme.palette.primary.main, 0.6)}, ${alpha(_theme.palette.primary.main, 0.6)}),`
          : '';
        return {
          display: 'flex',
          backgroundImage: `${sidebarImageColorOverlay} url(${sidebarImage})`,
          backgroundSize: 'cover',
          [_theme.breakpoints.up('md')]: {
            width: '40%',
          },
        };
      }}
    >
      {!!tyEvent?.eventUrl && <Box component={'a'} href={tyEvent?.eventUrl} target='_blank' sx={{ flexGrow: 1 }} />}
    </Box>
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <SideBarImage />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <Stack justifyContent='space-around'>
            <Stack alignItems='center'>
              <Box
                component='img'
                src={logo}
                sx={{ marginTop: 4, marginBottom: 2, maxWidth: themeId === THEMES.VOG.id ? '130px' : '230px' }}
              />
              {children}
            </Stack>
            <Partners />
            <Footer />
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MainContainer;
