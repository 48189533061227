import React, { useState, useEffect } from 'react';
import { Stack, TextField, Button, InputAdornment, Alert, Typography, CircularProgress } from '@trustyou/ty-brew/mui';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { green, grey } from '@trustyou/ty-brew/colors';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CXPConfirmPasswordRequirementsList, CXPPasswordRequirementsList } from './CXPPasswordRequirements';
import SubmitButton from '../../../Login/Buttons/SubmitButton';
import { cxpSetPassword } from '../../service';

const CXPInputFields = ({ setCXPTransferSuccessful }) => {
  const session = useSelector((state) => state.app.session);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);

  // Errors
  const [minCharIconColor, setMinCharIconColor] = useState(grey[500]);
  const [oneDigitIconColor, setOneDigitIconColor] = useState(grey[500]);
  const [oneLetterIconColor, setOneLetterIconColor] = useState(grey[500]);
  const [matchingPassIconColor, setMatchingPassIconColor] = useState(grey[500]);

  const {
    register,
    formState: { isDirty, isValid },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: session.properties.first_name,
      lastName: session.properties.last_name,
      password: '',
      confirmPassword: '',
    },
  });

  const subscribeChangesOfPassword = watch('password');
  const subscribeChangesOfConfirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (!!watch('password') || !!watch('confirmPassword')) {
      // Check length at least 8 char long
      const isPassHasEightChar = watch('password').length > 7;
      setMinCharIconColor(isPassHasEightChar ? green[800] : grey[500]);
      // Check if contains one digit
      const isPassHasOneDigit = /\d/.test(watch('password'));
      setOneDigitIconColor(isPassHasOneDigit ? green[800] : grey[500]);
      // Checking if contains one uppercase letter
      const isPassHasOneLetter = /[A-Z]/.test(watch('password'));
      setOneLetterIconColor(isPassHasOneLetter ? green[800] : grey[500]);
      // Checking if passwords matching
      const isPasswordsMatching = watch('password') === watch('confirmPassword');
      setMatchingPassIconColor(isPasswordsMatching ? green[800] : grey[500]);
    } else {
      setMinCharIconColor(grey[500]);
      setOneDigitIconColor(grey[500]);
      setOneLetterIconColor(grey[500]);
    }
  }, [watch, subscribeChangesOfPassword, subscribeChangesOfConfirmPassword]);

  const {
    isError,
    isLoading,
    mutate: submitNewCXPPassword,
  } = useMutation(cxpSetPassword, {
    onSuccess: () => {
      localStorage.setItem('migrated-to-cxp', JSON.stringify(session.user.id));
      setCXPTransferSuccessful(true);
    },
  });

  const submitChanges = async (values) => {
    const { firstName, lastName, password } = values;

    submitNewCXPPassword({
      userId: session.user.id,
      firstName,
      lastName,
      password,
      ssoToken: session.ssoToken,
    });
  };

  return (
    <Stack sx={{ width: '340px', alignItems: 'center' }}>
      {isError && (
        <Alert severity='error' sx={{ width: '320px' }}>
          <Typography variant='body2'>
            <FormattedMessage id='cxp_create_user_error' defaultMessage='An error occurred. Please try again.' />
          </Typography>
        </Alert>
      )}
      <TextField
        id='firstName'
        type={'text'}
        label={<FormattedMessage id='cxp_first_name' defaultMessage='First name' />}
        variant='standard'
        fullWidth
        size='44px'
        sx={{ marginY: 1 }}
        disabled={isLoading}
        {...register('firstName', { required: true })}
      />
      <TextField
        id='lastName'
        type={'text'}
        label={<FormattedMessage id='cxp_last_name' defaultMessage='Last name' />}
        variant='standard'
        fullWidth
        size='44px'
        sx={{ marginY: 1 }}
        disabled={isLoading}
        {...register('lastName', { required: true })}
      />
      <TextField
        id='password'
        type={isPasswordVisible ? 'text' : 'password'}
        label={<FormattedMessage id='cxp_password' defaultMessage='Password' />}
        variant='standard'
        fullWidth
        size='44px'
        sx={{ marginY: 1 }}
        error={(!isDirty || !isValid) && !!watch('password')}
        disabled={isLoading}
        onBlurCapture={() => setIsPasswordFocused(false)}
        onFocus={() => setIsPasswordFocused(true)}
        {...register('password', {
          required: true,
          minLength: 8,
          pattern: /\d|[a-zA-Z]/,
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position='start'>
              {!!watch('password') && (
                <Button variant='text' onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  {isPasswordVisible ? (
                    <FormattedMessage id='cxp_password_hide' defaultMessage='Hide' />
                  ) : (
                    <FormattedMessage id='cxp_password_show' defaultMessage='Show' />
                  )}
                </Button>
              )}
            </InputAdornment>
          ),
        }}
      />
      {!!watch('password') && isPasswordFocused && (
        <CXPPasswordRequirementsList
          oneDigitIconColor={oneDigitIconColor}
          oneLetterIconColor={oneLetterIconColor}
          minCharIconColor={minCharIconColor}
        />
      )}
      <TextField
        id='newPassword'
        type={isConfirmPasswordVisible ? 'text' : 'password'}
        label={<FormattedMessage id='cxp_confirm_password' defaultMessage='Confirm password' />}
        variant='standard'
        fullWidth
        size='44px'
        sx={{ marginY: 1 }}
        error={(!isDirty || !isValid) && !!watch('confirmPassword')}
        disabled={isLoading}
        onBlurCapture={() => setIsConfirmPasswordFocused(false)}
        onFocus={() => setIsConfirmPasswordFocused(true)}
        {...register('confirmPassword', {
          required: true,
          validate: (value) => watch('password') === value || '',
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position='start' sx={{ marginBlock: 2.5 }}>
              {!!watch('confirmPassword') && (
                <Button variant='text' onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}>
                  {isConfirmPasswordVisible ? (
                    <FormattedMessage id='cxp_password_hide' defaultMessage='Hide' />
                  ) : (
                    <FormattedMessage id='cxp_password_show' defaultMessage='Show' />
                  )}
                </Button>
              )}
            </InputAdornment>
          ),
        }}
      />
      {!!watch('confirmPassword') && isConfirmPasswordFocused && (
        <CXPConfirmPasswordRequirementsList matchingPassIconColor={matchingPassIconColor} />
      )}
      <SubmitButton
        sx={{ minWidth: '150px', marginTop: 5 }}
        disabled={!isDirty || !isValid || isLoading}
        onClick={handleSubmit(submitChanges)}
      >
        {isLoading && <CircularProgress size={18} color='inherit' sx={{ marginRight: 1 }} />}
        <FormattedMessage id='cxp_continue_button' defaultMessage='Continue' />
      </SubmitButton>
    </Stack>
  );
};

CXPInputFields.propTypes = {
  setCXPTransferSuccessful: PropTypes.func,
};

export default CXPInputFields;
