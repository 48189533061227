import * as React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@trustyou/ty-brew/mui';
import { Icon } from '@trustyou/ty-brew';

const DropdownItem = ({ id, icon, label, clickHandler, selected, ...props }) => {
  if (props.href) {
    props.component = 'a';
  }

  const _clickHandler = (e) => {
    if (selected) {
      e.preventDefault();
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    clickHandler?.(e, id);
  };

  return (
    <MenuItem
      key={id}
      onClick={_clickHandler}
      selected={selected}
      sx={{
        color: 'text.secondary',
        '& .MuiListItemIcon-root': {
          minWidth: '24px',
          color: 'inherit',
        },
      }}
      {...props}
    >
      {icon && (
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography variant='body2'>{label}</Typography>
      </ListItemText>
    </MenuItem>
  );
};

DropdownItem.displayName = 'ItemsDropdown';
DropdownItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  clickHandler: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
};

export default DropdownItem;
