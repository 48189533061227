import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grow,
  InputLabel,
  Link,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ty-brew/mui';

import { createCredits } from './service';

const schema = z.object({
  clusterId: z.string().uuid({ message: 'Invalid format. Example: 3b932d79-4ae0-417d-9dab-0a861ae7ff7c' }),
  creditsAmount: z.number().positive().lt(1e6),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  salesforceLink: z.string().url({ message: 'Invalid URL. It must start with https://' }),
});

// eslint-disable-next-line react/prop-types
function Field({ id, label, success, children }) {
  return (
    <Stack spacing={1} sx={{ alignItems: 'start' }}>
      <InputLabel htmlFor={id}>
        {!success && (
          <Box component='span' sx={{ color: 'error.main' }}>
            *{' '}
          </Box>
        )}
        <Box component='span' sx={[{ color: 'text.primary' }, success && { fontSize: '14px', fontWeight: 700 }]}>
          {label}
        </Box>
      </InputLabel>
      {children}
    </Stack>
  );
}

function SlideTransition(props) {
  return <Slide {...props} direction='up' />;
}

export default function CreditsOnboarding() {
  const { mutateAsync, isError, isSuccess } = useMutation({
    mutationKey: ['create', 'credits'],
    mutationFn: (payload) => createCredits(payload),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors = {}, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      clusterId: '',
      creditsAmount: 0,
      startDate: dayjs(),
      endDate: dayjs().add(1, 'year'),
      salesforceLink: '',
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    const payload = {
      cluster_id: data.clusterId,
      amount_of_credits: data.creditsAmount,
      start_date: dayjs(data.startDate).format('YYYY-MM-DD'),
      expiration_date: dayjs(data.endDate).format('YYYY-MM-DD'),
      crm_link: data.salesforceLink,
    };
    mutateAsync(payload);
  };
  const success = isSubmitSuccessful && isSuccess;

  return (
    <Stack component='form' spacing={success ? 4 : 3} sx={{ width: 600 }} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h6' component='h1'>
        ResponseAI Onboarding
      </Typography>
      {success && (
        <Grow in={success}>
          <Alert severity='success'>
            <AlertTitle>Success</AlertTitle>
            Credits successfully added to cluster
          </Alert>
        </Grow>
      )}
      {isError && (
        <Snackbar
          open={isError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={SlideTransition}
        >
          <Alert severity='error'>
            <AlertTitle>Something went wrong, credits were not added to cluster</AlertTitle>
            Please try again
          </Alert>
        </Snackbar>
      )}
      <Controller
        name='clusterId'
        control={control}
        render={({ field }) => (
          <Field id='cluster-id' label='Cluster ID' width='38ch' success={success}>
            {success ? (
              <Typography>{field.value}</Typography>
            ) : (
              <TextField
                helperText={errors.clusterId?.message ?? 'TrustYou hotel ID'}
                error={Boolean(errors.clusterId?.message)}
                {...field}
                id='cluster-id'
                sx={{ minWidth: '38ch' }}
              />
            )}
          </Field>
        )}
      />
      <Controller
        name='creditsAmount'
        control={control}
        render={({ field }) => (
          <Field id='amount-of-credits' label='Amount of Credits' width='200px' success={success}>
            {success ? (
              <Typography>{field.value}</Typography>
            ) : (
              <TextField
                type='number'
                helperText={errors.creditsAmount?.message ?? 'Numbers'}
                error={Boolean(errors.creditsAmount?.message)}
                {...field}
                id='amount-of-credits'
                onChange={(event) => field.onChange(parseInt(event.target.value))}
                sx={{ minWidth: 150 }}
              />
            )}
          </Field>
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name='startDate'
          control={control}
          render={({ field }) => (
            <Field id='start-date' label='Start date' success={success}>
              {success ? (
                <Typography>{field.value.format('YYYY-MM-DD')}</Typography>
              ) : (
                <DatePicker
                  format='DD/MM/YYYY'
                  {...field}
                  slotProps={{
                    textField: {
                      helperText: errors.startDate?.message ?? 'First day to use credits',
                      // FIXME: empty date does not set the error
                      error: Boolean(errors.startDate?.message),
                      id: 'start-date',
                      sx: { minWidth: 262 },
                    },
                  }}
                />
              )}
            </Field>
          )}
        />
        <Controller
          name='endDate'
          control={control}
          render={({ field }) => (
            <Field id='end-date' label='End date' success={success}>
              {success ? (
                <Typography>{field.value.format('YYYY-MM-DD')}</Typography>
              ) : (
                <DatePicker
                  format='DD/MM/YYYY'
                  {...field}
                  slotProps={{
                    textField: {
                      helperText: errors.endDate?.message ?? 'First day when credits will be unavailable',
                      // FIXME: empty date does not set the error
                      error: Boolean(errors.endDate?.message),
                      id: 'end-date',
                      sx: { minWidth: 262 },
                    },
                  }}
                />
              )}
            </Field>
          )}
        />
      </LocalizationProvider>
      <Controller
        name='salesforceLink'
        control={control}
        render={({ field }) => (
          <Field id='salesforce-link' label='Salesforce opportunity link' success={success}>
            {success ? (
              <Link href={field.value} target='_blank' sx={{ alignSelf: 'start' }}>
                {field.value}
              </Link>
            ) : (
              <TextField
                helperText={errors.salesforceLink?.message ?? 'URL'}
                error={Boolean(errors.salesforceLink?.message)}
                {...field}
                id='salesforce-link'
                fullWidth
              />
            )}
          </Field>
        )}
      />
      <Box sx={{ display: 'flex', gap: 1, paddingTop: 4 }}>
        {!success && (
          <Button type='submit' variant='contained' color='primary' disabled={success}>
            Add credits
          </Button>
        )}
        <Button
          type='reset'
          variant={success ? 'contained' : 'text'}
          color={success ? 'primary' : 'inherit'}
          onClick={reset}
        >
          {success ? 'Onboard another' : 'Reset'}
        </Button>
      </Box>
    </Stack>
  );
}
