/* eslint-disable camelcase */

const prepareFilterData = ({
  regionSetsResponse,
  userBrandsResponse,
  userGroupsResponse,
  userClusterMappingResponse
}) => {
  // countries
  const countries = (regionSetsResponse?.country_list || []).map((c) => ({
    id: c.iso2_country,
    name: c.name,
    cmp: (c.name || '').trim().toLowerCase()
  }));
  countries.sort((a, b) => a.cmp.localeCompare(b.cmp));

  // regions
  const regions = (regionSetsResponse?.region_list || []).map((r) => ({
    id: r.region_id,
    name: r.region_name,
    cmp: (r.region_name || '').trim().toLowerCase()
  }));
  const WORLDWIDE_REGION_ID = '-1';
  regions.sort((a, b) => {
    // Worlwide region to be the first
    if (a.id === WORLDWIDE_REGION_ID) {
      return -1;
    }
    if (b.id === WORLDWIDE_REGION_ID) {
      return 1;
    }
    return a.cmp.localeCompare(b.cmp);
  });

  // brands
  const brands = (userBrandsResponse?.brand_list || []).map((b) => ({
    id: b.brand_id,
    name: b.brand_name,
    cmp: (b.brand_name || '').trim().toLowerCase()
  }));
  brands.sort((a, b) => a.cmp.localeCompare(b.cmp));

  // groups
  const groups = (userGroupsResponse?.group_list || []).map((g) => {
    if (!(g.count && (g.count === '0' || g.count === 0))) {
      return {
        id: g.group_id,
        name: g.group_name,
        cmp: (g.group_name || '').trim().toLowerCase()
      };
    }
    return null;
  });
  groups.sort((a, b) => a.cmp.localeCompare(b.cmp));

  const clusterMappings = userClusterMappingResponse?.mapping_list || [];
  return {
    filterOptions: {
      countries,
      regions,
      brands,
      groups
    },
    clusterMappings
  };
};

export const getFilterData = async ({
  session,
  portfolioService,
  clusterService
}) => {
  const [
    regionSetsResponse,
    userBrandsResponse,
    userGroupsResponse,
    userClusterMappingResponse
  ] = await Promise.all([
    session.properties.region_set_id ? portfolioService.getUserRegionSets({
      userId: session.user.id,
      regionSetId: session.properties.region_set_id,
      lang: session.properties.locale
    }) : null,
    session.properties.region_set_id ? portfolioService.getUserBrands({
      userId: session.user.id
    }) : null,
    portfolioService.getUserGroups({
      userId: session.user.id
    }),
    clusterService.getUserClusterMapping({
      userId: session.user.id
    })
  ]);
  const { clusterMappings, filterOptions } = prepareFilterData({
    regionSetsResponse,
    userBrandsResponse,
    userGroupsResponse,
    userClusterMappingResponse,
  });
  return {
    clusterMappings,
    filterOptions
  };
};
