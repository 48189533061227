import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@trustyou/ty-brew/mui';

import Profile from './Profile';
import Navigation from './Navigation';

const InnerSidebar = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => (
  <Box
    sx={{
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      width: '200px',
      backgroundColor: 'background.paper',
    }}
  >
    <Profile clickHandlers={clickHandlers} />
    <Navigation clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
  </Box>
);

InnerSidebar.propTypes = {
  clickHandlers: PropTypes.object,
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
};

export default InnerSidebar;
