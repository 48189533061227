import BaseService from './BaseService';

export default class SsoService extends BaseService {
  constructor(hostUrl, { idpService = null } = {}) {
    super();
    this.hostUrl = hostUrl;
    this.baseUrl = `${this.hostUrl}/sso`;
    this.idpService = idpService;
  }

  async putUserProperty({ userId, name, value, ssoToken }) {
    const headers = await this.idpService?.getIdpHeaders();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('value', value);
    formData.append('sso_token', ssoToken);
    await BaseService.fetchOk(`${this.baseUrl}/api/users/properties/${userId}`, {
      method: 'PUT',
      cache: 'no-cache',
      body: formData,
      headers,
    });
  }
}
