import React from 'react';
import PropTypes from 'prop-types';

import StyledFontAwesomeIcon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import Avatar from '@trustyou/ty-brew/mui/Avatar';
import Card from '@trustyou/ty-brew/mui/Card';
import Stack from '@trustyou/ty-brew/mui/Stack';
import Typography from '@trustyou/ty-brew/mui/Typography';
import { faCheckCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';

const MarketingItemsList = ({ items, ...a11yProps }) => (
  <Stack
    {...a11yProps}
    component='ul'
    spacing={1}
    sx={{
      margin: 0,
      listStyle: 'unset',
      paddingTop: 3,
      paddingX: 0,
    }}
  >
    {items.map(({ description }, index) => (
      <Stack
        component='li'
        key={index}
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{
          listStyle: 'unset',
        }}
      >
        <StyledFontAwesomeIcon
          icon={faCheckCircle}
          sx={{
            color: 'secondary.main',
          }}
        />
        <Typography variant='body2'>{description}</Typography>
      </Stack>
    ))}
  </Stack>
);

MarketingItemsList.propTypes = {
  /**
   * Array of items to render in a list
   * Composed by:
   * - @description: Item description
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
};

const MarketingItemsGrid = ({ items, ...a11yProps }) => (
  <Stack direction='row' paddingY={4} spacing={3} {...a11yProps}>
    {items.map(({ icon, title, description }, index) => (
      <Card key={index} sx={{ padding: 3, flex: 1 }}>
        <Stack direction='row' spacing={1} paddingBottom={1} alignItems='baseline'>
          <Avatar sx={{ bgcolor: 'secondary.light' }}>
            {/* FIXME: Workaround to show the first letter of the brand name, till we include brand icons in FA */}
            {typeof icon === 'string' ? icon?.[0]?.toUpperCase?.() : <StyledFontAwesomeIcon icon={icon} />}
          </Avatar>
          <Typography variant='h6'>{title}</Typography>
        </Stack>
        <Typography variant='body2'>{description}</Typography>
      </Card>
    ))}
  </Stack>
);

MarketingItemsGrid.propTypes = {
  /**
   * Array of items to render in a grid
   * Composed by:
   * - @icon Item icon to be render inside Avatar component
   * - @title Item title
   * - @description Item description
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

const MarketingSection = ({ id, title, description, children, descriptionAlignment = 'center' }) => (
  <Stack data-section-id={id} component='section' maxWidth={670}>
    <Stack spacing={1}>
      <Typography variant='h4' textAlign='center'>
        {title}
      </Typography>
      <Typography variant='body1' textAlign={descriptionAlignment}>
        {description}
      </Typography>
    </Stack>
    {children}
  </Stack>
);

MarketingSection.propTypes = {
  /**
   * Category section id
   */
  id: PropTypes.string.isRequired,
  /**
   * Category section title
   */
  title: PropTypes.string.isRequired,
  /**
   * Category section description
   */
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /**
   * Alignment of category description
   * @default 'center'
   */
  descriptionAlignment: PropTypes.oneOf(['center', 'left']),
};

export { MarketingSection, MarketingItemsGrid, MarketingItemsList };
