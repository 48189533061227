const config = {
  vertical: process.env.REACT_APP_VERTICAL,
  urls: {
    host: process.env.REACT_APP_URLS__HOST,
    cdn: process.env.REACT_APP_URLS__CDN,
    api: process.env.REACT_APP_URLS__API,
    messaging: process.env.REACT_APP_URLS__MESSAGING,
    messagingProxy: process.env.REACT_APP_URLS__MESSAGING_PROXY,
    messagingWebSocket: process.env.REACT_APP_URLS__MESSAGING_WEB_SOCKET,
    links: process.env.REACT_APP_URLS__LINKS,
    forms: process.env.REACT_APP_URLS__FORMS,
    formsUi: process.env.REACT_APP_URLS__FORMS_UI,
    logoutRest: process.env.REACT_APP_URLS__LOGOUT_REST,
    logoutHotel: process.env.REACT_APP_URLS__LOGOUT_HOTEL,
    rss: process.env.REACT_APP_URLS__RSS,
    onlineTraining: process.env.REACT_APP_URLS__ONLINE_TRAINING,
    settingsBff: process.env.REACT_APP_URLS__SETTINGS_BFF,
    reviewsInboxBff: process.env.REACT_APP_URLS__REVIEWS_INBOX_BFF,
    tyAssets: process.env.REACT_APP_URLS__TY_ASSETS,
    restaurantsHost: process.env.REACT_APP_URLS__RESTAURANTS_HOST,
    hotelsHost: process.env.REACT_APP_URLS__HOTELS_HOST,
    eventUrl: process.env.REACT_APP_EVENT_URL,
    clientOnboardingHost: process.env.REACT_APP_URLS__CLIENT_ONBOARDING_HOST,
  },
  idp: {
    secret: process.env.REACT_APP_IDP__SECRET,
    idpUrl: process.env.REACT_APP_IDP__IDP_URL,
    proxyApiUrl: process.env.REACT_APP_IDP__PROXY_API_URL,
    clientId: process.env.REACT_APP_IDP__CLIENT_ID,
  },
  gtm: {
    containerId: process.env.REACT_APP_GTM__CONTAINER_ID,
    auth: process.env.REACT_APP_GTM__AUTH,
    preview: process.env.REACT_APP_GTM__PREVIEW,
  },
  userlane: {
    enabled: process.env.REACT_APP_USERLANE__ENABLED,
    propertyId: process.env.REACT_APP_USERLANE__PROPERTY_ID,
  },
  zendesk: {
    chat: {
      enabled: process.env.REACT_APP_ZENDESK__CHAT__ENABLED,
      excludedDomains: process.env.REACT_APP_ZENDESK__CHAT__EXCLUDED_DOMAINS,
      clusterCountries: process.env.REACT_APP_ZENDESK__CHAT__CLUSTER_COUNTRIES,
      locales: process.env.REACT_APP_ZENDESK__CHAT__LOCALES,
      basicRoles: process.env.REACT_APP_ZENDESK__CHAT__BASIC_ROLES,
    },
    redirectUrls: {
      templates: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__TEMPLATES,
      comparison: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__COMPARISON,
      tasks: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__TASKS,
      default: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__DEFAULT,
      reviews: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__REVIEWS,
      customerServiceUrl: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__CUSTOMER_SERVICE_URL,
      survey: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__SURVEY,
      dashboard: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__DASHBOARD,
      portfolio: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__PORTFOLIO,
      goals: process.env.REACT_APP_ZENDESK__REDIRECT_URLS__GOALS,
    },
  },
  upsell: {
    unlockLiveExperienceTutorialUrl: process.env.REACT_APP_UPSELL__UNLOCK_LIVE_EXPERIENCE_TUTORIAL_URL,
    winMoreBookingsUrl: process.env.REACT_APP_UPSELL__WIN_MORE_BOOKINGS_URL,
    spa_tutorial_url: process.env.REACT_APP_UPSELL__SPA_TUTORIAL_URL,
    negativeFeedbackPreventionUrl: process.env.REACT_APP_UPSELL__NEGATIVE_FEEDBACK_PREVENTION_URL,
    miceTutorialUrl: process.env.REACT_APP_UPSELL__MICE_TUTORIAL_URL,
    vogJourneyTutorialUrl: process.env.REACT_APP_UPSELL__VOG_JOURNEY_TUTORIAL_URL,
    marketoScriptUrl: process.env.REACT_APP_UPSELL__MARKETO_SCRIPT_URL,
    registrationUrl: process.env.REACT_APP_UPSELL__REGISTRATION_URL,
    fbTutorialUrl: process.env.REACT_APP_UPSELL__FB_TUTORIAL_URL,
    guestSatisfactionImprovementUrl: process.env.REACT_APP_UPSELL__GUEST_SATISFACTION_IMPROVEMENT_URL,
  },
  defaultTiles: {
    portfolio: [
      'TrustScore',
      'Performance',
      'MessageDirection',
      'Responses',
      'Popularity',
      'Reviews',
      'SentimentScores',
      'GroupSentiment',
      'HousekeepingDepartmentGroupSentiment',
      'FrontOfficeDepartmentGroupSentiment',
      'FoodAndBeverageDepartmentGroupSentiment',
      'MaintenanceDepartmentGroupSentiment',
      'Social',
      'PerformanceTrendGraph',
      'BestPerformersGraph',
      'WorstPerformersGraph',
    ],
    survey: [
      'ReviewsSurvey',
      'BazaarReviewsSurvey',
      'QuestionsSurvey',
      'CategoryScoresSurvey',
      'MailingStatisticsSurvey',
      'StarsSurvey',
    ],
    dashboard: [
      'PerformanceIndex',
      'TrustScore',
      'Reviews',
      'Languages',
      'Responses',
      'PopularityRank',
      'Highlights',
      'CategoryScores',
      'Social',
      'Comparison',
      'FrontOfficeDepartmentGroupSentiment',
      'HousekeepingDepartmentGroupSentiment',
      'FoodAndBeverageDepartmentGroupSentiment',
      'MaintenanceDepartmentGroupSentiment',
      'MessageDirection',
      'TripAdvisorSource',
    ],
    surveyLite: [
      'ReviewsSurvey',
      'GeneralQuestionsSurvey',
      'CategoryScoresSurvey',
      'MailingStatisticsSurvey',
      'StarsSurvey',
    ],
    comparison: [
      'PerformanceComparison',
      'TrustScoreComparison',
      'ResponsesComparison',
      'PopularityComparison',
      'ReviewsComparison',
      'SentimentScoresComparison',
      'SocialComparison',
      'PerformanceTrendGraph',
      'BestPerformersGraph',
      'WorstPerformersGraph',
    ],
  },
};

export default config;
