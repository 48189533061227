import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { styled, Box } from '@trustyou/ty-brew/mui';

import innerSidebarTabs from '../Sidebar/InnerSidebar/innerSidebarTabs';
import minibarTabs from '../Sidebar/Minibar/minibarTabs';
import Templates from './Templates';
import Survey from './Survey';
import Goals from './Goals';
import Tasks from './Tasks';
import UsersAdmin from './UsersAdmin';
import SurveysAdmin from './SurveysAdmin';
import HotelAdmin from './HotelAdmin';
import Settings from './Settings';
import Reviews from './Reviews';
import Dashboard from './Dashboard';
import Portfolio from './Portfolio';
import Comparison from './Comparison';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const getVisibilityStyle = (tab, location) => {
  const isTabSelected = tab.route && tab.route === location?.pathname;
  return isTabSelected ? {} : { display: 'none' };
};

const needTabRender = (tab, location) => {
  const isTabAlreadyRendered = !!document.getElementById(`${tab.id}-wrapper`)?.innerHTML;
  const isTabSelected = tab.route && tab.route === location?.pathname;
  return isTabSelected || isTabAlreadyRendered;
};

const TabsLegacy = ({ require }) => {
  const location = useLocation();
  const {
    templates,
    survey,
    goals,
    tasks,
    surveysAdmin,
    usersAdmin,
    hotelAdmin,
    reviews,
    dashboard,
    portfolio,
    comparison
  } = innerSidebarTabs;

  const {
    postStay
  } = minibarTabs;

  return (
    <>
      <Wrapper
        id={`${templates.id}-wrapper`}
        style={getVisibilityStyle(templates, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(templates, location) && <Templates require={require} />}
      </Wrapper>
      <Wrapper
        id={`${survey.id}-wrapper`}
        style={getVisibilityStyle(survey, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(survey, location) && <Survey require={require} />}
      </Wrapper>
      <Wrapper
        id={`${goals.id}-wrapper`}
        style={getVisibilityStyle(goals, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(goals, location) && <Goals require={require} />}
      </Wrapper>
      <Wrapper
        id={`${tasks.id}-wrapper`}
        style={getVisibilityStyle(tasks, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(tasks, location) && <Tasks require={require} />}
      </Wrapper>
      <Wrapper
        id={`${surveysAdmin.id}-wrapper`}
        style={getVisibilityStyle(surveysAdmin, location)}
        className='trustyou-ui tab-container'
        sx={{ height: '100vh' }}
      >
        {needTabRender(surveysAdmin, location) && <SurveysAdmin />}
      </Wrapper>
      <Wrapper
        id={`${usersAdmin.id}-wrapper`}
        style={getVisibilityStyle(usersAdmin, location)}
        className='trustyou-ui tab-container'
        sx={{ height: '100vh' }}
      >
        {needTabRender(usersAdmin, location) && <UsersAdmin />}
      </Wrapper>
      <Wrapper
        id={`${hotelAdmin.id}-wrapper`}
        style={getVisibilityStyle(hotelAdmin, location)}
        className='trustyou-ui tab-container'
        sx={{ width: '100%' }}
      >
        {needTabRender(hotelAdmin, location) && <HotelAdmin require={require} />}
      </Wrapper>
      <Wrapper
        id={`${postStay.id}-wrapper`}
        style={getVisibilityStyle(postStay, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(postStay, location) && <Settings require={require} />}
      </Wrapper>
      <Wrapper
        id={`${reviews.id}-wrapper`}
        style={getVisibilityStyle(reviews, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(reviews, location) && <Reviews require={require} location={location} />}
      </Wrapper>
      <Wrapper
        id={`${dashboard.id}-wrapper`}
        style={getVisibilityStyle(dashboard, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(dashboard, location) && <Dashboard require={require} />}
      </Wrapper>
      <Wrapper
        id={`${portfolio.id}-wrapper`}
        style={getVisibilityStyle(portfolio, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(portfolio, location) && <Portfolio require={require} />}
      </Wrapper>
      <Wrapper
        id={`${comparison.id}-wrapper`}
        style={getVisibilityStyle(comparison, location)}
        className='trustyou-ui tab-container'
      >
        {needTabRender(comparison, location) && <Comparison require={require} />}
      </Wrapper>
    </>
  );
};

TabsLegacy.propTypes = {
  require: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default TabsLegacy;
