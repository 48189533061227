import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@trustyou/ty-brew/mui';

import { usePermissions } from '../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../contexts/services/servicesContext';
import { PRODUCTS_NAMES } from '../../../core/ProductManager/ProductManager';
import { setActiveHash, setMessagingSubdomain } from '../../../redux/slices/sidebar';
import InnerSidebar from './InnerSidebar';
import innerSidebarTabs from './InnerSidebar/innerSidebarTabs';
import Minibar from './Minibar';
import { settingsTabs } from './Minibar/minibarTabs';
import minibarTabs from './Minibar/minibarTabs';
import SidebarSkeleton from './SidebarSkeleton';

const allTabs = {
  ...minibarTabs,
  ...innerSidebarTabs,
};

const isMinibarSettingItem = (hash) => hash === minibarTabs.live.hash || hash === minibarTabs.postStay.hash;
const Sidebar = ({
  minibarClickHandlers = {},
  minibarSelectedTabId,
  innerSidebarClickHandlers = {},
  innerSidebarSelectedTabId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { productManager, tabsPermissionsManager } = usePermissions();
  const { messagingService } = useServices();

  const [activeTabsId, setActiveTabsId] = useState([innerSidebarSelectedTabId]);
  const [minibarTabId, setMinibarTabId] = useState(minibarSelectedTabId);

  const handleDispatchActiveTabHash = useCallback((hashId) => dispatch(setActiveHash(hashId)), [dispatch]);

  const handlePopState = useCallback(
    (e) => {
      if (e.state === null) {
        e.preventDefault();
        return false;
      }
      const lastActive = activeTabsId[activeTabsId.length - 2];
      const lastActiveHash = allTabs[lastActive]?.hash;
      setMinibarTabId(isMinibarSettingItem(lastActiveHash) ? minibarTabs[lastActive].id : null);
      setActiveTabsId((prevActiveTabId) => prevActiveTabId.slice(0, -1));
      handleDispatchActiveTabHash(lastActiveHash);
    },
    [activeTabsId, handleDispatchActiveTabHash]
  );

  useEffect(() => {
    window.addEventListener('popstate', handlePopState, false);
    return () => window.removeEventListener('popstate', handlePopState, false);
  }, [handlePopState]);

  const setSelectedTabId = (tabId) => {
    const lastItem = activeTabsId[activeTabsId.length - 1];
    if (tabId !== lastItem) {
      const { hash } = allTabs[tabId];

      handleDispatchActiveTabHash(hash);
      setMinibarTabId(isMinibarSettingItem(hash) ? minibarTabs[tabId].id : null);
      setActiveTabsId([...activeTabsId, tabId]);
    }
  };

  const selectedTabId = activeTabsId[activeTabsId.length - 1];

  useEffect(() => {
    handleDispatchActiveTabHash(`#${innerSidebarSelectedTabId}`);
  }, [handleDispatchActiveTabHash, innerSidebarSelectedTabId]);

  useEffect(() => {
    // set first opened tab on login for analytics, for messaging innerSidebarSelectedTabId is always set
    const needFirstTabSelect = !innerSidebarSelectedTabId;
    const hashTabId = window.location.hash
      ? Object.keys(allTabs).find((tabId) => window.location.hash.startsWith(allTabs[tabId]?.hash))
      : null;
    const isHashTabVisible = hashTabId ? tabsPermissionsManager.isVisible(hashTabId) : false;
    // select frist visible tab from pre-defined list if current url hash is empty or selected has shouldn't be visible for the user
    if (needFirstTabSelect && !isHashTabVisible) {
      const firstTab = [
        innerSidebarTabs.dashboard,
        innerSidebarTabs.portfolio,
        innerSidebarTabs.reviews,
        innerSidebarTabs.survey,
        innerSidebarTabs.hotelAdmin,
        innerSidebarTabs.usersAdmin,
      ].find((tab) => tabsPermissionsManager.isVisible(tab.id));
      if (firstTab?.hash) {
        window.location.hash = firstTab?.hash;
      }
    }

    if (!productManager.isProductEnabled(PRODUCTS_NAMES.MESSAGING)) {
      // eslint-disable-next-line no-console
      console.warn('Messaging is not available');
      setIsLoading(false);
      return;
    }
    async function loadMessagingSubdomain() {
      try {
        const subdomain = await messagingService.getMessagingSubdomain();
        dispatch(setMessagingSubdomain(subdomain));
        setIsLoading(true);
      } catch {
        // eslint-disable-next-line no-console
        console.warn('Messaging subdomain load failed!');
      } finally {
        setIsLoading(false);
      }
    }
    loadMessagingSubdomain();
  }, [messagingService, dispatch, productManager, tabsPermissionsManager, innerSidebarSelectedTabId]);

  const hasToTrackMinibarTabsClick = minibarClickHandlers[settingsTabs.live.id];
  const hasToTrackTabsClick = innerSidebarClickHandlers && Object.keys(innerSidebarClickHandlers).length;

  if (isLoading) {
    return <SidebarSkeleton />;
  }

  return (
    <Box
      sx={{
        width: '260px',
        height: '100%',
        display: 'flex',
      }}
    >
      <Minibar
        clickHandlers={minibarClickHandlers}
        selectedTabId={minibarTabId}
        setSelectedTabId={hasToTrackMinibarTabsClick ? setSelectedTabId : handleDispatchActiveTabHash}
      />
      <InnerSidebar
        clickHandlers={innerSidebarClickHandlers}
        selectedTabId={selectedTabId}
        setSelectedTabId={hasToTrackTabsClick ? setSelectedTabId : handleDispatchActiveTabHash}
      />
    </Box>
  );
};
Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
  minibarClickHandlers: PropTypes.object,
  minibarSelectedTabId: PropTypes.string,
  innerSidebarClickHandlers: PropTypes.object,
  innerSidebarSelectedTabId: PropTypes.string,
};
export default Sidebar;
