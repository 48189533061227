import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { usePermissions } from '../../../../../../contexts/permissions/permissionsContext';
import useHash from '../../../../../../hooks/useHash';
import { getTabVisibleMap } from '../../../utils';
import { promotionsSection, promotionsTabsList } from '../../innerSidebarTabs';
import Section from '../Section';
import Tab from '../Tab';

const Promotions = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => {
  const hash = useHash();
  const { tabsPermissionsManager } = usePermissions();
  const badges = useSelector((state) => state.sidebar.badges);

  const tabVisibleMap = useMemo(
    () => getTabVisibleMap(promotionsTabsList, tabsPermissionsManager),
    [tabsPermissionsManager]
  );

  const isSectionVisible = useMemo(
    () => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible),
    [tabVisibleMap]
  );

  const clickHandler = (e, id) => {
    clickHandlers[id]?.(e);
    setSelectedTabId?.(id);
  };

  if (!isSectionVisible) {
    return null;
  }

  return (
    <Section key={promotionsSection.id} label={<FormattedMessage id={promotionsSection.labelId} />}>
      {promotionsTabsList.map((tab) => {
        if (!tabVisibleMap[tab.id]) {
          return null;
        }
        return (
          <Tab
            id={tab.id}
            key={tab.id}
            icon={tab.icon}
            chip={tab.chip}
            label={<FormattedMessage id={tab.labelId} />}
            href={tab.hash}
            badge={badges[tab.id]}
            clickHandler={clickHandler}
            selected={hash === tab.hash || selectedTabId === tab.id}
          />
        );
      })}
    </Section>
  );
};

Promotions.propTypes = {
  selectedTabId: PropTypes.string,
  clickHandlers: PropTypes.object,
  setSelectedTabId: PropTypes.func,
  badges: PropTypes.object,
};
export default Promotions;
