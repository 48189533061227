import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Menu } from '@trustyou/ty-brew/mui';

import { usePermissions } from '../../../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../../../contexts/services/servicesContext';
import useHash from '../../../../../hooks/useHash';
import { setIsLoading as setAppIsLoading } from '../../../../../redux/slices/app';
import { navigateToMessagingTab } from '../../utils';
import MinibarItem from '../MinibarItem';
import { settingsTabs, settingsTabsList } from '../minibarTabs';
import DropdownItem from './DropdownItem';

const settingsSections = {
  id: 'mainSettings',
  icon: 'settings',
  labelId: 'settings',
};

const { postStay, live } = settingsTabs;

const DropdownSettings = ({ clickHandler, hash, selectedTabId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const _clickHandler = (e, id) => {
    // eslint-disable-next-line no-unused-expressions
    clickHandler?.(e, id);
    setAnchorEl(null);
  };
  return (
    <>
      <MinibarItem
        id={settingsSections.id}
        icon={settingsSections.icon}
        label={<FormattedMessage id={settingsSections.labelId} />}
        clickHandler={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          '&& .Mui-selected, && .Mui-selected:hover': {
            color: 'secondary.main',
          },
        }}
      >
        <DropdownItem
          id={postStay.id}
          icon={postStay.icon}
          href={postStay.hash}
          label={<FormattedMessage id={postStay.labelId} />}
          selected={hash === postStay.hash || selectedTabId === postStay.id}
          clickHandler={_clickHandler}
        />
        <DropdownItem
          id={live.id}
          icon={live.icon}
          href={live.hash}
          label={<FormattedMessage id={live.labelId} />}
          selected={hash === live.hash || selectedTabId === live.id}
          clickHandler={_clickHandler}
        />
      </Menu>
    </>
  );
};
DropdownSettings.propTypes = {
  clickHandler: PropTypes.func,
  hash: PropTypes.string,
  selectedTabId: PropTypes.string,
};

const SettingsItem = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => {
  const { tabsPermissionsManager } = usePermissions();
  const hash = useHash();
  const dispatch = useDispatch();
  const { messagingService } = useServices();
  const messagingSubdomain = useSelector((state) => state.sidebar.messagingSubdomain);

  const tabVisibleMap = useMemo(
    () =>
      settingsTabsList.reduce((map, tab) => {
        map[tab.id] = tabsPermissionsManager.isVisible(tab.id);
        return map;
      }, {}),
    [tabsPermissionsManager]
  );

  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    const dispatchLoading = (_isLoading) => {
      dispatch(setAppIsLoading(_isLoading));
    };
    return {
      [live.id]: (e) => {
        navigateToMessagingTab(e, 'settings', { messagingService, messagingSubdomain, dispatchLoading });
      },
    };
  }, [clickHandlers, dispatch, messagingService, messagingSubdomain]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
    // eslint-disable-next-line no-unused-expressions
    setSelectedTabId?.(id);
  };

  // item visibility
  const isItemVisible = useMemo(
    () => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible),
    [tabVisibleMap]
  );

  if (!isItemVisible) {
    return null;
  }

  if (tabVisibleMap[postStay.id] && tabVisibleMap[live.id]) {
    return <DropdownSettings clickHandler={clickHandler} hash={hash} selectedTabId={selectedTabId} />;
  }

  if (tabVisibleMap[live.id]) {
    return (
      <MinibarItem
        id={live.id}
        icon={live.icon}
        href={live.hash}
        selected={hash === live.hash || selectedTabId === live.id}
        label={<FormattedMessage id={live.labelId} />}
        clickHandler={clickHandler}
      />
    );
  }
  return (
    <MinibarItem
      id={postStay.id}
      icon={postStay.icon}
      href={postStay.hash}
      selected={hash === postStay.hash || selectedTabId === postStay.id}
      label={<FormattedMessage id={postStay.labelId} />}
      clickHandler={clickHandler}
    />
  );
};

SettingsItem.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  clickHandlers: PropTypes.shape({
    [postStay.id]: PropTypes.func,
    [live.id]: PropTypes.func,
  }),
};
export default SettingsItem;
