import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@trustyou/ty-brew/mui';

import PageLoading from '../../PageLoading';
import { useServices } from '../../../../contexts/services/servicesContext';
import { getCreateView } from '../service';
import { parseHashParams } from '../utils';

const Reviews = ({ require, location }) => {
  const { tyraService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get view from DB or set default one
      const view = await getCreateView(tyraService, {
        type: 'REVIEW_INBOX',
        pageId: 'reviews',
        parameters: {
          filter: {},
        },
        filter: null,
      });

      // get hashParams to preselect the filter (used in Dashboard > Response Rate)
      // location.search shouldn't be included in useEffect deps,
      // otherwise will rerender on every tab change.
      // reload tab when hash search params are set (??), location.search in useEffect deps.
      const hashParams = location.search.startsWith('??') ? parseHashParams(location.search.substring(2)) : {};

      require(['jquery', 'review-plugins'], ($) => {
        const content = $('#reviews-view');
        const params = {
          filterChanged(parametersFilter) {
            view.parameters.filter = parametersFilter;
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },
          uiChanged(newUi) {
            view.parameters.parameters = newUi;
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },

          dontShowAgainSourceConnection(sourceId) {
            if (view.parameters.disabledConnectionSources) {
              view.parameters.disabledConnectionSources.push(sourceId);
              this.disabledConnectionSources.push(sourceId);
            } else {
              view.parameters.disabledConnectionSources = [sourceId];
              this.disabledConnectionSources = [sourceId];
            }
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },

          showAgainSourceConnection(sourceId) {
            if (view.parameters.disabledConnectionSources) {
              const indexOfSource = view.parameters.disabledConnectionSources.indexOf(sourceId);
              if (indexOfSource !== -1) {
                view.parameters.disabledConnectionSources.splice(indexOfSource, 1);
                this.disabledConnectionSources = view.parameters.disabledConnectionSources;
              }
            }
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },

          onCloseOnSiteInboxNotification() {
            view.parameters.closedOnSiteInboxNotification = true;
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },

          resetOnSiteInboxNotification() {
            view.parameters.closedOnSiteInboxNotification = false;
            // save view in DB view
            view?.id && tyraService.updateView(view);
          },

          // Include hashParams state from the view.
          hashParams,
        };

        if (view.parameters.filter) {
          params.filter = view.parameters.filter;
        }
        if (view.parameters.parameters) {
          params.ui = view.parameters.parameters;
        }
        if (view.parameters.disabledConnectionSources) {
          params.disabledConnectionSources = view.parameters.disabledConnectionSources;
        }

        content.Inbox().init(params);
      });
    };
    render();
  }, [require, tyraService, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        margin: 0,
        padding: '20px 20px 0 20px',
      }}
      id='reviews-view'
      className='view REVIEW_INBOX'
    >
      <PageLoading />
    </Box>
  );
};

Reviews.propTypes = {
  require: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default React.memo(Reviews);
