import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@trustyou/ty-brew/mui';

import Inbox from './Sections/Inbox';
import Reports from './Sections/Reports';
import Promotions from './Sections/Promotions';
import Setup from './Sections/Setup';
import Admin from './Sections/Admin';

const Navigation = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => (
  <List
    disablePadding
    sx={{
      overflowY: 'auto',
      '&& .Mui-selected, && .Mui-selected:hover': {
        color: 'secondary.main',
      },
    }}
  >
    <Inbox clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
    <Reports clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
    <Promotions clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
    <Setup clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
    <Admin clickHandlers={clickHandlers} selectedTabId={selectedTabId} />
  </List>
);

Navigation.propTypes = {
  clickHandlers: PropTypes.object,
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
};

export default Navigation;
