import { usePermissions } from '../../../contexts/permissions/permissionsContext';
import useHash from '../../../hooks/useHash';
import { findTabByHash } from '../Sidebar/utils';

const ProtectedRedirect = ({ children }) => {
  const currentHash = useHash();
  const { tabsPermissionsManager } = usePermissions();
  const tab = findTabByHash(currentHash);
  const isPermissionsArrayExist = tabsPermissionsManager.permissionsWorker.session.permissions.length > 0;
  const isVisible = tabsPermissionsManager.isVisible(tab?.id);

  if (!isVisible && !!tab && isPermissionsArrayExist) {
    window.location.replace('');
  }

  return children;
};

export default ProtectedRedirect;
