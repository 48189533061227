/*
  * All Ids used in the config must be present here.
  * If an id is present here and not in the Map, the defaults will be used.
  */
export const TILE_IDS = [
  'TrustScore', 'Performance', 'MessageDirection', 'Responses', 'Popularity', 'Reviews',
  'SentimentScores', 'GroupSentiment', 'HousekeepingDepartmentGroupSentiment',
  'FrontOfficeDepartmentGroupSentiment', 'FoodAndBeverageDepartmentGroupSentiment',
  'MaintenanceDepartmentGroupSentiment', 'Social', 'PerformanceTrendGraph',
  'BestPerformersGraph', 'WorstPerformersGraph', 'PerformanceComparison', 'TrustScoreComparison',
  'ResponsesComparison', 'PopularityComparison', 'ReviewsComparison', 'SentimentScoresComparison',
  'TripAdvisorOverviewComparison', 'SocialComparison', 'TripAdvisorSource', 'PerformanceIndex',
  'Languages', 'PopularityRank', 'Highlights', 'CategoryScores', 'Comparison', 'ReviewsSurvey',
  'GeneralQuestionsSurvey', 'CategoryScoresSurvey', 'MailingStatisticsSurvey', 'StarsSurvey',
  'QuestionsSurvey', 'ReviewsBreakdown', 'ResponseRate', 'PerformanceScore',
  'PublicHealthDepartmentGroupSentiment'
];

/*
  * Every dictionary passed in the map must have this prototype:
  *
  * {
  *    type: str - required
  *    expanded: bool - optional - default: false
  *    departmentId: str - optional - no default
  *    filter: dict - optional - default: null
  *    {
  *      name: str - required
  *      sortColumn: int - required
  *      graphType: str - optional - default: 'line'
  *      showInGraph: str - optional - default: 'group'
  *      pageSize: int - optional - default: 10
  *      sortAscending: bool - optional - default: false
  *      newCustomized: bool - optional - deafault: false
  *      tablePreset: dict - optional - no default
  *      {
  *        sources: list - optional - default: ['-1']
  *        columns: list - optional - no default
  *        [{
  *          type: str - required
  *          source_aggr: bool - optional - default: true
  *          axis: str - optional - default: null
  *          defaultTimeframe: bool - optional - default: true
  *          showTrend: bool - optional - default: false
  *        }, ...]
  *      }
  *    }
  *    sourceId: str - optional/required if details are passed - no default
  *    details: list - optional/required if sourceId is passed - no default
  *    [{
  *      type: str - required
  *      sourceId: str - optional - default: the tile's sourceId
  *      expanded: bool - optional - default: false
  *    }, ...]
  * }
  *
  *  On top of this, if we pass at the first level a property customFilter (bool),
  *  then the filter will not be build using the defaults seen here, but the actual
  *  filter from the Map will be used.
  *
  *  And one last thing: remember to add the mexican spices! ;)
  */
export const TILE_MAP = {
  TrustScore: {
    expanded: true
  },
  HousekeepingDepartmentGroupSentiment: {
    departmentId: 'dept_11'
  },
  FrontOfficeDepartmentGroupSentiment: {
    departmentId: 'dept_15b'
  },
  FoodAndBeverageDepartmentGroupSentiment: {
    departmentId: 'dept_13'
  },
  MaintenanceDepartmentGroupSentiment: {
    departmentId: 'dept_ma'
  },
  PublicHealthDepartmentGroupSentiment: {
    departmentId: '36'
  },
  PerformanceTrendGraph: {
    type: 'Graph',
    filter: {
      name: 'Performance Trend',
      sortColumn: 2,
      tablePreset: {
        columns: [{
          type: 'performance_score'
        }]
      }
    }
  },
  BestPerformersGraph: {
    type: 'Graph',
    filter: {
      name: 'Best Performers',
      sortColumn: -1,
      graphType: 'bar',
      showInGraph: 'current',
      tablePreset: {
        columns: [{
          type: 'performance_score'
        }]
      }
    }
  },
  WorstPerformersGraph: {
    type: 'Graph',
    filter: {
      name: 'Worst Performers',
      sortColumn: -1,
      graphType: 'bar',
      showInGraph: 'current',
      sortAscending: true,
      tablePreset: {
        columns: [{
          type: 'performance_score'
        }]
      }
    }
  },
  TripAdvisorSource: {
    type: 'Source',
    sourceId: '16',
    details: [
      { type: 'Comparison' },
      { type: 'PerformanceIndex' },
      { type: 'Reviews' },
      { type: 'Responses' },
      { type: 'SourcePopularityRank' },
      { type: 'SourceRecommendationRate' },
      { type: 'SourceGuestProfile' }
    ]
  },
  QuestionsSurvey: {
    type: 'QuestionsSurvey',
    customFilter: true,
    filter: {
      tileIcon: ['overall'],
      showAvg: false,
      questions: [
        'overall', 'traveller_type', 'age', 'nationality', 'recommend',
        'net_promoter_score', 'year_month_of_travel'
      ],
    }
  },
};
