import React, { useContext, createContext } from 'react';
import { useSelector } from 'react-redux';

import config from '../../config';
import PermissionsWorker from '../../core/PermissionsManager/PermissionsWorker';
import SessionFilter from '../../core/PermissionsManager/SessionFilter';
import TabsPermissionsManager from '../../core/PermissionsManager/TabsPermissionsManager';
import ProductManager from '../../core/ProductManager/ProductManager';

const PermissionsContext = createContext();

function PermissionsProvider({ children }) {
  const session = useSelector(state => state.app.session);

  const permissionsWorker = new PermissionsWorker(session);
  const sessionFilter = new SessionFilter(session);
  const tabsPermissionsManager = new TabsPermissionsManager(session, config.vertical);
  const productManager = new ProductManager(session);

  const value = {
    permissionsWorker,
    sessionFilter,
    tabsPermissionsManager,
    productManager
  };
  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
}

const usePermissions = () => useContext(PermissionsContext);

export {
  PermissionsProvider,
  usePermissions
};
