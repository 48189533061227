import PermissionsWorker from '../PermissionsManager/PermissionsWorker';
import MessagingProduct from './Products/MessagingProduct';

export const PRODUCTS_NAMES = {
  MESSAGING: 'MessagingProduct'
};

/**
 * Add here any new products you want to include.
 * Please add the new concrete classes for products in ./Products/*, and import then here.
 */
const AVAILABLE_PRODUCTS = {
  MessagingProduct
};

export default class ProductManager {
  constructor(session) {
    this.permissionsWorker = new PermissionsWorker(session);
  }

  isProductEnabled(productName) {
    const SelectedProductClass = AVAILABLE_PRODUCTS[productName];
    const product = new SelectedProductClass(this.permissionsWorker);

    return product.isProductEnabled();
  }

  getProductPermissionsFor(productName) {
    const SelectedProductClass = AVAILABLE_PRODUCTS[productName];
    const product = new SelectedProductClass(this.permissionsWorker);

    return product.getProductPermissions();
  }
}
