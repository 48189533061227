import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@trustyou/ty-brew';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@trustyou/ty-brew/mui';

const MenuListItem = ({ id, clickHandler, icon, label, ...props }) => {
  if (props.href) {
    props.component = 'a';
  }
  const _clickHandler = (e) => {
    // eslint-disable-next-line no-unused-expressions
    clickHandler?.(e, id);
  };
  return (
    <MenuItem
      id={id}
      color='primary'
      onClick={_clickHandler}
      sx={{
        color: 'text.secondary',
        '& .MuiListItemIcon-root': {
          minWidth: '24px',
          color: 'inherit',
        },
      }}
      {...props}
    >
      {icon && (
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography variant='body2'>{label}</Typography>
      </ListItemText>
    </MenuItem>
  );
};

MenuListItem.displayName = 'MenuListItem';
MenuListItem.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  clickHandler: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.node,
};

export default MenuListItem;
