import React, { useEffect, useState } from 'react';
import { Box, ClickAwayListener } from '@trustyou/ty-brew/mui';
import { useIntl, FormattedMessage } from 'react-intl';
import TopbarMenuItem, { TopbarMenuItemWrapper } from '../TopbarMenuItem';

const UserSwitcher = () => {
  const intl = useIntl();
  const [retries, setRetries] = useState(0);
  const maxRetries = 10;

  const initUserSwitcher = () => {
    try {
      if (!document.getElementById('login_as')) {
        window
          .$('#user-switcher')
          .find('#login-as-container')
          .UserLoginAs()
          .init({
            loginAs: intl.formatMessage({ id: 'header_login_as' }),
            placeholderUserSearch: intl.formatMessage({ id: 'placeholderUserSearch' }),
            placeholderNoUsers: intl.formatMessage({ id: 'placeholderNoUsers' }),
            success_callback: '/___tyra___/static/home.html',
          });
      }
    } catch (error) {
      if (retries < maxRetries) {
        setTimeout(initUserSwitcher, 1000);
      }
      setRetries((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    setTimeout(initUserSwitcher, 1000);
  });

  const handleClick = () => {
    document.getElementsByClassName('dropdown-user-switcher')[0].classList.toggle('open');
    document.getElementById('user-search-input').focus();
  };

  const handleClickAway = () => {
    document.getElementsByClassName('dropdown-user-switcher')[0].classList.remove('open');
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <TopbarMenuItemWrapper id='user-switcher' onClick={handleClick}>
          <TopbarMenuItem icon='user'>
            <FormattedMessage id='header_login_as' />
          </TopbarMenuItem>
          <div className='trustyou-ui dropdown dropdown-user-switcher'>
            <Box
              sx={{
                position: 'fixed !important',
                top: '60px !important',
                right: '16px !important',
              }}
              className='dropdown-menu l-right'
              onClick={(e) => e.stopPropagation()}
            >
              <div id='login-as-container' />
            </Box>
          </div>
        </TopbarMenuItemWrapper>
      </ClickAwayListener>
    </>
  );
};

export default UserSwitcher;
