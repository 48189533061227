import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
  messagingSubdomain: null,
  badges: {},
  activeHash: null
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: { ...defaultState },
  reducers: {
    setActiveHash(state, action) {
      state.activeHash = action.payload;
    },
    setMessagingSubdomain(state, action) {
      state.messagingSubdomain = action.payload;
    },
    setBadges(state, action) {
      state.badges = {
        ...state.badges,
        ...action.payload
      };
    },
  }
});

export const {
  setMessagingSubdomain,
  setBadges,
  setActiveHash
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
