import React from 'react';
import { Box } from '@trustyou/ty-brew/mui';

import Exports from './Exports';
import MarketingNotification from './MarketingNotification';
import UserSwitcher from './UserSwitcher';
import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import PERMISSIONS from '../../../../models/Permissions/Permissions';

const TopbarMenu = () => {
  const { permissionsWorker } = usePermissions();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {!permissionsWorker.hasPermission([PERMISSIONS.MANAGE_USERS]) && <Exports />}
      {!permissionsWorker.hasPermission([PERMISSIONS.MANAGE_USERS]) && <MarketingNotification />}
      {permissionsWorker.hasPermission([PERMISSIONS.MANAGE_USERS]) && <UserSwitcher />}
    </Box>
  );
};

export default TopbarMenu;
