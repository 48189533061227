import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { Stack } from '@trustyou/ty-brew/mui';

const FormContainer = forwardRef(({ isError = false, ...other }, ref) => (
  <Stack
    ref={ref}
    component='form'
    sx={[
      { width: '350px' },
      isError && {
        '@keyframes shake': {
          '0%': {
            transform: 'translateX(0)',
          },
          '20%': {
            transform: 'translateX(-8%)',
          },
          '40%': {
            transform: 'translateX(8%)',
          },
          '60%': {
            transform: 'translateX(-8%)',
          },
          '80%': {
            transform: 'translateX(8%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
        animation: 'shake 0.5s',
      },
    ]}
    {...other}
  />
));

FormContainer.displayName = 'FormContainer';
FormContainer.propTypes = {
  isError: PropTypes.bool,
};

export default FormContainer;
