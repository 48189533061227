import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@trustyou/ty-brew/mui';

import PageLoading from '../../PageLoading';

const Goals = ({ require }) => {
  useEffect(() => {
    const render = async () => {
      // tyra-5 views for goals is managed inside assignments-app
      // both required modules are implemented in tyra-5
      require(['TYAssignments', 'tyra-5/static/js/apps_bootstrap/assignments'], (
        TYAssignments,
        AssignmentsBootstrap
      ) => {
        const content = document.getElementById('goals-view');
        AssignmentsBootstrap.then(() => {
          TYAssignments.app.startGoals(content);
        });
      });
    };
    render();
  }, [require]);

  return (
    <Box
      sx={{
        width: '1000px',
        margin: 0,
        padding: '20px 20px 0 20px',
      }}
      id='goals-view'
      className='view GOALS_VIEW'
    >
      <PageLoading />
    </Box>
  );
};

Goals.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Goals);
