import { getDefaultTiles } from '../tiles';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import config from '../../../../config';

export const getDefaultView = ({ permissionsWorker }) => {
  const canViewStatistics = permissionsWorker.hasPermission([
    PERMISSIONS.PORTFOLIO_STATISTICS
  ]);
  const canViewMessagingStatistics = permissionsWorker.hasPermission([
    PERMISSIONS.PORTFOLIO_MESSAGING_STATISTICS
  ]);
  const defaultOverviewTablePreset = {
    sources: ['-1'],
    columns: [
      {
        type: 'performance_score',
        agg: 'timerange_30d'
      },
      {
        type: 'score',
        agg: 'value_today'
      },
      {
        type: 'reviews',
        agg: 'trend_30d',
        defaultTimeframe: true
      },
      {
        type: 'response_rate',
        agg: 'timerange_30d',
        defaultTimeframe: true
      },
      {
        type: 'popularity',
        agg: 'value_today'
      }
    ]
  };
  const defaultMessagingOverviewTablePreset = {
    sources: ['-1'],
    columns: [
      {
        type: 'inbound_messages',
        agg: 'trend_30d'
      },
      {
        type: 'outbound_messages',
        agg: 'trend_30d'
      },
      {
        type: 'inbound_outbound_ratio',
        agg: 'trend_30d'
      }
    ]
  };
  let overviewTablePreset = {};
  let activeTab = 0;
  if (canViewStatistics) {
    activeTab = 1;
    overviewTablePreset = defaultOverviewTablePreset;
  } else if (canViewMessagingStatistics) {
    activeTab = 0;
    overviewTablePreset = defaultMessagingOverviewTablePreset;
  }
  return {
    type: 'PORTFOLIO_MODULES',
    pageId: 'portfolio',
    parameters: {
      tiles: [
        {
          type: 'OverviewTabContainer',
          filter: {
            activeTab,
            folder: {},
            tablePreset: overviewTablePreset,
            averagesTotals: 'averages',
            name: '',
            pageSize: 20
          }
        },
        ...getDefaultTiles(config.defaultTiles.portfolio, {
          generic: permissionsWorker.hasPermission([
            PERMISSIONS.PORTFOLIO_TILES
          ]),
          special: [
            {
              permission: permissionsWorker.hasPermission([
                PERMISSIONS.DASHBOARD_MESSAGING_DIRECTION_TILE
              ]),
              tiles: ['MessageDirection']
            }
          ]
        })
      ]
    }
  };
};
