import React from 'react';
import { Stack, Typography } from '@trustyou/ty-brew/mui';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { faCheckCircle } from '@trustyou/fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CXPPasswordRequirementBox = ({ text, color }) => (
  <Stack direction='row' alignItems='center'>
    <Icon icon={faCheckCircle} color={color} size='sm' />
    <Typography variant='body2' color='text.hint' sx={{ marginLeft: 0.8 }}>
      {text}
    </Typography>
  </Stack>
);
CXPPasswordRequirementBox.propTypes = {
  text: PropTypes.node,
  color: PropTypes.string,
};

const CXPPasswordRequirementsList = ({ minCharIconColor, oneDigitIconColor, oneLetterIconColor }) => (
  <Stack sx={{ width: '100%', justifyContent: 'flex-start' }}>
    <CXPPasswordRequirementBox
      text={<FormattedMessage id='cxp_eight_char_long' defaultMessage='At least 8 characters' />}
      color={minCharIconColor}
    />
    <CXPPasswordRequirementBox
      text={<FormattedMessage id='cxp_one_digit' defaultMessage='One number (0-9)' />}
      color={oneDigitIconColor}
    />
    <CXPPasswordRequirementBox
      text={<FormattedMessage id='cxp_one_uppercase_letter' defaultMessage='One uppercase letter' />}
      color={oneLetterIconColor}
    />
  </Stack>
);

const CXPConfirmPasswordRequirementsList = ({ matchingPassIconColor }) => (
  <Stack sx={{ width: '100%', justifyContent: 'flex-start' }}>
    <CXPPasswordRequirementBox
      text={<FormattedMessage id='cxp_confirm_retype' defaultMessage='Match password' />}
      color={matchingPassIconColor}
    />
  </Stack>
);

export { CXPPasswordRequirementsList, CXPConfirmPasswordRequirementsList };

CXPPasswordRequirementsList.propTypes = {
  minCharIconColor: PropTypes.string,
  oneDigitIconColor: PropTypes.string,
  oneLetterIconColor: PropTypes.string,
};

CXPConfirmPasswordRequirementsList.propTypes = {
  matchingPassIconColor: PropTypes.string,
};
