import { useIntl } from 'react-intl';
import { singular } from 'pluralize';
import { translate } from '@trustyou/messaging-translator';
import { showDesktopNotification } from '@trustyou/brio';
import MessagingNotifications from '@trustyou/cerebro';
import idpClientJs from '@trustyou/idp_client_js';

import { useIdpService } from '../../../../contexts/services/idpContext';
import useHttpStates from '../../../../hooks/useHttpStates';
import config from '../../../../config';

const camelCase = (myString) =>
  myString.replace(/(-|_)([a-z])/g, (g) => g[1].toUpperCase());

const MessagingNotification = () => {
  const intl = useIntl();

  const idpService = useIdpService();
  const [idpToken, idpLoading, idpTokenError] = useHttpStates(
    idpService.getIdpToken.bind(idpService),
    null,
    null
  );

  if (idpToken && !idpLoading && !idpTokenError) {
    const userId = idpClientJs.getUserIdFromIdpToken(idpToken);
    const messagingJwt = idpClientJs.getMessagingJwtFromIdpToken(idpToken);

    const subscriber = new MessagingNotifications({
      url: `${config.urls.messagingWebSocket}/websocket`,
      tokenProvider: () => messagingJwt
    });

    // Subscribe to the user notification
    subscriber.subscribeToUserNotifications(userId, async ({ payload }) => {
      const { data, included } = payload;
      const messageId = data.id;
      // const conversationId = data.relationships.conversation.data.id;

      // This is required base on what messaging implemented, we get type in plural,
      // but i18n is in singular
      const type = camelCase(singular(data.type));

      const body = data.attributes.notification;
      const url = `${config.urls.messaging}${data.links.path}`;
      let title = '';
      if (included) {
        const author = included[0].attributes.display_name;
        title = await translate(intl.locale, `desktopAlert.${type}.title`, {
          author
        });
      } else {
        title = await translate(
          intl.locale,
          `desktopAlert.${type}.titleNoAuthor`
        );
      }

      await showDesktopNotification({
        title,
        options: { body, messageId },
        callback: () => {
          window.location.replace(url);
        }
      });
    });
  }

  return null;
};

export default MessagingNotification;
