import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemText, ListItemIcon, Skeleton } from '@trustyou/ty-brew/mui';
import { ListSubheader } from '@mui/material';

const SidebarSkeleton = ({ businessItemsCount, globalItemsCount, innerSidebarSections }) => (
  <Box
    sx={{
      width: '260px',
      height: '100%',
      display: 'flex',
    }}
  >
    <Box // Minibar
      sx={{
        width: '60px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        backgroundColor: 'secondary.main',
        paddingBottom: '20px',
      }}
    >
      <List>
        <ListItemIcon key='ty-logo' sx={{ padding: '14px', minWidth: '32px' }}>
          <img src='https://cdn.trustyou.com/apps/commons/public/img/ty_logo_icon_512x512.svg' width={32} height={32} />
        </ListItemIcon>
        {[...Array(businessItemsCount).keys()].map((i) => (
          <ListItem key={`business-${i}`} sx={{ padding: '18px' }}>
            <Skeleton variant='circular' sx={{ backgroundColor: 'white' }} width={24} height={24} />
          </ListItem>
        ))}
      </List>
      <List>
        {[...Array(globalItemsCount).keys()].map((i) => (
          <ListItem key={`global-${i}`} sx={{ padding: '18px' }}>
            <Skeleton variant='circular' sx={{ backgroundColor: 'white' }} width={24} height={24} />
          </ListItem>
        ))}
      </List>
    </Box>
    <Box // Inner Sidebar
      sx={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        backgroundColor: 'background.paper',
      }}
    >
      <List disablePadding>
        <ListItem
          key='userItem'
          sx={{
            height: '60px',
            borderBottom: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <ListItemIcon>
            <Skeleton variant='circular' width={36} height={36} />
          </ListItemIcon>
          <ListItemText disableTypography primary={<Skeleton />} secondary={<Skeleton height={16} />} />
        </ListItem>
      </List>
      <List disablePadding>
        {innerSidebarSections.map((itemsCount, sectionIdx) => (
          <Fragment key={sectionIdx}>
            <ListSubheader
              disableSticky
              key={`header-${sectionIdx}`}
              sx={{
                paddingY: 1,
              }}
            >
              <Skeleton width={70} height={20} />
            </ListSubheader>
            {[...Array(itemsCount).keys()].map((itemIdx) => (
              <ListItem dense key={`${sectionIdx}-${itemIdx}`}>
                <ListItemIcon
                  sx={{
                    minWidth: '28px',
                  }}
                >
                  <Skeleton variant='circular' width={20} height={20} />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Skeleton width={120} />} />
              </ListItem>
            ))}
          </Fragment>
        ))}
      </List>
    </Box>
  </Box>
);

SidebarSkeleton.propTypes = {
  businessItemsCount: PropTypes.number,
  globalItemsCount: PropTypes.number,
  innerSidebarSections: PropTypes.array,
};
SidebarSkeleton.defaultProps = {
  businessItemsCount: 2,
  globalItemsCount: 2,
  innerSidebarSections: [2, 4, 1, 3],
};
SidebarSkeleton.displayName = 'SidebarSkeleton';

export default SidebarSkeleton;
