import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, ListItemButton, alpha } from '@trustyou/ty-brew/mui';
import { Icon } from '@trustyou/ty-brew';
import { useIntl } from 'react-intl';

const MinibarItem = ({ id, selected, selectable = true, arrow = true, clickHandler, icon, label, ...props }) => {
  const intl = useIntl();
  if (props.href) {
    props.component = 'a';
  }
  const _selectable = selected ? false : selectable;
  const _clickHandler = (e) => {
    if (!_selectable) {
      e.preventDefault();
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    clickHandler?.(e, id);
  };

  const component = (
    <ListItemButton
      sx={(theme) => ({
        padding: '18px',
        color: alpha(theme.palette.secondary.contrastText, 0.8),
        '&:hover': {
          color: 'secondary.contrastText',
        },
        ...(selected && { color: 'secondary.contrastText' }),
      })}
      key={id}
      selected={selected}
      onClick={_clickHandler}
      {...props}
    >
      <Icon
        style={{
          fontSize: '21px',
          padding: '1.5px',
        }}
        icon={icon}
      />
      {selected && arrow && (
        <Box
          component='span'
          sx={{
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              right: '0',
              zIndex: '1',
              opacity: '1',
              transition: 'border 0.2s ease-out',
              transform: 'translateY(-50%)',
              borderTop: '7.5px solid transparent',
              borderBottom: '7.5px solid transparent',
              borderRightWidth: '8px',
              borderRightStyle: 'solid',
            },
          }}
        />
      )}
    </ListItemButton>
  );

  if (label) {
    return (
      <Tooltip
        key={`${id}-tooltip`}
        title={label}
        aria-label={intl.formatMessage({ id: label.props.id })}
        placement='right'
        enterDelay={500}
        arrow
      >
        {component}
      </Tooltip>
    );
  }
  return component;
};

MinibarItem.displayName = 'MinibarItem';
MinibarItem.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  clickHandler: PropTypes.func,
  icon: PropTypes.string.isRequired,
  label: PropTypes.node,
  selected: PropTypes.bool,
  arrow: PropTypes.bool,
  selectable: PropTypes.bool,
};

export default MinibarItem;
