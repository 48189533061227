import React from 'react';
import { Stack, Typography } from '@trustyou/ty-brew/mui';
import { FormattedMessage } from 'react-intl';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { faCheckCircle } from '@trustyou/fortawesome/pro-solid-svg-icons';
import SubmitButton from '../../../Login/Buttons/SubmitButton';

const CXPSuccess = () => (
  <Stack sx={{ alignItems: 'center' }}>
    <Stack sx={{ flexDirection: 'row' }}>
      <Icon icon={faCheckCircle} sx={{ width: '30px', height: '30px' }} color='green' />
      <Typography variant='h6' color='text.primary' sx={{ marginLeft: 1 }}>
        <FormattedMessage id='cxp_success_title' defaultMessage='Your account is now ready' />
      </Typography>
    </Stack>
    <Typography variant='body1' color='text.secondary' sx={{ marginY: 1 }}>
      <FormattedMessage
        id='cxp_success_description'
        defaultMessage='Start exploring new features on Customer Experience Platform (CXP)'
      />
    </Typography>
    <SubmitButton sx={{ minWidth: '150px', marginTop: 5 }} onClick={() => window.open('https://cxp.trustyou.com/')}>
      <FormattedMessage id='cxp_login_now' defaultMessage='Login now' />
    </SubmitButton>
  </Stack>
);

export default CXPSuccess;
