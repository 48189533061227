import React from 'react';
import PropTypes from 'prop-types';
import { Card, Box, Tabs, Tab, Typography } from '@mui/material';
import CreditsOnboarding from './CreditsOnboardingForm';
// import HistoryDataGrid from './HistoryDataGrid';

function AccessibleTabPanel({ children, value, index, ...other }) {
  return (
    <Box role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && children}
    </Box>
  );
}

AccessibleTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function ResponseAi() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card sx={{ margin: 3, padding: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Typography variant='h6' component='h1'>
        ResponseAI Onboarding
      </Typography>
      <Tabs value={value} onChange={handleChange} aria-label='ResponseAI Onboarding tabs'>
        <Tab label='Add credits' {...a11yProps(0)} />
        {/* TODO: revert temporary hiding (SUR-3921) */}
        {/* <Tab label='History' {...a11yProps(1)} /> */}
      </Tabs>
      <AccessibleTabPanel value={value} index={0}>
        <CreditsOnboarding />
      </AccessibleTabPanel>
      {/* TODO: revert temporary hiding (SUR-3921) */}
      {/* <AccessibleTabPanel value={value} index={1}>
        <HistoryDataGrid />
      </AccessibleTabPanel> */}
    </Card>
  );
}
