import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import Stack from '@trustyou/ty-brew/mui/Stack';
import Button from '@trustyou/ty-brew/mui/Button';

import { MarketingSection, MarketingItemsGrid } from './MarketingCategory';
// import MarketingForm from './MarketingForm';

import LOAD_STATUS from '../../../../hooks/const/LoadStatusConstants';
import {
  MARKETO_ID,
  MARKETO_LOCALE_CODES,
  MARKETO_SCRIPT_ID as scriptId,
  MARKETO_DEFAULT_SCRIPT_URL as defaultScriptUrl,
} from './const/MarketoConstants';

import useLoadScript from '../../../../hooks/useLoadScript';
import MarketoFormWrapper from './form/MarketoFormWrapper';
import { getGenericCategories } from './getUpgradeCategories';

const UpgradeGenericContainer = ({ upgradeUrls = {}, marketoScriptUrl: scriptUrl }) => {
  const intl = useIntl();
  const { locale } = intl;
  const allCategories = getGenericCategories(intl, upgradeUrls);

  const marketoScriptStatus = useLoadScript({ scriptUrl, scriptId, defaultScriptUrl });
  const marketoFormWrapper = useMemo(() => new MarketoFormWrapper(scriptUrl, defaultScriptUrl, MARKETO_ID), [
    scriptUrl,
  ]);
  const marketoLocaleCode = useMemo(() => MARKETO_LOCALE_CODES?.[locale] ?? MARKETO_LOCALE_CODES.en, [locale]);
  // const marketoFormId = `mktoForm_${marketoLocaleCode}`;

  useMemo(() => marketoScriptStatus === LOAD_STATUS.COMPLETED && marketoFormWrapper.loadForm(marketoLocaleCode), [
    marketoScriptStatus,
    marketoFormWrapper,
    marketoLocaleCode,
  ]);
  return (
    <Stack alignItems='center' spacing={7} paddingY={7}>
      {allCategories.map(({ id, title, description, link, items }) => (
        <MarketingSection key={id} {...{ id, title, description }}>
          <MarketingItemsGrid aria-label={`${title} grid`} {...{ items }} />
          <Stack alignItems='center'>
            <Button
              variant='contained'
              size='large'
              color='secondary'
              data-testid={`${id}_learn_more_btn`}
              href={link}
              target='_blank'
            >
              <FormattedMessage id='learn_more' defaultMessage='@Learn more'/>
            </Button>
          </Stack>
        </MarketingSection>
      ))}
      {/* <MarketingForm formId={marketoFormId} /> */}
    </Stack>
  );
};

UpgradeGenericContainer.propTypes = {
  /**
   * Urls related to static content per categoy for upgrade generic page
   */
  upgradeUrls: PropTypes.shape({
    negativeFeedbackPreventionUrl: PropTypes.string,
    guestSatisfactionImprovementUrl: PropTypes.string,
    winMoreBookingsUrl: PropTypes.string,
  }),
  /**
   * Script url to load Marketo
   */
  marketoScriptUrl: PropTypes.string,
};

export default UpgradeGenericContainer;
