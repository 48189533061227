import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { usePermissions } from '../../../../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../../../../contexts/services/servicesContext';
import useHash from '../../../../../../hooks/useHash';
import { setIsLoading as setAppIsLoading } from '../../../../../../redux/slices/app';
import { getTabVisibleMap, navigateToMessagingTab } from '../../../utils';
import { setupSection, setupTabs, setupTabsList } from '../../innerSidebarTabs';
import Section from '../Section';
import Tab from '../Tab';

const { outreaches, distribution, integrations, customers } = setupTabs;

const Setup = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => {
  const hash = useHash();
  const dispatch = useDispatch();
  const { tabsPermissionsManager } = usePermissions();
  const { messagingService } = useServices();
  const messagingSubdomain = useSelector((state) => state.sidebar.messagingSubdomain);
  const badges = useSelector((state) => state.sidebar.badges);

  const tabVisibleMap = useMemo(
    () => getTabVisibleMap(setupTabsList, tabsPermissionsManager),
    [tabsPermissionsManager]
  );

  const isSectionVisible = useMemo(
    () => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible),
    [tabVisibleMap]
  );

  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    const dispatchLoading = (_isLoading) => {
      dispatch(setAppIsLoading(_isLoading));
    };
    return {
      [outreaches.id]: (e) => {
        navigateToMessagingTab(e, 'outreach', { messagingService, messagingSubdomain, dispatchLoading });
      },
      [distribution.id]: (e) => {
        navigateToMessagingTab(e, 'distributions', { messagingService, messagingSubdomain, dispatchLoading });
      },
      [integrations.id]: (e) => {
        navigateToMessagingTab(e, 'integrations', { messagingService, messagingSubdomain, dispatchLoading });
      },
      [customers.id]: (e) => {
        navigateToMessagingTab(e, 'customers/segments', { messagingService, messagingSubdomain, dispatchLoading });
      },
    };
  }, [clickHandlers, messagingService, messagingSubdomain, dispatch]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
    // eslint-disable-next-line no-unused-expressions
    setSelectedTabId?.(id);
  };

  if (!isSectionVisible) {
    return null;
  }

  return (
    <Section key={setupSection.id} label={<FormattedMessage id={setupSection.labelId} />}>
      {setupTabsList.map((tab) => {
        if (!tabVisibleMap[tab.id]) {
          return null;
        }
        return (
          <Tab
            id={tab.id}
            key={tab.id}
            icon={tab.icon}
            chip={tab.chip}
            label={<FormattedMessage id={tab.labelId} />}
            href={tab.hash}
            badge={badges[tab.id]}
            clickHandler={clickHandler}
            selected={hash === tab.hash || selectedTabId === tab.id}
          />
        );
      })}
    </Section>
  );
};

Setup.propTypes = {
  selectedTabId: PropTypes.string,
  clickHandlers: PropTypes.object,
  setSelectedTabId: PropTypes.func,
};
export default Setup;
