import { LANG_CODES } from './MarketingConstants';

const MARKETO_LOCALE_CODES = {
  [LANG_CODES.en]: 2865,
  [LANG_CODES.de]: 2878,
  [LANG_CODES.es]: 2887,
  [LANG_CODES.fr]: 2877,
  [LANG_CODES.it]: 2880,
  [LANG_CODES.ja]: 2885,
  [LANG_CODES.pt]: 2886,
  [LANG_CODES.zh]: 2876,
};

const MARKETO_ID = '580-OJF-909';
const MARKETO_SCRIPT_ID = 'ty_marketo_id';
const MARKETO_DEFAULT_SCRIPT_URL = 'https://info.trustyou.com/js/forms2/js/forms2.min.js';

export {
  MARKETO_LOCALE_CODES,
  MARKETO_ID,
  MARKETO_SCRIPT_ID,
  MARKETO_DEFAULT_SCRIPT_URL,
};
