function getColumnsList(columns) {
  const columnsList = [];
  columns.forEach((column) => {
    columnsList.push({
      type: column.type,
      source_aggr: column.source_aggr || true,
      axis: column.axis || null,
      defaultTimeframe: column.defaultTimeframe || true,
      showTrend: column.showTread || false
    });
  });

  return columnsList;
}

function getTablePresetDict(tablePreset) {
  const tablePresetDict = {
    sources: tablePreset.sources || ['-1']
  };

  const { columns } = tablePreset;
  if (columns) {
    const columnsList = getColumnsList(columns);
    tablePresetDict.columns = columnsList;
  }

  return tablePresetDict;
}

function getFilterDict(filter) {
  const DEFAULT_PAGE_SIZE = 10;

  const filterDict = {
    name: filter.name,
    sortColumn: filter.sortColumn,
    graphType: filter.graphType || 'line',
    showInGraph: filter.showInGraph || 'group',
    pageSize: filter.pageSize || DEFAULT_PAGE_SIZE,
    sortAscending: filter.sortAscending || false,
    newCustomized: filter.newCustomized || false
  };

  const { tablePreset } = filter;
  if (tablePreset) {
    const tablePresetDict = getTablePresetDict(tablePreset);
    filterDict.tablePreset = tablePresetDict;
  }

  return filterDict;
}

function getDetailsList(sourceId, details) {
  const detailsList = [];

  details.forEach((item) => {
    detailsList.push({
      type: item.type,
      sourceId: item.sourceId || sourceId,
      expanded: item.expanded || false
    });
  });

  return detailsList;
}

/*
 * This function creates a dictionary with information for tile creation.
 * It expects a tileInfo dictionary. Check the tile_types for a prototype
 * of the tileInfo.
 */
export function getTileDict(tileInfo) {
  const tileDict = {
    type: tileInfo.type,
    expanded: tileInfo.expanded || false,
    filter: null
  };

  const { departmentId } = tileInfo;
  if (departmentId) {
    tileDict.departmentId = departmentId;
  }

  const { customFilter, filter } = tileInfo;
  if (filter && customFilter) {
    tileDict.filter = filter;
  } else if (filter) {
    const filterDict = getFilterDict(filter);
    tileDict.filter = filterDict;
  }

  const { sourceId, details } = tileInfo;
  if (sourceId && details) {
    tileDict.sourceId = sourceId;

    const detailsList = getDetailsList(sourceId, details);
    tileDict.details = detailsList;
  }

  return tileDict;
}
