import React, { useContext, createContext } from 'react';
import config from '../../config';

import IdpService from '../../services/IdpService';

const IdpServiceContext = createContext();

const IdpServiceProvider = ({ children }) => {
  const idpService = new IdpService({
    idpClientId: config.idp.clientId,
    idpSecret: config.idp.secret,
    idpUrl: config.idp.idpUrl,
  });
  return (
    <IdpServiceContext.Provider value={idpService}>
      {children}
    </IdpServiceContext.Provider>
  );
};

const useIdpService = () => useContext(IdpServiceContext);

export {
  IdpServiceProvider,
  useIdpService,
};
