import BaseService from './BaseService';

export default class HotelsService extends BaseService {
  constructor(hostUrl) {
    super();
    this.hostUrl = (hostUrl || '').replace(/\/$/, '');
    this.baseUrl = `${this.hostUrl}/hotels`;
  }

  async getSources() {
    const fetchResponse = await BaseService.fetchOk(`${this.baseUrl}/sources`);
    const response = await fetchResponse.json();
    return response?.response;
  }
}
