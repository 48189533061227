/* eslint-disable no-undef */
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import TabsLegacy from './index';
import ProtectedRedirect from './ProtectedRedirect';

const RouterLegacy = () => (
  <Routes>
    <Route
      path='*'
      element={
        <ProtectedRedirect>
          <TabsLegacy require={requirejs} />
        </ProtectedRedirect>
      }
    />
  </Routes>
);

export default RouterLegacy;
