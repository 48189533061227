import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import idpClientJs from '@trustyou/idp_client_js';
import { List, Menu } from '@trustyou/ty-brew/mui';

import config from '../../../../../config';
import VERTICALS from '../../../../../models/Verticals';
import { getAvatarColorBasedOnId } from '../../utils';
import { profileTabs } from '../innerSidebarTabs';
import MenuListItem from './MenuListItem';
import UserItem from './UserItem';

const { logout } = profileTabs;

const Profile = ({ clickHandlers = {} }) => {
  const session = useSelector((state) => state.app.session);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // click handlers
  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    return {
      [logout.id]: () => {
        idpClientJs.logout();
        // the hack to cleanup sessions
        // users-app Logout endpoint does not kill the session or does not invalidate all sso tokens,
        // so we need to navigate first to another vertical and then to current one to force cookie cleanup
        const currentVerticalLogoutUrl =
          config.vertical === VERTICALS.HOTELS ? config.urls.logoutHotel : config.urls.logoutRest;
        const anotherVerticalLogoutUrl =
          config.vertical === VERTICALS.HOTELS ? config.urls.logoutRest : config.urls.logoutHotel;
        const callback = encodeURI(`${currentVerticalLogoutUrl}?callback=/tyra-5/static/home.html`);
        window.location = `${anotherVerticalLogoutUrl}?callback=${callback}`;
      },
    };
  }, [clickHandlers]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
  };

  return (
    <>
      <List disablePadding>
        <UserItem
          open={open}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          avatarBgColor={getAvatarColorBasedOnId(session.user?.id)}
          name={session.properties.displayname}
          clusterName={session.defaultCluster.name}
        />
      </List>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          sx: { minWidth: '200px' },
        }}
      >
        <MenuListItem
          id={logout.id}
          label={<FormattedMessage id={logout.labelId} />}
          icon={logout.icon}
          clickHandler={clickHandler}
        />
      </Menu>
    </>
  );
};

Profile.propTypes = {
  clickHandlers: PropTypes.object,
};

export default Profile;
