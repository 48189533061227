/* eslint-disable object-shorthand, prefer-destructuring, camelcase */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@trustyou/ty-brew/mui';

import PageLoading from '../../PageLoading';
import { useServices } from '../../../../contexts/services/servicesContext';
import { usePermissions } from '../../../../contexts/permissions/permissionsContext';
import { getCreateView } from '../service';
import PERMISSIONS from '../../../../models/Permissions/Permissions';
import { getDefaultTiles } from '../tiles';
import innerSidebarTabs from '../../Sidebar/InnerSidebar/innerSidebarTabs';
import envConfig from '../../../../config';

const Comparison = ({ require }) => {
  const session = useSelector((state) => state.app.session);

  const { permissionsWorker } = usePermissions();
  const { tyraService, clusterService, ssoService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get custom cluster id mappings
      const userClusterMappingsResponse = await clusterService.getUserClusterMapping({ userId: session.user.id });
      const clusterMappings = userClusterMappingsResponse?.mapping_list || [];

      // get view from DB or set default one
      const defaultView = {
        type: 'COMPARISON_MODULES',
        pageId: 'comparison',
        parameters: {
          tiles: [
            {
              type: 'OverviewTabContainer',
              filter: {
                activeTab: 1,
                folder: {},
                tablePreset: {
                  sources: ['-1'],
                  columns: [
                    {
                      type: 'performance_score',
                      agg: 'timerange_30d',
                      compIndex: 'checked',
                    },
                    {
                      type: 'score',
                      agg: 'value_today',
                      compIndex: 'checked',
                    },
                    {
                      type: 'reviews',
                      agg: 'trend_30d',
                      compIndex: 'checked',
                      defaultTimeframe: true,
                    },
                    {
                      type: 'response_rate',
                      agg: 'timerange_30d',
                      compIndex: 'checked',
                      defaultTimeframe: true,
                    },
                    {
                      type: 'popularity',
                      agg: 'value_today',
                      compIndex: 'checked',
                    },
                  ],
                },
                averagesTotals: 'averages',
                name: '',
                pageSize: 20,
              },
            },
            ...getDefaultTiles(envConfig.defaultTiles.comparison, {
              generic: true,
            }),
          ],
        },
      };
      const view = await getCreateView(tyraService, defaultView);
      require(['portfolio-app', 'dashboard-agg'], () => {
        require([
          'jquery',
          'tyra-5/static/js/config',
          'ty-i18n!analytics_js_4x',
          'ty-i18n!metacategory',
          'ty-i18n!domain_category#optional',
          'ty-i18n!portfolio',
          'export-pane',
          'add-tile-button',
          'portfolio-plugins',
          'export-app',
          'dashboard-plugins',
        ], ($, config, i18n, metacategory, domainCategory, portfolioI18n, exportPane, AddTileButton) => {
          const content = $('#comparison-view');
          content.empty();

          function saveCallback(tiles) {
            view.parameters.tiles = tiles;
            // save filter and tile in DB view parameters
            view?.id && tyraService.updateView(view);
          }

          async function selectClusterCallback(clusterId) {
            await ssoService.putUserProperty({
              userId: session.user.id,
              name: 'selected_cluster',
              value: clusterId,
              ssoToken: session.ssoToken,
            });
            window.location.hash = innerSidebarTabs.dashboard.hash;
            window.location.reload();
          }

          const urls = {
            groupURL: config.urls.group,
            portfolioURL: config.urls.portfolio,
            publicWidgetsURL: config.urls.widget5,
            ssoURL: config.urls.sso,
            clusterURL: config.urls.cluster,
            tasksURL: config.urls.tasks,
            goalsURL: config.urls.goals,
          };

          const params = {
            // IMPROVE this is needed because the web app part of the portfolio app doesn't use RequireJS
            exportModule: function (element, actionContainer, param, alwaysShow) {
              const urlOptions = {
                options: $.extend(true, { userId: session.user.id }, urls),
              };

              // Get selected folder name
              const overviewModule = (view.parameters.tiles || []).filter(
                (tile) => tile.type === 'OverviewTabContainer'
              );

              const folderOptions = {
                options: {
                  filter: {
                    folder:
                      $.isEmptyObject(overviewModule) || !overviewModule[0].filter
                        ? null
                        : overviewModule[0].filter.folder,
                  },
                },
              };
              param = $.extend(true, folderOptions, param);

              const portfolioDeferred = $.Deferred();

              require(['portfolio/static/js/export/export-module'], (exportModule) => {
                portfolioDeferred.resolve(
                  exportModule(element, actionContainer, $.extend(true, urlOptions, param), exportPane, alwaysShow)
                );
              }, portfolioDeferred.reject);

              return $.when(portfolioDeferred).pipe((portfolio) => portfolio);
            },

            groupFilterOptions: {},
            defaultFilter: {
              globalTimeframe: 'trend_30d',

              // an object of the form { source_id: true, source_id: true, ... }
              sources: { '-1': true },

              // each element has the form { type: 'sentiment', subtype: 'hotel' (optional),
              // timeframe: 'last30days', compIndex: true/false }
              values: [
                { type: 'performance_score', timeframe: 'timerange_30d', compIndex: false },
                { type: 'score', timeframe: 'value_today', compIndex: false },
                { type: 'reviews', timeframe: 'trend_30d', compIndex: false },
                { type: 'response_rate', timeframe: 'trend_30d', compIndex: false },
                { type: 'popularity', timeframe: 'value_today', compIndex: false },
              ],
            },
            canViewSocial: permissionsWorker.hasPermission([PERMISSIONS.SOCIAL]),
            canViewStatistics: permissionsWorker.hasPermission([PERMISSIONS.COMPARISON]),
            canViewMessagingStatistics: false,
            userId: session.user.id,
            userName: session.user.username,
            email: session.user.email,
            scaling: session.properties.scale,
            defaultCluster: session.defaultCluster,
            regionSetId: session.properties.region_set_id,
            isEnterprise: permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]),
            canEditPortfolio:
              permissionsWorker.hasPermission([PERMISSIONS.PORTFOLIO]) &&
              !permissionsWorker.hasPermission([PERMISSIONS.DISABLE_PORTFOLIO_EDITING]),
            canEditComparison:
              permissionsWorker.hasPermission([PERMISSIONS.COMPARISON]) &&
              !permissionsWorker.hasPermission([PERMISSIONS.DISABLE_COMPARISON_EDITING]),
            tiles: view.parameters.tiles,
            presets: view.parameters.presets,
            hiddenTiles: view.parameters.hiddenTiles,
            location: 'COMPARISON',
            popularityDisabled: true,
            fromDate: null,
            time: 'last30days',
            toDate: null,
            locale: session.properties.locale,
            clusterMappings: clusterMappings,
          };

          $.each(metacategory, (id, label) => {
            i18n[`category_${id}`] = label;
          });

          $.each(portfolioI18n, (id, label) => {
            i18n[id] = label;
          });

          $.each(domainCategory, (id, label) => {
            i18n[id] = label;
          });

          $.Topic('comparison/tilesLoaded').subscribe((grid) => {
            grid.find('.tile-blank').remove().DashboardModule().destroy();
            const onAddTileButton = () => content.find('.add-tile-button').trigger('click');
            const ghostTile = new AddTileButton(onAddTileButton);
            grid.addModule(ghostTile.iconParams, ghostTile.detailsParams);
            grid.rearrange();
          });

          // See portfolio app documentation
          return content.Portfolio(params, urls, i18n, saveCallback, selectClusterCallback);
        });
      });
    };
    render();
  }, [require, session, tyraService, permissionsWorker, clusterService, ssoService]);

  return (
    <Box
      sx={{
        width: '1000px',
        margin: 0,
        padding: '20px 20px 0 20px',
      }}
      id='comparison-view'
      className='view COMPARISON_MODULES'
    >
      <PageLoading />
    </Box>
  );
};

Comparison.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Comparison);
