import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { usePermissions } from '../../../../../../contexts/permissions/permissionsContext';
import useHash from '../../../../../../hooks/useHash';
import { getTabVisibleMap } from '../../../utils';
import { adminSection, adminTabs, adminTabsList } from '../../innerSidebarTabs';
import Section from '../Section';
import Tab from '../Tab';

const Admin = ({ clickHandlers = {}, selectedTabId }) => {
  const hash = useHash();
  const { tabsPermissionsManager } = usePermissions();
  const badges = useSelector((state) => state.sidebar.badges);

  const tabVisibleMap = useMemo(
    () => getTabVisibleMap(adminTabsList, tabsPermissionsManager),
    [tabsPermissionsManager]
  );

  const isSectionVisible = useMemo(
    () => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible),
    [tabVisibleMap]
  );

  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    return {
      [adminTabs.newUsersAdmin.id]: () => {
        window.open('/users-admin');
      },
    };
  }, [clickHandlers]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
  };

  if (!isSectionVisible) {
    return null;
  }

  return (
    <Section key={adminSection.id} label={<FormattedMessage id={adminSection.labelId} />}>
      {adminTabsList.map((tab) => {
        if (!tabVisibleMap[tab.id]) {
          return null;
        }
        return (
          <Tab
            id={tab.id}
            key={tab.id}
            icon={tab.icon}
            chip={tab.chip}
            label={<FormattedMessage id={tab.labelId} defaultMessage={tab.labelDefaultMessage} />}
            href={tab.hash}
            badge={badges[tab.id]}
            clickHandler={clickHandler}
            selected={hash === tab.hash || selectedTabId === tab.id}
          />
        );
      })}
    </Section>
  );
};

Admin.propTypes = {
  selectedTabId: PropTypes.string,
  clickHandlers: PropTypes.object,
};
export default Admin;
