import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trustyou/ty-brew/mui';

const SubmitButton = ({ ...props }) => (
  <Button
    sx={{
      width: '350px',
    }}
    type='submit'
    size='large'
    variant='contained'
    {...props}
  />
);

SubmitButton.propTypes = {
  props: PropTypes.object
};

export default SubmitButton;
