import idpClientJs from '@trustyou/idp_client_js';

export default class IdpService {
  constructor({ idpClientId, idpSecret, idpUrl }) {
    this.idpClientId = idpClientId;
    this.idpSecret = idpSecret;
    this.idpUrl = idpUrl;
  }

  async getIdpToken() {
    const idpJwt = await idpClientJs.getIdpToken(
      this.idpClientId,
      this.idpSecret,
      this.idpUrl
    );
    return idpJwt;
  }

  async getMessagingJwtFromIdpToken() {
    const idpJwt = await this.getIdpToken();
    return idpClientJs.getMessagingJwtFromIdpToken(idpJwt);
  }

  async getUserIdFromIdpToken() {
    const idpJwt = await this.getIdpToken();
    return idpClientJs.getUserIdFromIdpToken(idpJwt);
  }

  async getIdpHeaders() {
    const idpJwt = await this.getIdpToken();
    return idpJwt ? { Authorization: `Bearer ${idpJwt}` } : {};
  }
}
