/* eslint-disable no-undef */

export default class MarketoFormWrapper {
  constructor(marketoScriptUrl, defaultUrl, marketoId) {
    this._marketoBasePath = this._parseBasePath(marketoScriptUrl || defaultUrl);
    this._marketoId = marketoId;
  }

  loadForm = (marketoLocaleCode) =>
    this._isMarketoEnabled() && MktoForms2.loadForm(this._marketoBasePath, this._marketoId, marketoLocaleCode);

  _isMarketoEnabled = () => typeof MktoForms2 !== 'undefined';

  _parseBasePath = (url) => {
    const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    return matches && matches[0];
  };
}
