import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Slide, Tab, Tabs } from '@trustyou/ty-brew/mui';

import config from '../../config';
import VERTICALS from '../../models/Verticals';
import LoginForm from './LoginForm';
import MainContainer from './MainContainer';

const Login = ({ vertical }) => {
  const { themeId } = useParams();
  const tabValue = vertical === VERTICALS.HOTELS ? 0 : 1;
  const navigate = useNavigate();

  useEffect(() => {
    if (themeId) {
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = `${config.urls.tyAssets}/common/img/logos/${themeId}_favicon.png`;
      }
      document.title = themeId.toUpperCase();
    }
  }, [themeId]);

  const handleChange = (event, newValue) => {
    const selectedVertical = newValue === 0 ? VERTICALS.HOTELS : VERTICALS.RESTAURANTS;
    const themeSubpath = themeId ? `/${themeId}` : '';
    navigate(`/auth/${selectedVertical}/login${themeSubpath}`);
  };

  const TabPanel = ({ children, value, index }) => (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
  TabPanel.propTypes = {
    value: PropTypes.number,
    index: PropTypes.number,
  };

  return (
    <MainContainer>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 3,
            marginBottom: 0.5,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant='fullWidth'
            centered
            textColor='primary'
            sx={{ width: '350px' }}
          >
            <Tab label={<FormattedMessage id='tab_hotels' defaultMessage='Hotels' />} value={0} />
            <Tab label={<FormattedMessage id='tab_rest' defaultMessage='Restaurants' />} value={1} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Box sx={{ width: '350px', overflow: 'hidden' }}>
            <Slide direction={'right'} in={tabValue === 0} timeout={400} mountOnEnter unmountOnExit>
              <Box sx={{ width: '350px' }}>
                <LoginForm vertical={VERTICALS.HOTELS} />
              </Box>
            </Slide>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ width: '350px', overflow: 'hidden' }}>
            <Slide timeout={400} direction={'left'} in={tabValue === 1} mountOnEnter unmountOnExit>
              <Box sx={{ width: '350px' }}>
                <LoginForm vertical={VERTICALS.RESTAURANTS} />
              </Box>
            </Slide>
          </Box>
        </TabPanel>
      </Box>
    </MainContainer>
  );
};

Login.propTypes = {
  vertical: PropTypes.oneOf([VERTICALS.HOTELS, VERTICALS.RESTAURANTS]),
};

export default Login;
