import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@trustyou/ty-brew/mui';

import PageLoading from '../../PageLoading';
import { useServices } from '../../../../contexts/services/servicesContext';
import { getCreateView } from '../service';

const HotelAdmin = ({ require }) => {
  const { tyraService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get view from DB or set default one
      const view = await getCreateView(tyraService, {
        type: 'HOTEL-ADMIN_VIEW',
        pageId: 'hotel-admin',
        parameters: {},
      });

      require([
        'jquery',
        'hotel-admin-plugins',
        'table', // used in hotel-admin-plugins ($.ty.table)
        'paginator', // used in hotel-admin-plugins ($('#id').paginator())
      ], ($) => {
        const content = $('#hotel-admin-view');
        content.empty();

        const getViewParameter = (key) => view.parameters[key];
        const saveViewParameter = (filter) => {
          view.parameters = { ...view.parameters, ...filter };
          // save filter and tile in DB view parameters
          view?.id && tyraService.updateView(view);
        };

        content.HotelAdmin().init({ getViewParameter, saveViewParameter });
      });
    };
    render();
  }, [require, tyraService]);

  return (
    <Box
      id='hotel-admin-view'
      className='view HOTEL-ADMIN_VIEW'
      sx={{
        margin: 0,
        padding: '20px 20px 0 20px',
        width: '100%',
        a: {
          textDecoration: 'none',
        },
      }}
    >
      <PageLoading />
    </Box>
  );
};

HotelAdmin.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(HotelAdmin);
