import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { usePermissions } from '../../../../../../contexts/permissions/permissionsContext';
import { useServices } from '../../../../../../contexts/services/servicesContext';
import useHash from '../../../../../../hooks/useHash';
import { setIsLoading as setAppIsLoading } from '../../../../../../redux/slices/app';
import { setBadges } from '../../../../../../redux/slices/sidebar';
import { getTabVisibleMap, navigateToMessagingTab } from '../../../utils';
import { inboxSection, inboxTabs, inboxTabsList } from '../../innerSidebarTabs';
import Section from '../Section';
import Tab from '../Tab';

const { conversations } = inboxTabs;

const Inbox = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => {
  const hash = useHash();
  const dispatch = useDispatch();
  const { tabsPermissionsManager } = usePermissions();
  const { messagingService } = useServices();
  const messagingSubdomain = useSelector((state) => state.sidebar.messagingSubdomain);
  const badges = useSelector((state) => state.sidebar.badges);

  const tabVisibleMap = useMemo(
    () => getTabVisibleMap(inboxTabsList, tabsPermissionsManager),
    [tabsPermissionsManager]
  );

  // badges
  useEffect(() => {
    const setUnreadConversationCount = (count) => {
      dispatch(
        setBadges({
          [conversations.id]: count,
        })
      );
    };
    async function getUnreadConversationCount() {
      const count = await messagingService.getUnreadConversationCount();
      setUnreadConversationCount(count);
      await messagingService.subscribeToUnreadConversationCount(setUnreadConversationCount);
    }
    tabVisibleMap[conversations.id] && getUnreadConversationCount();
  }, [messagingService, dispatch, tabVisibleMap]);

  // click handlers
  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    const dispatchLoading = (_isLoading) => {
      dispatch(setAppIsLoading(_isLoading));
    };
    return {
      [conversations.id]: (e) => {
        navigateToMessagingTab(e, 'inbox', {
          messagingService,
          messagingSubdomain,
          dispatchLoading,
        });
      },
    };
  }, [clickHandlers, dispatch, messagingService, messagingSubdomain]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
    // eslint-disable-next-line no-unused-expressions
    setSelectedTabId?.(id);
  };

  // section visibility
  const isSectionVisible = useMemo(
    () => Object.values(tabVisibleMap).some((isTabVisible) => isTabVisible),
    [tabVisibleMap]
  );

  if (!isSectionVisible) {
    return null;
  }

  return (
    <Section key={inboxSection.id} label={<FormattedMessage id={inboxSection.labelId} />}>
      {inboxTabsList.map((tab) => {
        if (!tabVisibleMap[tab.id]) {
          return null;
        }
        return (
          <Tab
            id={tab.id}
            key={tab.id}
            icon={tab.icon}
            chip={tab.chip}
            label={<FormattedMessage id={tab.labelId} />}
            href={tab.hash}
            badge={badges[tab.id]}
            clickHandler={clickHandler}
            selected={hash === tab.hash || selectedTabId === tab.id}
          />
        );
      })}
    </Section>
  );
};

Inbox.propTypes = {
  selectedTabId: PropTypes.string,
  clickHandlers: PropTypes.object,
  setSelectedTabId: PropTypes.func,
};
export default Inbox;
