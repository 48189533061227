import React from 'react';
import PropTypes from 'prop-types';

import HelpItem from './HelpItem';
import SettingsItem from './SettingsItem';
import { helpTabs, settingsTabs } from '../minibarTabs';

const SecondaryItems = ({ clickHandlers = {}, selectedTabId, setSelectedTabId }) => (
  <>
    <HelpItem clickHandlers={clickHandlers} />
    <SettingsItem clickHandlers={clickHandlers} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId}/>
  </>
);

SecondaryItems.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  clickHandlers: PropTypes.shape({
    // help tabs
    [helpTabs.helpManual.id]: PropTypes.func,
    [helpTabs.supportChat.id]: PropTypes.func,
    [helpTabs.customerService.id]: PropTypes.func,
    [helpTabs.onlineTraining.id]: PropTypes.func,
    [helpTabs.featureTour.id]: PropTypes.func,
    // settings tabs
    [settingsTabs.postStay.id]: PropTypes.func,
    [settingsTabs.live.id]: PropTypes.func,
  })
};

export default SecondaryItems;
