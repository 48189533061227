import React, { Suspense, lazy } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import config from '../../../config';
import PageLoading from '../PageLoading';
import innerSidebarTabs, { redirects } from '../Sidebar/InnerSidebar/innerSidebarTabs';
import minibarTabs from '../Sidebar/Minibar/minibarTabs';
import ClientOnboarding from './ClientOnboarding';
import ResponseAi from './ResponseAi';
import Upgrade from './Upgrade';
import WidgetRedirect from './Redirect/widget-redirect';

const Forms = lazy(() => import('@trustyou/forms-admin-ui').then((module) => ({ default: module.Forms })));
const Widgets = lazy(() => import('@trustyou/marketing-app').then((module) => ({ default: module.App })));
const ReviewInboxRevamp = lazy(() =>
  import('@trustyou/rir-frontend-ui').then((module) => ({ default: module.ReviewInboxRevamp }))
);

const Router = () => {
  const session = useSelector((state) => state.app.session);
  const intl = useIntl();

  return (
    <Routes>
      <Route
        path={`${innerSidebarTabs.forms.route}/*`}
        element={
          <Suspense fallback={<PageLoading sx={{ paddingTop: '20px' }} />}>
            <Forms
              config={{
                hostUrl: config.urls.forms,
                formsUiUrl: config.urls.formsUi,
                tyAssetsUrl: `${config.urls.cdn}/v2/hotel/ty-assets`,
                idp: {
                  client_id: config.idp.clientId,
                  secret: config.idp.secret,
                  idp_url: config.idp.idpUrl,
                },
                permissions: session.permissions,
                lang: intl.locale,
              }}
            />
          </Suspense>
        }
      />
      <Route path={minibarTabs.upgrade.route} element={<Upgrade />} />
      <Route
        path={`${innerSidebarTabs.widgets.route}/*`}
        element={
          <Suspense fallback={<PageLoading sx={{ paddingTop: '20px' }} />}>
            <Widgets
              config={{
                host: config.urls.host,
                defaultClusterId: session.defaultCluster.id,
                userId: session.user.id,
                permissions: session.permissions,
                lang: intl.locale,
              }}
            />
          </Suspense>
        }
      />
      <Route
        path={`${innerSidebarTabs.rir.route}/*`}
        element={
          <Suspense fallback={<PageLoading sx={{ paddingTop: '20px' }} />}>
            <ReviewInboxRevamp
              config={{
                apiUrl: config.urls.reviewsInboxBff,
                idp: {
                  clientId: config.idp.clientId,
                  secret: config.idp.secret,
                  url: config.idp.idpUrl,
                },
                lang: intl.locale,
              }}
            />
          </Suspense>
        }
      />
      <Route path={`${innerSidebarTabs.clientOnboarding.route}/*`} element={<ClientOnboarding />} />
      <Route path={`${innerSidebarTabs.responseAi.route}/*`} element={<ResponseAi />} />
      <Route path={`${redirects.widgetRedirect.route}`} element={<WidgetRedirect session={session} />} />
    </Routes>
  );
};

export default Router;
