const LANG_CODES = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  ja: 'ja',
  pt: 'pt',
  zh: 'zh',
};

const ACCOR_LANG_CODES = {
  [LANG_CODES.en]: LANG_CODES.en,
  [LANG_CODES.fr]: LANG_CODES.fr,
};

/**
 * Upgrade products categorization
 */
const CATEGORIES = {
  PREVENT_NEGATIVE_FEEDBACK: {
    ID: 'prevent_negative_feedback',
  },
  IMPROVE_GUEST_SATISFACTION: {
    ID: 'improve_guest_satisfaction',
  },
  WIN_MORE_BOOKINGS: {
    ID: 'win_more_bookings'
  },
  UNLOCK_LIVE_EXPERIENCE: {
    ID: 'unlock_live_experience',
  },
  ACCESS_VOG_JOURNEY: {
    ID: 'access_vog_journey',
  },
  REQUEST_DEMO: {
    ID: 'request_demo',
  },
};

/**
 * Upgrade products definition
 */
const PRODUCTS = {
  LIVE_SURVEY: {
    ID: 'live_survey',
  },
  LIVE_MESSAGING: {
    ID: 'live_messaging',
  },
  POST_STAY_SURVEY: {
    ID: 'post_stay_survey',
  },
  REPUTATION_MANAGEMENT: {
    ID: 'reputation_management',
  },
  REVIEW_MARKETING: {
    ID: 'review_marketing',
  },
  PUSH_TO_GOOGLE: {
    ID: 'push_to_google',
  },
};

/**
 * Upgrade Accor surveys definition
 */
const SURVEYS = {
  MICE: {
    ID: 'mice',
    VALUE: 'MICE'
  },
  SPA: {
    ID: 'spa',
    VALUE: 'SPA'
  },
  FB: {
    ID: 'fb',
    VALUE: 'F&B'
  },
};

const MARKETING_DEFAULT_LINK = 'https://www.trustyou.com/';
const ACCOR_REGISTRATION_DEFAULT_LINK = 'https://trustyou-sfawk.force.com/customer/s/login-accor?language={lang_code}';

export {
  LANG_CODES,
  ACCOR_LANG_CODES,
  CATEGORIES,
  PRODUCTS,
  SURVEYS,
  MARKETING_DEFAULT_LINK,
  ACCOR_REGISTRATION_DEFAULT_LINK,
};
