import React from 'react';
import { Stack, Modal, Box, Typography, Paper, Step, Stepper, StepButton } from '@trustyou/ty-brew/mui';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../Login/Buttons/SubmitButton';
import config from '../../../config';

const cxpTransitionSteps = [
  {
    title: <FormattedMessage id='cxp_step_1_title' defaultMessage='Set a new password' />,
    description: <FormattedMessage id='cxp_step_1_description' defaultMessage='with your current email address' />,
  },
  {
    title: <FormattedMessage id='cxp_step_2_title' defaultMessage='Log into CXP' />,
    description: <FormattedMessage id='cxp_step_2_description' defaultMessage='on the new login page' />,
    url: 'https://cxp.trustyou.com/',
  },
  {
    title: <FormattedMessage id='cxp_step_3_title' defaultMessage='Explore new features' />,
    description: (
      <FormattedMessage
        id='cxp_step_3_description'
        defaultMessage='with self enablement, Business Intelligence, AI and much more'
      />
    ),
  },
];

const StepComponent = () => (
  <Stepper>
    {cxpTransitionSteps.map((value, index) => (
      <Step
        key={index}
        active
        sx={{
          minWidth: '260px',
          height: '108px',
          backgroundColor: 'grey.50',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginX: 1,
        }}
      >
        <StepButton color='inherit' disabled sx={{ justifyContent: 'flex-start' }}>
          <Typography variant='subtitle1' color='text.primary'>
            {value.title}
          </Typography>
        </StepButton>
        <Typography variant='body2' color='text.secondary' sx={{ marginTop: '5px', width: '260px' }}>
          {value.description}
          <a href={value.url} target='_blank' rel='noopener noreferrer'>
            {' '}
            {value.url}
          </a>
        </Typography>
      </Step>
    ))}
  </Stepper>
);

const CXPStartingModal = ({ open = true, handleClose }) => {
  const navigate = useNavigate();
  const isCXPTransferPage = window.location.hash.includes('cxp');

  const navigateToCXPTransfer = () => {
    navigate('/cxp/cxp_onboarding');
  };

  return (
    <Modal
      open={!isCXPTransferPage && open}
      onClose={handleClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Paper
        sx={{
          minWidth: '900px',
          height: '490px',
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 1,
          justifyContent: 'space-around',
          outline: 0,
        }}
      >
        <Box
          component='img'
          sx={{
            height: '110px',
            width: '110px',
          }}
          src={`${config.urls.tyAssets}/common/img/logos/cxp-rocket.png`}
        />
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant='h6' color='text.primary'>
            <FormattedMessage
              id='cxp_starting_modal_title'
              defaultMessage={'You’ve been upgraded to Customer Experience Platform (CXP)'}
            />
          </Typography>
          <Typography variant='body1' color='text.secondary' sx={{ width: '624px', textAlign: 'center' }}>
            <FormattedMessage
              id='cxp_starting_modal_description'
              defaultMessage='Set up your account to gain access to a range of new benefits with enhanced usability. Here’s how it works'
            />
            {':'}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: 'row' }}>
          <StepComponent />
        </Stack>
        <SubmitButton sx={{ width: '150px' }} onClick={navigateToCXPTransfer}>
          <FormattedMessage id='get_started_cxp' defaultMessage='Get started' />
        </SubmitButton>
      </Paper>
    </Modal>
  );
};

CXPStartingModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default CXPStartingModal;
