import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ListItemIcon } from '@trustyou/ty-brew/mui';

import { setIsLoading as setAppIsLoading } from '../../../../../redux/slices/app';
import VERTICALS from '../../../../../models/Verticals';
import { usePermissions } from '../../../../../contexts/permissions/permissionsContext';
import useHash from '../../../../../hooks/useHash';
import MinibarItem from '../MinibarItem';
import PERMISSIONS from '../../../../../models/Permissions/Permissions';
import { primaryTabs } from '../minibarTabs';
import config from '../../../../../config';

const { hotels, restaurants, upgrade } = primaryTabs;

const PrimaryItems = ({ clickHandlers = {}, selectedTabId }) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.app.session);
  const { permissionsWorker, tabsPermissionsManager } = usePermissions();

  const hash = useHash();
  const { vertical } = tabsPermissionsManager;
  const verticalSwitchEnabled = permissionsWorker.hasPermission([PERMISSIONS.SYNCHRONIZE_SESSIONS]);
  const isHotelsEnabled = vertical === VERTICALS.HOTELS || verticalSwitchEnabled;
  const isRestaurantsEnabled = vertical === VERTICALS.RESTAURANTS || verticalSwitchEnabled;

  const verticalUrl = `${config.urls.host}/sso/api/synchronize/?user_id=${session.user.id}${hash}`;
  const _clickHandlers = useMemo(() => {
    // use passed clickHandlers
    if (clickHandlers && Object.keys(clickHandlers).length) {
      return clickHandlers;
    }
    return {
      [hotels.id]: () => {
        dispatch(setAppIsLoading(true));
      },
      [restaurants.id]: () => {
        dispatch(setAppIsLoading(true));
      },
    };
  }, [clickHandlers, dispatch]);

  const clickHandler = (e, id) => {
    _clickHandlers[id]?.(e);
  };

  return (
    <>
      <ListItemIcon key='ty-logo' sx={{ padding: '14px', minWidth: '32px' }}>
        <img src={`${config.urls.cdn}/apps/commons/public/img/ty_logo_icon_512x512.svg`} width={32} height={32} />
      </ListItemIcon>
      {isHotelsEnabled && (
        <MinibarItem
          id={hotels.id}
          selected={vertical === VERTICALS.HOTELS || selectedTabId === hotels.id}
          href={verticalUrl}
          icon={hotels.icon}
          label={<FormattedMessage id={hotels.labelId} />}
          clickHandler={clickHandler}
        />
      )}
      {isRestaurantsEnabled && (
        <MinibarItem
          id={restaurants.id}
          selected={vertical === VERTICALS.RESTAURANTS || selectedTabId === restaurants.id}
          href={verticalUrl}
          icon={restaurants.icon}
          label={<FormattedMessage id={restaurants.labelId} />}
          clickHandler={clickHandler}
        />
      )}
      {tabsPermissionsManager.isVisible(upgrade.id) && (
        <MinibarItem
          id={upgrade.id}
          selected={hash === upgrade.hash || selectedTabId === upgrade.id}
          arrow={false}
          href={upgrade.hash}
          icon={upgrade.icon}
          label={<FormattedMessage id={upgrade.labelId} />}
          clickHandler={clickHandler}
        />
      )}
    </>
  );
};

PrimaryItems.propTypes = {
  selectedTabId: PropTypes.string,
  verticalUrl: PropTypes.string,
  clickHandlers: PropTypes.shape({
    [hotels.id]: PropTypes.func,
    [restaurants.id]: PropTypes.func,
    [upgrade.id]: PropTypes.func,
  }),
};

export default PrimaryItems;
