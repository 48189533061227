import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box } from '@trustyou/ty-brew/mui';

import { useServices } from '../../../../contexts/services/servicesContext';
import { getCreateView } from '../service';

const Templates = ({ require }) => {
  const { tyraService } = useServices();

  useEffect(() => {
    const render = async () => {
      // get view from DB or set default one
      const view = await getCreateView(tyraService, {
        type: 'TEMPLATES_VIEW',
        pageId: 'templates',
        parameters: {
          filter: {
            survey: [],
            template: [],
          },
        },
      });

      require(['jquery', 'survey-app', 'tyra-5/static/js/config'], ($, surveys, config) => {
        const content = $('#templates-view');
        const params = {
          summaryElement: content.find('#templates-card'),
          filter: view?.parameters?.filter || {},
          onSaveFilter: (filter) => {
            // save filter in DB view parameters
            const { parameters, ...others } = view;
            view?.id &&
              tyraService.updateView({
                ...others,
                parameters: { ...parameters, filter },
              });
          },
          urls: config.urls,
          isTemplateTab: true,
        };

        surveys(params);
      });
    };
    render();
  }, [require, tyraService]);

  return (
    <Box
      sx={{
        width: '1000px',
        margin: 0,
        padding: '20px 20px 0 20px',
      }}
      id='templates-view'
      className='view TEMPLATES_VIEW'
    >
      <div id='templates-card' className='trustyou-ui card card-survey-template-library'>
        <div className='card-header'>
          <ul hidden='hidden' className='actions'>
            <li>
              <a className='add-template'>
                <span>
                  <FormattedMessage id='add_new_template' defaultMessage='@Add new template' />
                </span>
                <i className='ty-icon ty-icon-plus'></i>
              </a>
            </li>
          </ul>
          <h2>
            <FormattedMessage id='templates' defaultMessage='@Templates' />
          </h2>
        </div>
        <div className='card-body ty-loading-small left' style={{ display: 'block' }} />
      </div>
    </Box>
  );
};

Templates.propTypes = {
  require: PropTypes.func.isRequired,
};

export default React.memo(Templates);
