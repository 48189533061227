import { Socket } from '@trustyou/phoenix';
import BaseService from './BaseService';

export default class MessagingService extends BaseService {
  constructor(
    hostUrl, { idpService, linksUrl, analyticsUrl, messagingWebsocketUrl, messagingUrl, ssoToken } = {}
  ) {
    super();
    this.hostUrl = hostUrl;
    this.analyticsUrl = analyticsUrl;
    this.linksUrl = linksUrl;
    this.messagingUrl = messagingUrl;
    this.messagingWebsocketUrl = messagingWebsocketUrl;
    this.idpService = idpService;
    this.webSocket = null;
    this.ssoToken = ssoToken;
  }

  async getMessagingSubdomain() {
    const headers = await this.idpService?.getIdpHeaders();
    const businessDetailsResponse = await BaseService.fetchOk(
      `${this.hostUrl}/businesses/me`,
      {
        headers: {
          Accept: 'application/json',
          ...headers
        }
      });
    const businessDetails = await businessDetailsResponse.json();
    return businessDetails.data?.attributes.subdomain;
  }

  async getMessagingUrlFor(messagingSubdomain, path) {
    const { jwt } = await BaseService.fetchOk(
      `${this.analyticsUrl}/sso/api/jwt`,
      {
        credentials: 'include',
        method: 'GET'
      });

    const messagingUrlResponse = await BaseService.fetchOk(
      this.linksUrl,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: jwt
        },
        body: JSON.stringify({
          sso_token: this.ssoToken,
          url: `${this.messagingUrl}/${messagingSubdomain}/${path}`
        }),
        method: 'POST'
      });
    const translatedUrls = await messagingUrlResponse.json();
    return translatedUrls.data.tokenized_url;
  }

  async getUnreadConversationCount() {
    const headers = await this.idpService?.getIdpHeaders();
    const messagingUrlResponse = await BaseService.fetchOk(
      `${this.hostUrl}/conversations/unread_conversations_count`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers
        },
        method: 'GET'
      });
    const unreadConversationsCountResponse = await messagingUrlResponse.json();
    return unreadConversationsCountResponse.data?.attributes.count;
  }

  async connectToWebSocket() {
    const messagingJwt = await this.idpService?.getMessagingJwtFromIdpToken();
    this.webSocket = new Socket(this.messagingWebsocketUrl, { params: { accessToken: messagingJwt } });
    this.webSocket.connect();
  }

  async subscribeToUnreadConversationCount(callback) {
    if (!this.webSocket) {
      await this.connectToWebSocket();
    }
    const userId = await this.idpService?.getUserIdFromIdpToken();
    const messagingJwt = await this.idpService?.getMessagingJwtFromIdpToken();
    const channel = this.webSocket.channel(`private-user-unresponded-conversations:${userId}`, { accessToken: messagingJwt });
    channel.on('count-change', msg => callback(msg.data?.attributes.count));
    channel.join();
  }
}
