import PermissionsWorker from '../../../core/PermissionsManager/PermissionsWorker';
import PERMISSIONS from '../../../models/Permissions/Permissions';
import AVATAR_COLORS from '../../../models/AvatarColors/AvatarColors';
import minibarTabs from './Minibar/minibarTabs';
import innerSidebarTabs from './InnerSidebar/innerSidebarTabs';
import config from '../../../config';

export const isChatEnabled = (session) => {
  const { chat } = config.zendesk;
  const trimAndSplit = (s) =>
    (s || '')
      .split(',')
      .map((t) => t.trim())
      .filter((u) => u.length > 0);
  const locales = trimAndSplit(chat.locales);
  const clusterCountries = trimAndSplit(chat.clusterCountries);
  const basicRoles = trimAndSplit(chat.basicRoles);
  const excludedDomains = trimAndSplit(chat.excludedDomains);
  const checkBasicRole = (role) => session.roles.indexOf(role) >= 0;
  const checkEmailDomain = (excludedDomain) => {
    const parts = session.user.email.split('@');
    if (parts.length !== 2) {
      return true;
    }
    return parts.length === 2 && parts[1] !== excludedDomain;
  };
  const permissionsWorker = new PermissionsWorker(session);
  const isEnabled =
    chat.enabled &&
    (locales.length === 0 || locales.includes(session.properties.locale)) &&
    (clusterCountries.length === 0 || clusterCountries.includes(session.defaultCluster.iso2_country)) &&
    (basicRoles.length === 0 || basicRoles.every(checkBasicRole)) &&
    (excludedDomains.length === 0 || excludedDomains.every(checkEmailDomain)) &&
    (!permissionsWorker.hasPermission([PERMISSIONS.STARS_LITE]) || session.properties.trial);
  return isEnabled;
};

export const getZendeskUrl = (session, returnToUrl) =>
  `${config.urls.host}/sso/api/zendesk/${session.ssoToken}?sso_token=${session.ssoToken}&user_id=${session.user.id}&return_to=${returnToUrl}`;

export const getAvatarColorBasedOnId = (id) => {
  const BASE = AVATAR_COLORS.length;
  if (!id) {
    return;
  }
  const firstChunkOfId = id.split('-')[0];
  const summaryNumber = firstChunkOfId.split('').reduce((acc, currentValue) => {
    const number = Number(currentValue.charCodeAt(0).toString(8));
    return acc + number;
  }, 0);
  const color = AVATAR_COLORS[summaryNumber % BASE];
  return color;
};

export const navigateToMessagingTab = async (e, path, { messagingService, messagingSubdomain, dispatchLoading }) => {
  e.preventDefault();
  try {
    dispatchLoading(true);
    window.location.href = await messagingService.getMessagingUrlFor(messagingSubdomain, path);
  } catch {
    dispatchLoading(false);
  }
};

export const getTabVisibleMap = (tabsList, tabsPermissionsManager) =>
  tabsList.reduce((map, tab) => {
    map[tab.id] = tabsPermissionsManager.isVisible(tab.id);
    return map;
  }, {});

export const findTabByHash = (currentHash) => {
  const allTabs = {
    ...minibarTabs,
    ...innerSidebarTabs,
  };

  return Object.values(allTabs).find((element) => element.hash === currentHash);
};
