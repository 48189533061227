import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography, Tooltip, ListItemButton, ListItemAvatar, ListItemText } from '@trustyou/ty-brew/mui';
import { faChevronUp, faChevronDown } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { StyledFontAwesomeIcon } from '@trustyou/ty-brew';

const useEnhancedEffect = typeof window === 'undefined' ? React.useEffect : React.useLayoutEffect;

const ConditionalTooltip = ({ condition, children, ...props }) => {
  if (condition) {
    return <Tooltip {...props}>{children}</Tooltip>;
  }
  return children;
};

ConditionalTooltip.propTypes = {
  condition: PropTypes.bool,
};

const UserItem = React.forwardRef(({ onClick, open, img, name, avatarBgColor, clusterName }, ref) => {
  const nameRef = React.useRef();
  const clusterNameRef = React.useRef();
  const [showNameTooltip, setShowNameTooltip] = React.useState(true);
  const [showClusterNameTooltip, setShowClusterNameTooltip] = React.useState(true);

  useEnhancedEffect(() => {
    if (nameRef.current?.scrollWidth > nameRef.current?.offsetWidth) {
      setShowNameTooltip(true);
    } else {
      setShowNameTooltip(false);
    }
    if (clusterNameRef.current?.scrollWidth > clusterNameRef.current?.offsetWidth) {
      setShowClusterNameTooltip(true);
    } else {
      setShowClusterNameTooltip(false);
    }
  });

  return (
    <ListItemButton
      ref={ref}
      onClick={onClick}
      sx={{
        height: '60px',
        borderBottom: '1px solid',
        borderColor: 'grey.300',
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: '44px',
        }}
      >
        <Avatar
          src={img}
          alt={name}
          sx={{
            width: '36px',
            height: '36px',
            backgroundColor: avatarBgColor,
          }}
        >
          {name?.[0]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <ConditionalTooltip condition={showNameTooltip} title={name} placement='right' enterDelay={500} arrow>
            <Typography
              variant='body2'
              ref={nameRef}
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'text.primary',
              }}
            >
              {name}
            </Typography>
          </ConditionalTooltip>
        }
        secondary={
          <ConditionalTooltip
            condition={showClusterNameTooltip}
            title={clusterName}
            aria-label={clusterName}
            placement='right'
            enterDelay={500}
            arrow
          >
            <Typography
              variant='caption'
              ref={clusterNameRef}
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                color: 'text.secondary',
              }}
            >
              {clusterName}
            </Typography>
          </ConditionalTooltip>
        }
        sx={{
          margin: 0,
          width: '100%',
        }}
      />
      <StyledFontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size='sm' sx={{
        paddingLeft: 1
      }} />
    </ListItemButton>
  );
});

UserItem.displayName = 'UserItem';

UserItem.propTypes = {
  img: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  name: PropTypes.any.isRequired,
  avatarBgColor: PropTypes.string,
  clusterName: PropTypes.string.isRequired,
};

export default UserItem;
