import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@trustyou/ty-brew/mui';

import RowItem from '../RowItem';
import { fetchClientOnboardingData } from '../service';

const OnboardingTable = () => {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const { isLoading, data } = useQuery({
    queryKey: ['clientList', page],
    queryFn: () => fetchClientOnboardingData({ size: rowsPerPage, page }),
    keepPreviousData: true,
  });

  return (
    <TableContainer>
      {isLoading ? (
        <Stack sx={{ alignItems: 'center' }}>
          <CircularProgress size={30} />
        </Stack>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <FormattedMessage id='property_name' defaultMessage='Property Name' />
                </TableCell>
                <TableCell align='right'>
                  <FormattedMessage id='property_url' defaultMessage='Property URL' />
                </TableCell>
                <TableCell align='right'>
                  <FormattedMessage id='creation_date' defaultMessage='Creation Date' />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.items.map((row) => (
                <RowItem key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
          <Stack sx={{ alignItems: 'flex-end', marginTop: 2 }}>
            <Pagination count={data?.pages} page={page} onChange={(_, newPage) => setPage(newPage)} />
          </Stack>
        </>
      )}
    </TableContainer>
  );
};

export default OnboardingTable;
